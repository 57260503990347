import { atom } from 'jotai';
import { v4 } from 'uuid';

import { ToastProps } from 'src/types/Toast';

export const toastsAtom = atom<ToastProps[]>([]);

export const toastClearTimerIdAtom = atom<ReturnType<typeof setTimeout> | null>(
  null
);

const MAX_TOAST_COUNT = 2;

export const showToastAtom = atom(
  null,
  (_get, set, props: Omit<ToastProps, 'uuid'>) => {
    const uuid = v4();
    set(toastsAtom, (prev) =>
      [{ ...props, uuid }, ...prev].slice(0, MAX_TOAST_COUNT)
    );

    setTimeout(() => {
      set(toastsAtom, (prev) => prev.filter((toast) => toast.uuid !== uuid));
    }, props.duration || 3000);
  }
);
