import color, { darkColor, lightColor } from './color';
import pointer from './pointer';
import screenSize from './screenSize';
import scroll from './scroll';
import { textShadow } from './textShadow';

const isDark =
  typeof window !== 'undefined' && !!window.AzarJs
    ? window?.matchMedia?.('(prefers-color-scheme: dark)').matches ||
      window.navigator.userAgent.includes('ColorScheme/dark')
    : true;
const theme = {
  color: {
    ...color,
    ...(isDark ? darkColor : lightColor),
  },
  isDark,
  lightColor,
  darkColor,
  scroll,
  textShadow,
  screenSize,
  pointer,
  isRtl: false,
};

export default theme;
