// Azar Android의 다음 파일을 Web에 맞게 포팅
// https://git.dev.hpcnt.com/hyperconnect/azar-android/blob/3e1e8d3e136495e753dd0d0d7671a5417d479624/app/src/main/java/com/azarlive/android/support/core/webrtc/RtcStatManager.kt
// https://git.dev.hpcnt.com/hyperconnect/azar-android/blob/3e1e8d3e136495e753dd0d0d7671a5417d479624/app/src/main/java/com/azarlive/android/support/core/webrtc/PeerConnectionInfo.kt
function roundOrNull(val: number | undefined | null): number | null {
  if (val == null || isNaN(val)) {
    return null;
  }
  return Math.round(val);
}

// Azar Android의 다음 파일을 Web에 맞게 포팅
// https://git.dev.hpcnt.com/hyperconnect/azar-android/blob/74a358ee797e9201538f306e4053ecd46d89c171/app/src/main/java/com/azarlive/android/util/MatchStat.java
export class MatchStat {
  private _beginProfileTime: number | null = null;

  private _endProfileTime: number | null = null;

  private _beginIceConnectTime: number | null = null;

  private _endIceConnectTime: number | null = null;

  private firstFrameReceivedTimeMs: number | null = null;

  set beginProfileTime(beginProfileTime: number) {
    this._beginProfileTime = beginProfileTime;
  }

  set endProfileTime(endProfileTime: number) {
    this._endProfileTime = endProfileTime;
  }

  set beginIceConnectTime(beginIceConnectTime: number) {
    this._beginIceConnectTime = beginIceConnectTime;
  }

  set endIceConnectTime(endIceConnectTime: number) {
    this._endIceConnectTime = endIceConnectTime;
  }

  setEndIceConnectTime(time: number) {
    this.endIceConnectTime = time;
  }

  getIceConnectTime(): number | null {
    return roundOrNull(
      this._beginIceConnectTime != null && this._endIceConnectTime != null
        ? this._endIceConnectTime - this._beginIceConnectTime
        : null
    );
  }

  getPeerProfileTimeMs() {
    const peerProfileTimeMs = roundOrNull(
      this._endProfileTime != null && this._beginProfileTime != null
        ? this._endProfileTime - this._beginProfileTime
        : null
    );
    return peerProfileTimeMs;
  }

  gotFirstFrame() {
    if (this._endIceConnectTime != null) {
      this.firstFrameReceivedTimeMs = Date.now() - this._endIceConnectTime;
    }
  }

  getFirstFrameReceivedTimeMs() {
    return this.firstFrameReceivedTimeMs;
  }
}
