import { FC, useCallback, useEffect } from 'react';
import styled from '@emotion/styled';
import ModalLayout, {
  MobileModalType,
  ModalContent,
} from 'src/components/ModalLayout';
import Logo from 'src/assets/images/imgAzarSymbolBackground.svg';
import Button from 'src/components/Button';
import { useTranslation } from 'react-i18next';
import { WebToAppModalProps, WebToAppModalType } from 'src/types/WebToApp';
import getDeviceInfo from 'src/utils/device/info';
import useEvent from 'src/hooks/useEvent';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';

const RootStyle = styled.div`
  width: 100%;
  color: white;
`;
const LogoWrap = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Title = styled.p`
  color: ${({ theme }) => theme.color.black__dkWhite};
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
  margin-bottom: 8px;
`;
const Description = styled.p`
  color: ${({ theme }) => theme.color.gray600__dkGray500};
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px;
`;

const translationKeys: {
  [key in WebToAppModalType]: {
    title: string;
    description: string;
  };
} = {
  [WebToAppModalType.BASIC]: {
    title: 'web_to_app_tilte',
    description: 'web_to_app_desc',
  },
  [WebToAppModalType.EFFECT]: {
    title: 'web_to_app_effect_title',
    description: 'web_to_app_effect_desc',
  },
  [WebToAppModalType.MESSAGE]: {
    title: 'web_to_app_message_title',
    description: 'web_to_app_message_desc',
  },
};

/**
 * 모바일 전용 웹투앱 모달
 */
const WebToAppModal: FC<WebToAppModalProps> = ({ type, onClose }) => {
  const { t } = useTranslation();
  const keys = translationKeys[type];
  const pushEvent = useEvent();
  const { isMobile } = getDeviceInfo();

  useEffect(() => {
    if (!isMobile) return;
    pushEvent({
      eventType: EVENT_TYPE.WEB2APP,
      eventName: EVENT_NAME.ANYWHERE__IMPRESSION_TO_APP_MODAL_BTN,
      eventParams: {
        action_category: 'impression',
        tab: type === WebToAppModalType.MESSAGE ? 'history' : 'mirror',
        page: 'main',
        target: 'to_app_modal',
        content_id: type,
        type: 'button',
      },
    });
  }, [pushEvent, type]);
  const handleClick = useCallback(() => {
    //event
    pushEvent({
      eventType: EVENT_TYPE.WEB2APP,
      eventName: EVENT_NAME.ANYWHERE__CLICK_TO_APP_MODAL_BTN,
      eventParams: {
        action_category: 'click',
        tab: type === WebToAppModalType.MESSAGE ? 'history' : 'mirror',
        page: 'main',
        target: 'to_app_modal_btn',
        content_id: type,
      },
    });

    const campaignId: {
      [key in WebToAppModalType]: string;
    } = {
      [WebToAppModalType.BASIC]: 'web_to_app_home',
      [WebToAppModalType.EFFECT]: 'web_to_app_effect',
      [WebToAppModalType.MESSAGE]: 'web_to_app_message',
    };

    window.open(
      `https://t64z.adj.st/discover?adj_t=t26roxr&adj_deep_link=azarlive%3A%2F%2Fdiscover&adj_campaign=${campaignId[type]}`,
      '_blank'
    );
  }, [pushEvent, type]);

  return (
    <ModalLayout
      mobileType={MobileModalType.BOTTOM_SHEET}
      isDim
      onClose={onClose}
    >
      <RootStyle>
        <LogoWrap>
          <Logo />
        </LogoWrap>
        <ModalContent>
          <Title>{t(keys.title)}</Title>
          <Description>{t(keys.description)}</Description>
        </ModalContent>
        <ButtonWrap>
          <Button
            id='anywhere__click_to_app_modal_btn'
            color='primary'
            onClick={handleClick}
          >
            {t('web_to_app_button')}
          </Button>
        </ButtonWrap>
      </RootStyle>
    </ModalLayout>
  );
};

export default WebToAppModal;
