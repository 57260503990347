import {
  EffectType,
  isResourceRequiredEffectType,
} from '@hyperconnect/effects/dist/EffectRenderer';

import { atom } from 'jotai';
import { createRef } from 'react';

import { eventMutateAtom } from 'src/stores/event/atoms';
import {
  eventMatchPageAtom,
  handleDecoStreamVideoAtom,
  handleStreamVideoAtom,
  matchInfoAtom,
  statusAtom,
} from 'src/stores/match/atoms';
import { openedModalsAtom, showModalAtom } from 'src/stores/modal/atoms';
import { showToastAtom } from 'src/stores/toast/atoms';
import {
  DecoGroupName,
  DecoUnsupportReason,
  DecoEffect,
  DecoEffectGroup,
  DEFAULT_CLIENT_EFFECT,
  UserEffectSetting,
  EffectRendererName,
  ClientEffect,
} from 'src/types/Deco';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { STATUS } from 'src/types/Match';
import { ModalType } from 'src/types/Modal';
import { ToastType } from 'src/types/Toast';
import { getResourceWithCache } from 'src/utils/cache';
import { getAtomWithStorage } from 'src/utils/localStorageUtils';
import getDeviceInfo from 'src/utils/device/info';

import { getDecoEffectListAPI, reportInMatchEffectUsageAPI } from './api';
import EffectRendererPipe from 'src/utils/media/pipes/effect';
import WebToAppModal from 'src/components/WebToAppModal';
import { WebToAppModalType } from 'src/types/WebToApp';
import { getRandomElement } from 'src/utils/array';

export const isDecoSupportedAtom = atom(true);
export const isRandomEffectAtom = atom(false); // 현재 적용중인 이펙트가 랜덤 이펙트에 의해 트리거되었는지 여부

export const isShowDecoRedDotAtom = getAtomWithStorage<boolean>(
  'isShowDecoRedDot',
  true
);
export const isShowDecoCoachMarkAtom = getAtomWithStorage<boolean>(
  'isShowDecoCoachMark',
  true
);
export const decoVidRefAtom = atom(createRef<HTMLCanvasElement>());

export const activeDecoEffectAtom = getAtomWithStorage<DecoEffect>(
  'decoEffect',
  DEFAULT_CLIENT_EFFECT
);
export const userEffectSettingAtom = atom<UserEffectSetting>({
  effectIdList: [],
  decoEffectStrengths: [],
  facialRecognitionValue: 0,
});

const isLoadingEffectRendererPipeAtom = atom(false);

export const isShowToolbarAtom = atom(true);
const isLoadingDecoResourceAtom = atom(false);

export const isLoadingEffectAtom = atom(
  (get) =>
    get(isLoadingEffectRendererPipeAtom) || get(isLoadingDecoResourceAtom)
);

export const decoListAtom = atom<DecoEffectGroup[]>([]);

/**
 * key: effectId
 */
export const effectGroupMapAtom = atom<Map<string, DecoGroupName>>(new Map());

export const activeEffectGroupIdAtom = atom((get) => {
  const effectGroupMap = get(effectGroupMapAtom);
  const activeEffectId = get(activeDecoEffectAtom).effectId;
  return effectGroupMap.get(activeEffectId);
});

export const activeResourceAtom = atom<ArrayBuffer | undefined>(undefined);
export const effectIdsAtom = getAtomWithStorage<DecoEffect['effectId'][]>(
  'effectIds',
  []
);

export const handleDecoEffectLoadCompleteAtom = atom(null, (get, set) => {
  set(isLoadingDecoResourceAtom, false);

  const effect = get(activeDecoEffectAtom);
  set(eventMutateAtom, {
    eventType: EVENT_TYPE.DECO,
    eventName: EVENT_NAME.MIRROR_REPORT_SAVE_EFFECT,
    eventParams: {
      action_category: 'report',
      tab: 'mirror',
      page: get(eventMatchPageAtom),
      target: 'save_effect',
      save_effect: [{ effect_id: effect.effectId }],
    },
  });

  const userEffectSetting: UserEffectSetting = {
    effectIdList: [effect.effectId],
    decoEffectStrengths: [],
    facialRecognitionValue: 0,
  };
  set(userEffectSettingAtom, { ...userEffectSetting });
  const matchId = get(matchInfoAtom)?.matchId;
  if (get(statusAtom) === STATUS.MATCHED && matchId) {
    reportInMatchEffectUsageAPI(matchId, { ...userEffectSetting });
  }
});

export const selectDecoEffectAtom = atom(
  null,
  // boolean 타입지정 삭제시 default값으로 타입 유추 안 되고 unknown으로 잡혀서 타입 에러 발생
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  (get, set, effect: DecoEffect, isRandom: boolean = false) => {
    set(isRandomEffectAtom, isRandom);
    set(activeDecoEffectAtom, effect);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    set(handleDecoDownloadEffectAtom);
  }
);

export const removeDecoEffectAtom = atom(null, (get, set) => {
  set(userEffectSettingAtom, {
    effectIdList: [],
    decoEffectStrengths: [],
    facialRecognitionValue: 0,
  });

  set(isLoadingDecoResourceAtom, false);
  set(selectDecoEffectAtom, DEFAULT_CLIENT_EFFECT);
});

export const handleUnsupportedDecoAccessAtom = atom(null, (get, set) => {
  const { isMobile } = getDeviceInfo();

  if (isMobile) {
    set(showModalAtom, {
      key: ModalType.WEB_TO_APP,
      component: () => <WebToAppModal type={WebToAppModalType.EFFECT} />,
    });
  } else {
    set(showToastAtom, {
      type: ToastType.ERROR,
      message: 'deco_studio_error_not_supported',
    });
  }
});

export const handleDecoUnsupportAtom = atom(
  null,
  (get, set, unsupportReason: DecoUnsupportReason) => {
    const decoWasPreviouslyAvailable = get(isDecoSupportedAtom);

    set(isDecoSupportedAtom, false);

    // 이펙트가 처음으로 비활성화 되었을 때에만 이벤트 로그 전송
    if (decoWasPreviouslyAvailable) {
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.DECO,
        eventName: EVENT_NAME.MIRROR_REPORT_FAIL_EFFECT,
        eventParams: {
          action_category: 'report',
          tab: 'mirror',
          page: get(eventMatchPageAtom),
          target: 'fail_effect',
          reason: unsupportReason,
        },
      });
    }

    const isDecoModalOpen = get(openedModalsAtom).some(
      (modal) => modal.key === ModalType.DECO_STUDIO
    );
    const activeDecoEffect = get(activeDecoEffectAtom);

    // 데코 스튜디오 모달이 켜져 있거나 현재 적용 혹은 시도중인 이펙트가 있는 경우
    if (
      isDecoModalOpen ||
      activeDecoEffect.effectId !== DEFAULT_CLIENT_EFFECT.effectId
    ) {
      set(handleUnsupportedDecoAccessAtom);
    }

    set(removeDecoEffectAtom);
  }
);

/**
 * 이펙트를 적용하는 과정에서 예상치 못한 이슈가 발생하여 실제로 해당 이펙트가 유저에게 적용되지 못하는 경우
 */
export const handleUnexpectedEffectFailAtom = atom(null, (get, set) => {
  set(showToastAtom, {
    type: ToastType.ERROR,
    message: 'common__problem_occurred',
  });
  set(removeDecoEffectAtom);
});

const applyEffectToStreamPipeAtom = atom(
  null,
  async (
    get,
    set,
    effect: DecoEffect,
    resourceData: ArrayBuffer | null = null
  ) => {
    // AS-IS는 다중 이펙트 미지원
    EffectRendererPipe.removeAllEffects();

    if (!effect.attributes) {
      return;
    }

    let effectType: EffectType | null;
    switch (effect.attributes.effectRenderer[0]) {
      case EffectRendererName.LUT_FILTER: {
        effectType = EffectType.LUTFilter;
        break;
      }
      case EffectRendererName.LIQUIFY:
      case EffectRendererName.STRETCH: {
        effectType = EffectType.FaceDistortion;
        break;
      }
      case EffectRendererName.SKIN_SMOOTH: {
        effectType = EffectType.FaceRetouch;
        break;
      }
      case EffectRendererName.TWO_D_STICKER:
      case EffectRendererName.THREE_D_STICKER:
      case EffectRendererName.MASK: {
        effectType = EffectType.LegacyHeadgear;
        break;
      }
      case EffectRendererName.BACKGROUND:
      case EffectRendererName.BACKGROUND_BLUR: {
        effectType = EffectType.BackgroundEffect;
        break;
      }
      default:
        effectType = null;
        break;
    }

    if (effectType === null) {
      EffectRendererPipe.removeAllEffects();
      return true;
    }

    if (!isResourceRequiredEffectType(effectType)) {
      return EffectRendererPipe.setEffect(effectType, resourceData as null);
    } else if (resourceData) {
      return EffectRendererPipe.setEffect(effectType, resourceData);
    }

    return false;
  }
);

export const handleDecoDownloadEffectAtom = atom(null, async (get, set) => {
  if (!get(isDecoSupportedAtom) || !get(decoListAtom).length) return;

  const effect = get(activeDecoEffectAtom);

  if (!effect.attributes?.effectRenderer) return;

  set(isLoadingDecoResourceAtom, true);

  let activeResource: ArrayBuffer | undefined = undefined;

  if (effect.resource?.resourceUrl) {
    let encryptedResource;
    try {
      encryptedResource = await getResourceWithCache(
        effect.resource.resourceUrl,
        'arraybuffer'
      );
    } catch {
      set(showToastAtom, {
        type: ToastType.ERROR,
        message: 'deco_studio_error_network',
      });
      set(isLoadingDecoResourceAtom, false);
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.DECO,
        eventName: EVENT_NAME.MIRROR_REPORT_NETWORK_FAIL_EFFECT,
        eventParams: {
          action_category: 'report',
          tab: 'mirror',
          page: get(eventMatchPageAtom),
          target: 'network_fail_effect',
        },
      });
      return;
    }

    activeResource = (
      await import(
        /* webpackChunkName: 'resourceDecryption' */ 'src/utils/resourceDecryption'
      )
    ).resourceDecrypt(
      encryptedResource,
      effect.resource?.encryptionKey || ''
    ).buffer;
  }

  // 로딩 도중에 active effect가 변경되지 않았을 때에만 실제 스트림에 반영
  if (effect.effectId === get(activeDecoEffectAtom).effectId) {
    set(activeResourceAtom, activeResource);
    set(applyEffectToStreamPipeAtom, effect, activeResource).then((result) => {
      if (result === true) {
        set(handleDecoEffectLoadCompleteAtom);
      } else if (result === false) {
        set(handleUnexpectedEffectFailAtom);
      }
    });
    set(handleDecoStreamVideoAtom);
    set(handleStreamVideoAtom);
    // 추가적인 로딩 작업이 필요하지 않는 경우
    if (!effect.resource) {
      set(handleDecoEffectLoadCompleteAtom);
    }
  }
});

export const loadEffectRendererPipeAtom = atom(null, async (get, set) => {
  if (get(isLoadingEffectRendererPipeAtom) || !get(isDecoSupportedAtom)) return;

  set(isLoadingEffectRendererPipeAtom, true);
  const isInitSuccess = await EffectRendererPipe.initialize();

  if (!isInitSuccess) {
    /**
     * 이펙트 라이브러리 내부에서 미디어파이프 모델을 구동함에 따라 어느 모델이 실패했는지 구분 불가능해짐
     * 이벤트 로그 제외 에러 핸들링이 동일하고 두 모델의 구동 실패 환경이 크게 차이나지 않으므로 임시로 일관되게 처리
     * @see https://hpcnt.slack.com/archives/C02SQS5AJDR/p1726733760517309
     *
     * @TODO 이펙트 라이브러리에 에러 인터페이스 추가시 재검토
     */
    set(
      handleDecoUnsupportAtom,
      DecoUnsupportReason.MEDIAPIPE_LOAD_FAILED_SELFIE_SEGMENTATION
    );
  }

  set(isLoadingEffectRendererPipeAtom, false);
});

export const getDecoEffectListAtom = atom(null, async (get, set) => {
  const {
    data: {
      result: { effectGroups },
    },
  } = await getDecoEffectListAPI();

  // 서버에서 내려주는 이펙트 중 클라이언트에서 인지하는 목록만 필터링
  const supportedEffectGroups = effectGroups.filter((effectGroup) => {
    return Object.values(DecoGroupName).includes(effectGroup.groupId);
  });

  const effects = supportedEffectGroups
    .map((effectGroup) => effectGroup.effects)
    .flat();

  // 캐싱된 기존 이펙트가 deprecated되지 않았는지 검사
  const cachedEffect = get(activeDecoEffectAtom);

  if (cachedEffect.effectId !== DEFAULT_CLIENT_EFFECT.effectId) {
    const updatedActiveEffect = effects.find(
      (effect) => effect.effectId === cachedEffect.effectId
    );

    if (updatedActiveEffect) {
      // 세부 설정 등 바뀌었을 경우 고려
      set(activeDecoEffectAtom, updatedActiveEffect);
    } else {
      // deprecated effect (호환성 이슈 등에 의한 크래시 방지)
      set(removeDecoEffectAtom);
    }
  }

  // update effect group map
  const effectGroupMap = new Map();
  effectGroupMap.set(ClientEffect.NONE, DecoGroupName.ETC);
  supportedEffectGroups.forEach((effectGroup) => {
    effectGroup.effects.forEach((effect) => {
      effectGroupMap.set(effect.effectId, effectGroup.groupId);
    });
  });
  set(effectGroupMapAtom, effectGroupMap);

  // 신규 이펙트 추가 여부 기준으로 레드닷 노출 여부 결정
  const updatedEffectIds = effects.map((effect) => effect.effectId);
  const prevEffectIds = get(effectIdsAtom);
  const prevEffectIdSet = new Set(prevEffectIds);

  if (
    updatedEffectIds.some(
      (updatedEffectId) => !prevEffectIdSet.has(updatedEffectId)
    )
  ) {
    set(isShowDecoRedDotAtom, true);
  }

  set(effectIdsAtom, updatedEffectIds);

  set(decoListAtom, supportedEffectGroups);
  set(handleDecoDownloadEffectAtom);
});

export const applyRandomEffectAtom = atom(null, (get, set) => {
  const decoList = get(decoListAtom);

  // 전략적으로 집중하는 이펙트 그룹 기준으로 모집단 선정
  const candidateGroup = decoList.find(
    (effect) => effect.groupId === DecoGroupName.STYLE
  );

  if (!candidateGroup) return;

  const activeEffect = get(activeDecoEffectAtom);

  // 현재 적용중인 이펙트는 제외
  const candidateEffects = candidateGroup.effects.filter(
    (effect) => effect.effectId !== activeEffect.effectId
  );

  const randomEffectToApply = getRandomElement(candidateEffects);

  set(selectDecoEffectAtom, randomEffectToApply, true);

  return randomEffectToApply;
});
