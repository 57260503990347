import {
  getAtomWithSessionStorage,
  getAtomWithStorage,
} from 'src/utils/localStorageUtils';

export const isFirstSessionOpenedAtom = getAtomWithSessionStorage<boolean>(
  'first_session_open',
  false
);
export const isFirstOpenedAtom = getAtomWithStorage<boolean>(
  'first_open',
  false
);
