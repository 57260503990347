export enum ModalType {
  LOGIN = 'LOGIN',
  QR = 'QR',
  ERROR = 'ERROR',
  REPORT = 'REPORT',
  PROFILE_MODAL = 'PROFILE_MODAL',
  EDIT_PROFILE = 'EDIT_PROFILE',
  HEADER_POPUP = 'HEADER_POPUP',
  MATCH_SETTING = 'MATCH_SETTING',
  GEM_LACK = 'GEM_LACK',
  LIVE_RESUME = 'LIVE_RESUME',
  LIVE_GUIDE = 'LIVE_GUIDE',
  LIVE_CLOSE = 'LIVE_CLOSE',
  LIVE_SUMMARY = 'LIVE_SUMMARY',
  LIVE_VIEWER = 'LIVE_VIEWER',
  LIVE_GIFT = 'LIVE_GIFT',
  LIVE_EDIT = 'LIVE_EDIT',
  CMP_CONSENTS = 'CMP_CONSENTS',
  PLEDGE = 'PLEDGE',
  HISTORY = 'HISTORY',
  MORE = 'MORE',
  SHOP = 'SHOP',
  AGE_RESTRICTED = 'AGE_RESTRICTED',
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
  DECO_STUDIO = 'DECO_STUDIO',
  RETURN_EVENT_REMIND = 'RETURN_EVENT_REMIND',
  RETURN_EVENT_USING_ITEM = 'RETURN_EVENT_USING_ITEM',
  LIVE_OFF = 'LIVE_OFF',
  DSA_REPORT = 'DSA_REPORT',
  DSAR = 'DSAR',
  SAUDI_REWARD_GEM = 'SAUDI_REWARD_GEM',
  SAUDI_REWARD_REMIND = 'SAUDI_REWARD_REMIND',
  SAUDI_REWARD_INVITE_FRIEND_CODE = 'SAUDI_REWARD_INVITE_FRIEND_CODE',
  SAUDI_REWARD_USING_ITEM = 'SAUDI_REWARD_USING_ITEM',
  BLOG_CATEGORY = 'BLOG_CATEGORY',
  WEB_TO_APP = 'WEB_TO_APP',
  AZAR_BADGE = 'AZAR_BADGE',
  TOS_RE_APPROVAL = 'TOS_RE_APPROVAL',
  INVALID_AGE_CHECK = 'INVALID_AGE_CHECK',
  FEATURE_SETTINGS_CONSENTS = 'FEATURE_SETTINGS_CONSENTS',
}

export enum ModalNavButtonType {
  BACK = 'BACK',
  CLOSE = 'CLOSE',
  NONE = 'NONE',
}
