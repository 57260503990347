import { useEffect } from 'react';

import { useAtom } from 'jotai';

import useEvent from 'src/hooks/useEvent';
import {
  isFirstOpenedAtom,
  isFirstSessionOpenedAtom,
} from 'src/stores/firstOpen/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';

export default function useFirstOpen() {
  const [isFirstOpened, setIsFirstOpened] = useAtom(isFirstOpenedAtom);
  const [isFirstSessionOpened, setIsFirstSessionOpened] = useAtom(
    isFirstSessionOpenedAtom
  );
  const pushEvent = useEvent();

  useEffect(() => {
    if (isFirstOpened) return;
    pushEvent({ eventName: EVENT_NAME.FIRST_OPEN, eventType: EVENT_TYPE.WEB });
    setIsFirstOpened(true);
  }, [isFirstOpened, setIsFirstOpened, pushEvent]);
  useEffect(() => {
    if (isFirstSessionOpened) return;
    pushEvent({
      eventName: EVENT_NAME.FIRST_SESSION_OPEN,
      eventType: EVENT_TYPE.WEB,
    });
    setIsFirstSessionOpened(true);
  }, [isFirstSessionOpened, setIsFirstSessionOpened, pushEvent]);
}
