import agent from '@egjs/agent';
import { AgentInfo } from '@egjs/agent/declaration/types';

let agentInfo: AgentInfo | null = null;

function getDeviceInfo() {
  if (agentInfo == null && typeof window !== 'undefined') {
    agentInfo = agent(window.navigator.userAgent);
  }
  return {
    browserName: agentInfo?.browser.name,
    browserVersion: agentInfo?.browser.version,
    isWebKit: agentInfo?.browser.webkit,
    os: agentInfo?.os.name,
    osMajorVersion: agentInfo?.os.majorVersion,
    isMobile: agentInfo?.isMobile,
  };
}

export default getDeviceInfo;
