import { FC, useMemo } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Gap from 'src/components/Gap';
import { BonusGemRate } from 'src/types/Payments';

import {
  PromoIcon,
  PromoRate as PromoRateBase,
  PromotionRate as PromotionRateBase,
  PromotionTextWrap as PromotionTextWrapBase,
  PromoTitle,
  PromoWrapper,
} from './style';

const PromoTitleLowPrice = styled.span`
  color: ${({ theme }) => theme.color.black__dkWhite};
`;

const LowPriceTitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: center;
  color: ${({ theme }) => theme.color.red500};
`;

const PromotionTextWrap = styled(PromotionTextWrapBase)`
  flex-direction: column;
`;

const PromoRate = styled(PromoRateBase)`
  margin-top: 0px;
`;

const PromotionRate = styled(PromotionRateBase)`
  margin-top: 0px;
`;

interface IProps {
  promotionRate: string;
  isPromotion: boolean;
  bonusGemRates?: BonusGemRate[];
}
// PG_BONUS -> App 가격 대비 10% 할인 으로 변경
const WebLowPricePromoTop: FC<IProps> = ({
  promotionRate,
  isPromotion,
  bonusGemRates,
}) => {
  const { t } = useTranslation();
  const bonusGemRateText = useMemo(
    () =>
      bonusGemRates
        ?.map(({ type, rate, vipRating }) => {
          if (type !== 'PG_BONUS') {
            switch (type) {
              case 'VIP_BONUS': {
                return t('web_banner_benefit_vip', {
                  n: rate,
                  currentVipLevel: vipRating,
                });
              }
              case 'BONUS_COUPON': {
                return t('web_bonus_coupon', { bonusPercentage: rate });
              }
            }
          }
        })
        .join(' + '),
    [bonusGemRates, t]
  );

  return (
    <PromoWrapper>
      <PromoTitle>
        <PromoIcon
          src='/images/icons/imgNewBadge.png'
          alt='Gift for web users'
        />
        <Gap width={6} />
        <PromoTitleLowPrice>{t('web_banner_title')}</PromoTitleLowPrice>
      </PromoTitle>
      <PromotionTextWrap>
        <LowPriceTitle>{t('web_banner_benefit_price')}</LowPriceTitle>
        {bonusGemRateText && <PromoRate>{`${bonusGemRateText}`}</PromoRate>}
        {isPromotion && <PromotionRate>{promotionRate}</PromotionRate>}
      </PromotionTextWrap>
    </PromoWrapper>
  );
};

export default WebLowPricePromoTop;
