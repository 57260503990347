import { useMemo } from 'react';

import useGemProduct from 'src/hooks/useGemProduct';
import { getProductCalcGem } from 'src/utils/payments';

// GemLackModal에서 보여줄 상품 필터링
const useLackingGemProducts = (lackingGemAmount: number) => {
  const gemProducts = useGemProduct();
  const sortedProducts = useMemo(
    () =>
      [...(gemProducts?.gemProducts || [])].sort(
        (a, b) => getProductCalcGem(a).num - getProductCalcGem(b).num
      ),
    [gemProducts?.gemProducts]
  );
  const recommendedProducts = useMemo(() => {
    const index = sortedProducts.findIndex(
      (product) => getProductCalcGem(product).num >= lackingGemAmount
    );
    const sliced = sortedProducts.slice(index, index + 2);
    if (sliced.length === 2) return sliced;
    return sortedProducts.slice(-2);
  }, [lackingGemAmount, sortedProducts]);

  return recommendedProducts;
};

export default useLackingGemProducts;
