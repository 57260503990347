import { useEffect } from 'react';

import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import {
  newStompBrokerInfoAtom,
  pushChannelNameAtom,
  stompBrokerInfoAtom,
} from 'src/stores/auth/atoms';
import { statusAtom } from 'src/stores/match/atoms';
import {
  closeStompAtom,
  handleStompMessageAtom,
  setStompAtom,
  stompAtom,
} from 'src/stores/stomp/atoms';
import { STATUS } from 'src/types/Match';

export default function useStompSubscriber() {
  const stomp = useAtomValue(stompAtom);
  const setStomp = useSetAtom(setStompAtom);
  const [stompBrokerInfo, setStompBrokerInfo] = useAtom(stompBrokerInfoAtom);
  const newStompBrokerInfo = useAtomValue(newStompBrokerInfoAtom);
  const pushChannelName = useAtomValue(pushChannelNameAtom);
  const handleStompMessage = useSetAtom(handleStompMessageAtom);
  const closeStomp = useSetAtom(closeStompAtom);
  const matchStatus = useAtomValue(statusAtom);

  useEffect(() => {
    // getUserSegmentAPI에서 주기적으로 가져올 때 status > initial이면 이미 stomp.subscribe가 되어있으므로 status == initial 되면 재연결
    if (!newStompBrokerInfo || matchStatus > STATUS.INITIAL) return;
    setStompBrokerInfo(newStompBrokerInfo);
  }, [matchStatus, newStompBrokerInfo, setStomp, setStompBrokerInfo]);

  useEffect(() => {
    if (stompBrokerInfo) {
      setStomp(stompBrokerInfo);
    }
  }, [setStomp, stompBrokerInfo]);

  useEffect(
    () => () => {
      closeStomp();
    },
    [closeStomp]
  );

  useEffect(() => {
    if (pushChannelName && stomp) {
      stomp.subscribe({
        channelId: pushChannelName,
        onMessage: handleStompMessage,
      });
      return () => {
        stomp.unsubscribe(pushChannelName);
      };
    }
  }, [pushChannelName, handleStompMessage, stomp]);
}
