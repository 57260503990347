// https://stomp-js.github.io/guide/stompjs/using-stompjs-v5.html
import { Client, IFrame, IPublishParams } from '@stomp/stompjs';

import { StompBrokerInfo } from 'src/types/AzarUser';

export interface BrokerMessage {
  matchId: string;
  peerProfile: object;
  initiator: boolean;
  canTranslateTextChat: boolean;
  canTranslateVoice: boolean;
  safeMatchIntervalMs: number;
  safeMatchAutoReportDurationLimitMs: number;
  safeMatchAutoReportConfidence: string;
  safeMatchIntervalMsAfterAutoReport: number;
  safeMatchMaxSample: number;
  disableRedUlpfec: boolean;
  supportSdes: boolean;
  type: string;
}

/**
 * StompJS client를 생성하는 함수입니다.
 * @param stompBrokerInfo `LoginAPI`의 response가 `connectMediaBroker.request()`의 payload로 넘어옵니다.
 */
export function createWebclientConnection(
  stompBrokerInfo: StompBrokerInfo,
  onConnect: () => void,
  onError: (e: IFrame | Error) => void
) {
  const { uri, login, passcode } = stompBrokerInfo;
  const client = new Client({
    brokerURL: uri.replace(/^tls?:\/\//i, 'wss://'),
    connectHeaders: { login, passcode },
    // debug: function(msg: string): void {
    //   console.info('stomp debg', new Date(), msg);
    // },
    onConnect: () => {
      let attemp = 0;
      let timerId: ReturnType<typeof setInterval> | null;
      const check = () => {
        if (client.webSocket.readyState === 1) {
          if (timerId) {
            clearInterval(timerId);
            timerId = null;
          }
          onConnect();
        } else {
          attemp += 1;
          if (attemp === 5) {
            if (timerId) {
              clearInterval(timerId);
              timerId = null;
              onError(new Error('websocket connection timeout'));
            }
          }
        }
      };
      timerId = setInterval(check, 500);
    },
    // stomp credential expire시 onStompError fire
    onStompError: (e) => {
      if (e.headers.message.indexOf('login failed') >= 0) {
        onError(e); // stomp atom 및 auth atom에서 retry (credential 재발급)
      }
    },
    reconnectDelay: 5000,
  });

  client.activate();
  return client;
}

export function closeChannel(subscriptionId: string, client: Client) {
  client.unsubscribe(subscriptionId);
}

/**
 * stompJS client에 메시지를 보내는 함수입니다.
 *
 * @param client stompJS client
 * @param destination matchReducer `signalingInfo.channelId`값을 전달받습니다.
 * @param clientId matchReducer `signalingInfo.clientId` 값을 전달 받습니다.
 * @param body 메시지 내용입니다.
 */
export function sendMessage(
  client: Client,
  destination: string,
  clientId: string,
  body: string
) {
  const iPublishParams = {
    destination,
    headers: { 'sender-id': clientId },
    body,
  } as IPublishParams;
  client.publish(iPublishParams);
}

// match info message sample
//  {
//   "matchId":"V6201912198a88656d71866b67e89ccef848620a08b1944ff70166ec07f18ed7d855dbd7",
//   "peerProfile":{
//     "uid":"306116",
//     "loginType":"FACEBOOK",
//     "simpleName":"창•",
//     "veiledName":"창•",
//     "gender":"MALE",
//     "country":"KR",
//     "language":"ko",
//     "location":{
//       "country":"South Korea",
//       "countryCode":"KR",
//       "state":null,
//       "city":null,
//       "timeZoneId":"America/New_York",
//       "timeZoneOffset":-18000
//     },
//     "position":{
//       "latitude":37.566,
//       "longitude":126.9784
//     },
//     "screenshotAllowed":false,
//     "requestFriendAllowed":true,
//     "highDefinitionVideo":false,
//     "maxVideoBandwidthKbps":500,
//     "sayHi":"An-nyeong",
//     "sayCompliment":"Mert-it-er-yo",
//     "sayAskFriend":"chin-gu-hal-ka-yo?",
//     "thumbnailImageUrl":"http://dyna0zvk5krbd.cloudfront.net/public/7b114be9-5b68-4834-aff1-f5b691226e3d.jpg",
//     "profileImageUrl":"http://dyna0zvk5krbd.cloudfront.net/public/b4d20f5d-5890-44db-9566-e122b0a50f53.jpg",
//     "coverProfileImageUrl":"https://d3cmj2pfvp1c98.cloudfront.net/cover-profile/imgCover28.jpg",
//     "coolPoint":0,
//     "popularity":"0",
//     "profileMessage":null,
//     "textChatSupported":true,
//     "faceDetectionRequired":true,
//     "confirmedVisualAbuse":false,
//     "confirmedToplessAbuse":false,
//     "mutualInterest":null,
//     "interestInfos":[],
//     "monitorability":"MONITORABLE",
//     "receivedGiftRecently":false,
//     "lightweightGiftPointsInfo":{
//       "pointList":[]
//     },
//     "type":"peerProfile"
//   },
//   "initiator":true,
//   "canTranslateTextChat":true,
//   "canTranslateVoice":true,
//   "safeMatchIntervalMs":2000,
//   "safeMatchAutoReportDurationLimitMs":30000,
//   "safeMatchAutoReportConfidence":"0.80",
//   "safeMatchIntervalMsAfterAutoReport":5000,
//   "safeMatchMaxSample":20,
//   "disableRedUlpfec":true,
//   "supportSdes":false,
//   "type":"matchInfo"
// }
