import { client } from 'src/utils/api';
import { MLFeatureConfig, MLModelConfig } from './types';

export enum MLApiList {
  ModelConfigs = '/machine_learning/models',
}

export interface GetMLModelConfigsRequest {
  modelIds: string[];
}

export interface GetMLModelConfigsResponse {
  models: MLModelConfig[];
  features: MLFeatureConfig[];
}

export const getMLModelConfigsAPI = async (
  params: GetMLModelConfigsRequest
) => {
  return client.post<GetMLModelConfigsResponse>(MLApiList.ModelConfigs, params);
};
