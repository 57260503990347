import React, { useEffect, useMemo } from 'react';

import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import Button from 'src/components/Button';
import ExternalLink from 'src/components/ExternalLink';
import Gap from 'src/components/Gap';
import ModalLayoutBase, {
  MobileModalType,
  ModalContent as ModalContentBase,
  ModalFooter as ModalFooterBase,
  ModalHeader,
  ModalHeaderDescriptionRow as ModalHeaderDescBase,
  ModalHeaderTitle,
} from 'src/components/ModalLayout';
import ShopModal from 'src/components/Shop/ShopModal';
import useEvent from 'src/hooks/useEvent';
import useGemProduct from 'src/hooks/useGemProduct';
import useLackingGemProducts from 'src/hooks/useLackingGemProducts';
import useTermsInfo from 'src/hooks/useTermsInfo';
import { closeModalAtom, showModalAtom } from 'src/stores/modal/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalType } from 'src/types/Modal';
import { PgPaymentMethod } from 'src/types/Payments';
import { getProductCalcGem } from 'src/utils/payments';

import GemLackCard from './GemLackCard';
import GemLackCardSkeleton from './GemLackCardSkeleton';
import GemLackMobileFooter from './GemLackMobileFooter';
import { hoverAndActiveCss } from 'src/utils/styled';

const ModalLayout = styled(ModalLayoutBase)`
  width: 400px;
`;

const ModalHeaderDescriptionRow = styled(ModalHeaderDescBase)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
`;

const ModalFooter = styled(ModalFooterBase)`
  border-top: 1px solid ${({ theme }) => theme.color.gray100__dkGray150};
  ${({ theme }) => theme.screenSize.mobile} {
    padding: 0;
  }
`;

const ModalContent = styled(ModalContentBase)`
  padding: 16px 16px 24px 16px;
`;

const GemLackCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const LegalLink = styled(ExternalLink)`
  margin-top: 24px;
  padding: 0 8px;
  color: ${({ theme }) => theme.color.gray300__dkGray250};
  width: 100%;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  font-size: 13px;

  ${({ theme }) =>
    hoverAndActiveCss(`color: ${theme.color.gray200__dkGray200};`)}
`;

const DesktopButton = styled(Button)`
  ${({ theme }) => theme.screenSize.mobile} {
    display: none;
  }
`;

interface GemLackModalProps {
  lackingGemAmount: number;
  description: string;
}

const GemLackModal: React.FC<GemLackModalProps> = ({
  lackingGemAmount: lackingGemAmount,
  description,
}) => {
  const { t } = useTranslation();
  const showModal = useSetAtom(showModalAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const pushEvent = useEvent();
  const recommendedProducts = useLackingGemProducts(lackingGemAmount);
  const gemProducts = useGemProduct();
  const { isGDPR } = useTermsInfo();
  const savePercent = useMemo(() => {
    const [first, second] = recommendedProducts;
    if (!first || !second) return;
    return Math.floor(
      (1 -
        second.price /
          getProductCalcGem(second).num /
          (first.price / getProductCalcGem(first).num)) *
        100
    );
  }, [recommendedProducts]);

  const handleShopButtonClick = () => {
    closeModal(ModalType.GEM_LACK);
    showModal({ key: ModalType.SHOP, component: ShopModal });
  };

  const handleClose = () => {
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME.GEM_LACK_POPUP__CLICK_CLOSE,
    });
  };

  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME.GEM_LACK_POPUP__IMPRESSION,
    });
  }, [pushEvent]);

  return (
    <ModalLayout
      mobileType={MobileModalType.BOTTOM_SHEET}
      isDim
      onClose={handleClose}
      data-testid='gem-lack-modal'
    >
      <ModalHeader>
        <ModalHeaderTitle mobileType={MobileModalType.BOTTOM_SHEET}>
          {t('FILTER_LOCATION_NOT_ENOUGH_GEM')}
        </ModalHeaderTitle>
        <ModalHeaderDescriptionRow>{description}</ModalHeaderDescriptionRow>
      </ModalHeader>
      <ModalContent>
        <GemLackCardContainer>
          {recommendedProducts.length ? (
            <>
              <GemLackCard gemProduct={recommendedProducts[0]} />
              <Gap width={12} />
              <GemLackCard
                gemProduct={recommendedProducts[1]}
                isSecond={true}
                savePercent={savePercent}
              />
            </>
          ) : (
            <>
              <GemLackCardSkeleton />
              <Gap width={12} />
              <GemLackCardSkeleton />
            </>
          )}
        </GemLackCardContainer>
        {gemProducts?.paymentMethod === PgPaymentMethod.TOSS && (
          <LegalLink href='https://azarlive.com/home/ko-KR/refundPolicy.html'>
            {t('shop_refund_minor_explanation_kr')}
          </LegalLink>
        )}
        {isGDPR && (
          <LegalLink href='https://azarlive.com/home/terms-of-service.html'>
            {t('shop_more_information_eu')}
          </LegalLink>
        )}
      </ModalContent>
      <ModalFooter>
        <DesktopButton
          color='primary'
          onClick={handleShopButtonClick}
          id='GEM_LACK_POPUP_BUTTON'
          variant='text'
        >
          {t('FILTER_GENDER_SHOP_BUTTON')}
        </DesktopButton>
        <GemLackMobileFooter />
      </ModalFooter>
    </ModalLayout>
  );
};

export default GemLackModal;
