import { DateConfig } from 'react-mobile-datepicker-ts/dist';
import { DatePatternShorthand, getLocaleDatePattern } from 'src/utils/date';

export const now = new Date();

export interface DropdownItem {
  value: string;
  label?: string;
}

export const yearOptions = Array.from(Array(now.getFullYear() - 1900 + 1)).map(
  (_, i) => ({
    value: `${i + 1900}`,
    label: `${i + 1900}`,
  })
);
export const monthOptions = Array.from(Array(12)).map((_, i) => ({
  value: `${i}`,
  label: `${i + 1}`,
}));
export const dateOptions = Array.from(Array(31)).map((_, i) => ({
  value: `${i + 1}`,
  label: `${i + 1}`,
}));

type DateConfigWithPattern = DateConfig & {
  pattern: DatePatternShorthand;
};

const dateConfig: DateConfigWithPattern[] = [
  {
    type: 'month',
    format: 'MM',
    caption: 'Mon',
    pattern: 'MM',
    step: 1,
  },
  {
    type: 'date',
    format: 'DD',
    caption: 'Day',
    pattern: 'DD',
    step: 1,
  },
  {
    type: 'year',
    format: 'YYYY',
    caption: 'Year',
    pattern: 'YEAR',
    step: 1,
  },
];

export const getDateConfigWithLocaleOrder = () => {
  const order = getLocaleDatePattern();
  const orderedDateConfig = dateConfig.sort((a, b) => {
    return order.indexOf(a.pattern) - order.indexOf(b.pattern);
  });

  return orderedDateConfig;
};

export const formateDateInLocaleOrder = (date: Date, separator = '.') => {
  const orderedDateConfig = getDateConfigWithLocaleOrder();

  const dateComponents = {
    YEAR: date.getFullYear(),
    MM: date.getMonth() + 1,
    DD: date.getDate(),
  };

  return orderedDateConfig
    .map((config) => {
      const value = dateComponents[config.pattern];
      return value;
    })
    .join(separator);
};
