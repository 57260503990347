import { useEffect } from 'react';

import { useAtomValue } from 'jotai';

import { userDataAtom } from 'src/stores/auth/atoms';
import useLogin from 'src/hooks/useLogin';
import { useCmpConsentsModal } from './useCmpConsentsModal';
import { useUpdatedConsentModal } from './useUpdatedConsentModal';
import { useFeatureSettingsPopupModal } from './useFeatureSettingsPopupModal';
import { openedModalsAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import { useWebToAppModal } from './useWebToAppModal';

const useLoginUserOrderedModal = () => {
  const userData = useAtomValue(userDataAtom);
  const { isLogin } = useLogin();
  const isLoginModalOpened = useAtomValue(openedModalsAtom).some(
    (modal) => modal.key === ModalType.LOGIN
  );

  const { isResolved: isCmpResolved, show: showCmpModal } =
    useCmpConsentsModal();
  const { isResolved: isUpdatedConsentsResolved, show: showUpdatedConsents } =
    useUpdatedConsentModal();
  const { isResolved: isFeatureSettingsResolved, show: showFeatureSettings } =
    useFeatureSettingsPopupModal();
  const { show: showWebToAppModal } = useWebToAppModal();

  useEffect(() => {
    // 현재 해당 훅의 대상인 모달들은 웹클라이언트 전용 > 웹뷰에서는 노출 제한
    if (window.AzarJs) return;

    if (!isLogin || !userData || isLoginModalOpened) return;

    if (!isCmpResolved) {
      showCmpModal();
    }

    if (isCmpResolved && !isUpdatedConsentsResolved) {
      showUpdatedConsents();
    }

    if (
      isCmpResolved &&
      isUpdatedConsentsResolved &&
      !isFeatureSettingsResolved
    ) {
      showFeatureSettings();
    }

    if (
      isCmpResolved &&
      isUpdatedConsentsResolved &&
      isFeatureSettingsResolved
    ) {
      showWebToAppModal();
    }
  }, [
    isCmpResolved,
    isFeatureSettingsResolved,
    isLogin,
    isUpdatedConsentsResolved,
    showCmpModal,
    showFeatureSettings,
    showUpdatedConsents,
    userData,
    isLoginModalOpened,
    showWebToAppModal,
  ]);
};

export default useLoginUserOrderedModal;
