import { AxiosError } from 'axios';
import { atom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import QueryString from 'qs';

import { eventMutateAtom } from 'src/stores/event/atoms';
import { UTMParamsAtom } from 'src/stores/utm/atoms';
import {
  API_NAME,
  EVENT_NAME,
  EVENT_TYPE,
  getErrorStatus,
} from 'src/types/Event';
import {
  BillingInfoRequest,
  BillingInfoResponse,
  GemProductsResponse,
  getErrorCode,
  OrderInfo,
  PaymentPopupInfo,
  PaymentsCompleteRequest,
  PaymentsReserveResponse,
} from 'src/types/Payments';
import { client } from 'src/utils/api';
import { getAtomWithSessionStorage } from 'src/utils/localStorageUtils';
import { getProductCalcGem } from 'src/utils/payments';

import {
  GetPurchasableSubscriptionProducts,
  DeletePaymentMethodRequest,
} from './types';
import { deletePaymentMethodAPI } from './apis';
import { ToastType } from 'src/types/Toast';
import { isAxiosError, parseError } from 'src/utils/error';
import { showToastAtom } from 'src/stores/toast/atoms';

export const PAYMENT_POPUP_INFO_KEY = 'paymentInfo';
export const paymentPopupInfoAtom =
  getAtomWithSessionStorage<PaymentPopupInfo | null>(
    PAYMENT_POPUP_INFO_KEY,
    null
  );

export const paymentsErrorAtom = atom<{
  errorCode: string;
} | null>(null);

export const billingInfoAtom = atom<BillingInfoResponse | null>(null);

export const getBillingInfoAtom = atom(
  null,
  async (_get, set, { productId, ...params }: BillingInfoRequest) => {
    const { data: billingInfo } = await client.get<BillingInfoResponse>(
      `/api/payments/billingInfo/${productId}`,
      { params }
    );
    set(billingInfoAtom, billingInfo);
  }
);

export const gemProductsAtom =
  getAtomWithSessionStorage<GemProductsResponse | null>('gemProducts', null);

export const getGemProductsFailedAtom = atom(false);
export const getGemProductsAtom = atom(null, async (_get, set) => {
  try {
    const platform = window.AzarJs?.getPlatform() || 'WEBCLIENT';
    const res = await client.get<GemProductsResponse>(
      '/api/shop/gem-products' +
        QueryString.stringify(
          { platform }, // MAC 예외처리
          { addQueryPrefix: true }
        )
    );
    set(gemProductsAtom, res.data);
    set(getGemProductsFailedAtom, false);
  } catch (_e) {
    set(getGemProductsFailedAtom, true);
  }
});

export const resetGemProductsAtom = atom(null, (get, set) => {
  set(gemProductsAtom, null);
});

export const paymentsReservationAtom = atom<PaymentsReserveResponse | null>(
  null
);

export const reservePaymentAtom = atom(null, async (get, set) => {
  const paymentMethod = get(gemProductsAtom)?.paymentMethod;
  const paymentPopupInfo = get(paymentPopupInfoAtom);
  const gemProduct = paymentPopupInfo?.gemProduct;
  if (!paymentMethod || !gemProduct) {
    return;
  }
  const body = {
    paymentMethod,
    productId: gemProduct.productId,
    productName: gemProduct.productName,
    amount: gemProduct.price.toString(),
    currency: gemProduct.currency,
    utmParameter: get(UTMParamsAtom),
  };
  client
    .post<PaymentsReserveResponse>('/api/payments/reserve', body)
    .then((res) => {
      set(paymentsReservationAtom, res.data);
    })
    .catch((e) => {
      const errorCode = getErrorCode(e.response?.data?.code);
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.PAYMENT,
        eventName: EVENT_NAME.API_ERROR,
        eventParams: {
          payment_method: body.paymentMethod,
          api_name: API_NAME.RESERVE_PAYMENT,
          error_status_code: e.response.status,
          error_response: e.response.data,
        },
        options: { status: getErrorStatus(errorCode) },
        url: '/payment',
      });

      set(paymentsErrorAtom, { errorCode });
    });
});

export const completedReservationAtom = atom<OrderInfo | null>(null);
export const isPaymentSuccessAtom = selectAtom(
  completedReservationAtom,
  (a) => !!a
);

export const sendFailureEventAtom = atom(
  null,
  (get, set, { body, e }: { body: PaymentsCompleteRequest; e: AxiosError }) => {
    const errorCode = getErrorCode(e.response?.data?.code);
    set(eventMutateAtom, {
      eventType: EVENT_TYPE.PAYMENT,
      eventName: EVENT_NAME.API_ERROR,
      eventParams: {
        payment_method: body.paymentMethod,
        api_name: API_NAME.COMPLETE_PAYMENT,
        error_status_code: e.response?.status,
        error_response: e.response?.data,
      },
      options: { status: getErrorStatus(errorCode) },
      url: '/payment',
    });
  }
);

export const completeReservationAtom = atom(
  null,
  async (get, set, body: PaymentsCompleteRequest) => {
    client
      .post<OrderInfo>('/api/payments/complete', body)
      .then((res) => {
        if (res.data) {
          set(eventMutateAtom, {
            eventType: EVENT_TYPE.PURCHASE,
            eventName: EVENT_NAME.PURCHASE_COMPLETE,
            eventParams: {
              payment_method: body.paymentMethod,
              value: body.amount,
              currency: body.currency,
              transaction_id: body.orderId,
              quantity: getProductCalcGem(res.data.product).num,
              origin: get(paymentPopupInfoAtom)?.origin,
            },
          });
          set(completedReservationAtom, res.data);
        }
      })
      .catch((e) => {
        set(sendFailureEventAtom, { body, e });
      });
  }
);

export const resetPaymentsAtom = atom(null, (get, set) => {
  set(paymentPopupInfoAtom, null);
  set(paymentsReservationAtom, null);
  set(completedReservationAtom, null);
  set(paymentsErrorAtom, null);
  set(billingInfoAtom, null);
});

export const purchasableSubscriptionProductsAtom = atom<
  GetPurchasableSubscriptionProducts['subscriptionGroups'] | void
>(undefined);
export const getPurchasableSubscriptionProductsAtom = atom(
  null,
  async (get, set) => {
    const { data } = await client.get<GetPurchasableSubscriptionProducts>(
      '/api/v1/subscriptions/purchasable-products'
    );
    set(purchasableSubscriptionProductsAtom, data.subscriptionGroups);
  }
);

export const deletePaymentMethodAtom = atom(
  null,
  (get, set, params: DeletePaymentMethodRequest) => {
    return deletePaymentMethodAPI(params).catch((e) => {
      if (!isAxiosError(e)) throw e;
      if (e.response?.status === 500) {
        set(showToastAtom, {
          message: parseError(e as Error),
          type: ToastType.ERROR,
        });
      }
      throw e;
    });
  }
);
