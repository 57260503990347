import { MutableRefObject, useRef } from 'react';

interface Result<T> {
  /**
   * 여러 요소에 대한 ref가 담긴 배열 (arrayRef.current[i]로 특정 요소 접근)
   */
  arrayRef: MutableRefObject<T[]>;

  /**
   * 참조하고자 하는 각 요소에 적용 (ex. ref={addToRefs})
   */
  addToRefs: (el: T | null) => void;
}

/**
 * Dynamic한 여러 요소에 대해 ref 사용할 때 사용
 */
const useArrayRef = <T>(): Result<T> => {
  const arrayRef = useRef<T[]>([]);

  // array의 크기가 고정적이지 않을 수 있으므로 렌더마다 새롭게 정의되어야함
  arrayRef.current = [];

  const addToRefs = (el: T | null) => {
    if (el) {
      arrayRef.current.push(el);
    }
  };

  return {
    arrayRef,
    addToRefs,
  };
};

export default useArrayRef;
