import { refreshUserBalance } from '@hyperconnect/matata-starter-kit';
import { IMessage } from '@stomp/stompjs';
import { atom } from 'jotai';
import { switchMap } from 'rxjs';

import {
  newStompBrokerInfoAtom,
  setServersInfoAtom,
} from 'src/stores/auth/atoms';
import { getInventoryAtom, userInventoryAtom } from 'src/stores/auth/atoms';
import { isLiveInitializedAtom } from 'src/stores/live/atoms';
import { getVipRatingDetailsAtom } from 'src/stores/vip/atoms';
import { StompBrokerInfo } from 'src/types/AzarUser';
import { atomWithPipes } from 'src/utils/atom';
import { StompClient } from 'src/utils/stomp';

export const stompAtom = atom<StompClient | null>(null);

export const closeStompAtom = atom(null, (get, set) => {
  const stompClient = get(stompAtom);
  if (stompClient) {
    stompClient.close();
    set(stompAtom, null);
  }
});

export const setStompAtom = atomWithPipes(
  null,
  async (get, set, stompBrokerInfo: StompBrokerInfo) => {
    set(closeStompAtom);
    set(
      stompAtom,
      new StompClient(
        stompBrokerInfo,
        () => {
          // newStompBrokerInfoAtom를 비워놔야 status 바뀌었을 때 다시 연결하지 않음 useStompSubscriber에서 체크
          set(newStompBrokerInfoAtom, undefined);
        },
        () => {
          set(setServersInfoAtom);
        }
      )
    );
  },
  [switchMap]
);

export const handleStompMessageAtom = atom(null, (get, set, data: IMessage) => {
  if (!data.body) {
    return;
  }
  const body = JSON.parse(data.body);
  switch (body.type) {
    case 'inventoryItemUpdated':
      set(userInventoryAtom, body.inventoryItems);
      if (get(isLiveInitializedAtom)) {
        // 재화가 변경되었을 경우, WebApp에게 재화 변경을 알려줍니다.
        refreshUserBalance();
      }
      break;
    case 'updateVipInfo':
      set(getInventoryAtom);
      set(getVipRatingDetailsAtom);
      break;
    default:
      break;
  }
});
