import { useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-mobile-datepicker-ts';

import 'react-mobile-datepicker-ts/dist/main.css';
import { ModalFooter, ModalTitle } from 'src/components/ModalLayout';
import {
  formateDateInLocaleOrder,
  getDateConfigWithLocaleOrder,
} from 'src/types/DateSelector';
import { hexToRgb } from 'src/utils/common';
import { LoginModalHeader, Wrap } from 'src/components/LoginModal/styles';
import { BirthdayInputProps } from './type';
import Button from 'src/components/Button';
import { defaultBirth } from 'src/stores/register/atoms';

const DateIndicator = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: ${({ theme }) => theme.color.green500};
  display: none;
  padding: 0;
  text-align: ${({ theme }) => (theme.isRtl ? 'right' : 'left')};
  background-color: transparent;
  ${({ theme }) => theme.screenSize.mobile} {
    display: inline-block;
  }
`;

export const MobileDatePickerWrap = styled.div`
  position: relative;
  display: none;
  ${({ theme }) => theme.screenSize.mobile} {
    display: flex;
  }
  width: 100%;
  .datepicker.ios {
    background-color: transparent;
    width: 100%;
    position: relative;
    height: auto;

    .datepicker-content {
      border-top: 1px solid ${({ theme }) => theme.color.gray200__dkGray200};
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
    }
    .datepicker-wheel {
      border-top: 1px solid
        rgba(${({ theme }) => hexToRgb(theme.color.gray200__dkGray200)}, 0.8);
      border-bottom: 1px solid
        rgba(${({ theme }) => hexToRgb(theme.color.gray200__dkGray200)}, 0.8);
    }
    .datepicker-viewport::after {
      ${({
        theme: {
          color: { gray900 },
        },
      }) => `
          background: linear-gradient(${gray900}, rgb(${hexToRgb(
            gray900
          )}, 0) 52%, rgba(${hexToRgb(gray900)}, 0) 48%,${gray900});
        `}
    }
    .datepicker-scroll li {
      color: ${({ theme }) => theme.color.white};
    }
  }
`;

export const MobileBirthdayInputTypeA = ({ onSubmit }: BirthdayInputProps) => {
  const [birth, setBirth] = useState<Date>(defaultBirth);
  const { t } = useTranslation();
  const dateConfig = useMemo(() => getDateConfigWithLocaleOrder(), []);
  const [isBirthChanged, setIsBirthChanged] = useState(false);

  const onChange = (date: Date) => {
    setBirth(date);
    setIsBirthChanged(true);
  };

  return (
    <>
      <Wrap>
        <LoginModalHeader>
          <ModalTitle>{t('SIGNUP__INFO_BIRTHDAY')}</ModalTitle>
        </LoginModalHeader>
        <DateIndicator>{formateDateInLocaleOrder(birth)}</DateIndicator>
      </Wrap>
      <ModalFooter>
        <MobileDatePickerWrap>
          <DatePicker
            customHeader={<></>}
            dateConfig={dateConfig}
            onChange={onChange}
            showHeader={false}
            showFooter={false}
            value={birth}
            theme='ios'
            isPopup={false}
            isOpen
          />
        </MobileDatePickerWrap>
        <Button
          onClick={() => onSubmit(birth)}
          disabled={!isBirthChanged}
          id='SIGNUP__NEXT_REGISTER_STEP_2'
          data-testid='RegisterStep2-button-next'
        >
          {t('SIGNUP__NEXT')}
        </Button>
      </ModalFooter>
    </>
  );
};
