import { useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { gemProductsAtom, getGemProductsAtom } from 'src/stores/payments/atoms';

export default function useGemProduct() {
  const gemProducts = useAtomValue(gemProductsAtom);
  const getGemProducts = useSetAtom(getGemProductsAtom);

  useEffect(() => {
    getGemProducts();
  }, [getGemProducts]);

  return gemProducts;
}
