import React, { FC } from 'react';

import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import Button from 'src/components/Button';
import ExternalLinkBase from 'src/components/ExternalLink';
import ModalLayout, {
  MobileModalType,
  ModalContent,
  ModalFooter as ModalFooterBase,
  ModalHeader,
  ModalHeaderTitle,
} from 'src/components/ModalLayout';
import useHelpCenterUrl from 'src/hooks/useHelpCenterUrl';
import useIntersection from 'src/hooks/useIntersection';
import { closeAllModalAtom } from 'src/stores/modal/atoms';
import { Suspension } from 'src/types/AzarUser';
import { hexToRgb } from 'src/utils/common';
import { formatDate } from 'src/utils/date';

const Scroll = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  ${({ theme }) => theme.screenSize.mobile} {
    overflow: scroll;
  }
  max-height: 300px;
`;
const Modal = styled(ModalLayout)`
  max-width: 500px;
`;
const ShadowWrap = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;
const ShadowHandler = styled.span``;
const Shadow = styled.div<{ isShow: boolean; position: 'top' | 'bottom' }>`
  display: none;
  ${({ theme }) => theme.screenSize.mobile} {
    display: block;
  }
  width: 100%;
  height: 80px;
  background-size: 100%;
  position: absolute;
  ${({
    position,
    theme: {
      color: { gray60__dkGray100: dkGray100, gray60__dkGray100: gray900 },
    },
  }) => `
    ${position}: 0px;
    background: linear-gradient(${
      position === 'top' ? 0 : 180
    }deg, rgba(${hexToRgb(gray900)}, 0) 0%, ${dkGray100} 100%);
  `}
  transition: opacity .2s;
  opacity: ${({ isShow }) => (isShow ? 1 : 0)};
`;

const Desc = styled.div`
  white-space: pre-line;
  margin-bottom: 16px;
  line-height: 140%;
  font-size: 16px;
  color: ${({ theme }) => theme.color.gray900__dkGray970};
`;

const Box = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 150%;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.color.gray700__dkGray700};
  background-color: ${({ theme }) => theme.color.gray100__dkGray150};
  border-radius: 16px;
`;

const Row = styled.div``;

const ModalFooter = styled(ModalFooterBase)`
  width: 100%;
`;

const ButtonWrap = styled.div`
  display: grid;
  width: 100%;
  padding-top: 8px;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 18px;
`;

const ExternalLink = styled(ExternalLinkBase)`
  text-decoration: none;
`;

const A = styled(ExternalLinkBase)`
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;

  text-align: center;
  text-decoration: underline;

  color: ${({ theme }) => theme.color.gray500__dkGray400};
`;

interface SuspensionModalProps {
  suspension: Suspension;
}

const SuspensionModal: FC<SuspensionModalProps> = ({ suspension }) => {
  const closeAllModal = useSetAtom(closeAllModalAtom);
  const [isShowTop, setIsShowTop] = React.useState(false);
  const [isShowBottom, setIsShowBottom] = React.useState(true);
  const helpCenterUrl = useHelpCenterUrl('385068');
  const handleIntersectTop = React.useCallback<IntersectionObserverCallback>(
    ([entry]) => {
      if (entry.boundingClientRect.width) {
        setIsShowTop(!entry.isIntersecting);
      }
    },
    []
  );
  const handleIntersectBottom = React.useCallback<IntersectionObserverCallback>(
    ([entry]) => {
      if (entry.boundingClientRect.width) {
        setIsShowBottom(!entry.isIntersecting);
      }
    },
    []
  );
  const { observerRef: topRef } = useIntersection(handleIntersectTop);
  const { observerRef: bottomRef } = useIntersection(handleIntersectBottom);

  const { t, i18n } = useTranslation();
  const handleClick = async () => {
    closeAllModal();
  };

  const {
    guidelineUrl,
    reason,
    type,
    localizedType,
    reportedAt,
    punishmentStartAt,
    punishmentEndAt,
  } = suspension;
  const ROWS = [
    ['REASON', { reason }],
    ['TYPE', { localizedType }],
    ['REPORTED_AT', { reportedAt }, formatDate],
    ['PERIOD', { punishmentEndAt, punishmentStartAt }, formatDate],
  ];
  return (
    <Modal
      mobileType={MobileModalType.BOTTOM_SHEET}
      isDim
      closeWhenClickOutside={false}
    >
      <ModalHeader>
        <ModalHeaderTitle mobileType={MobileModalType.BOTTOM_SHEET}>
          {t(`SUSPENSION_POPUP__${type}__TITLE`)}
        </ModalHeaderTitle>
      </ModalHeader>
      <ModalContent>
        <ShadowWrap>
          <Shadow isShow={isShowTop} position='top' />
        </ShadowWrap>
        <Scroll>
          <ShadowHandler ref={topRef} />
          <Desc>{t(`SUSPENSION_POPUP__${type}__DESC__TOP`)}</Desc>
          <Box>
            {ROWS.map(([row, values, format]) => {
              const translationKey = `SUSPENSION_POPUP__${type}__${row}`;
              if (
                !Object.values(values).every((value) => !!value) ||
                !i18n.exists(translationKey)
              ) {
                return null;
              }
              const formattedValues = Object.entries(values).reduce(
                (obj, [key, value]) => ({
                  ...obj,
                  [key]: typeof format === 'function' ? format(value) : value,
                }),
                {}
              );
              return (
                <Row key={translationKey}>
                  {t(translationKey, {
                    ...formattedValues,
                    interpolation: { escapeValue: false },
                  })}
                </Row>
              );
            })}
          </Box>
          <Desc>{t(`SUSPENSION_POPUP__${type}__DESC__BOTTOM`)}</Desc>
          <ShadowHandler ref={bottomRef} />
        </Scroll>
        <ShadowWrap>
          <Shadow isShow={isShowBottom} position='bottom' />
        </ShadowWrap>
      </ModalContent>
      <ModalFooter>
        <ButtonWrap>
          <ExternalLink href={helpCenterUrl}>
            <Button
              id='SUSPENSION__POPUP__BOTTOM__HELP_CENTER'
              color='tertiary'
            >
              {t('SUSPENSION__POPUP__BOTTOM__HELP_CENTER')}
            </Button>
          </ExternalLink>
          <Button id='COMMON__OK__SUSPENSION_MODAL' onClick={handleClick}>
            {t('COMMON__OK')}
          </Button>
        </ButtonWrap>
        <A href={guidelineUrl}>
          {t('SUSPENSION__POPUP__BOTTOM__COMMUNITY_GUIDELINE')}
        </A>
      </ModalFooter>
    </Modal>
  );
};

export default SuspensionModal;
export type { SuspensionModalProps };
