export const concatArrayBuffers: (buffers: ArrayBuffer[]) => ArrayBuffer = (
  buffers
) => {
  let totalByteLength = 0;
  buffers.forEach((buffer: ArrayBuffer) => {
    totalByteLength += buffer.byteLength;
  });

  const result = new Uint8Array(totalByteLength);
  let offset = 0;
  buffers.forEach((buffer: ArrayBuffer) => {
    result.set(new Uint8Array(buffer), offset);
    offset += buffer.byteLength;
  });
  return result.buffer;
};

export const readFileSync = (file: Blob | File) =>
  new Promise<Blob>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const blob = new Blob([reader.result as ArrayBuffer], {
        type: 'image/jpeg',
      });
      resolve(blob);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
