import { ParsedUrlQuery } from 'querystring';

/**
 * 동적 생성 URL에 대한 canonical url 생성
 * @param pathname next/router pathname으로 []의 동적 path
 * @param query
 * @returns searchParam, utm param등을 제외한 동적 path만 생성
 */
const getCanonicalUrl = (pathname: string, query: ParsedUrlQuery) => {
  const queries = Object.entries(query);
  let pathnameUrl = pathname;

  if (queries.length > 0) {
    queries.forEach(([key, value]) => {
      if (typeof value === 'string') {
        pathnameUrl = pathnameUrl.replace(`[${key}]`, value);
      } else if (Array.isArray(value) && value.length > 0) {
        pathnameUrl = pathnameUrl.replace(`[${key}]`, value[0]);
      }
    });
  }

  const canonicalUrl = `${process.env.NEXT_PUBLIC_HOST}${pathnameUrl}`;

  if (!canonicalUrl.endsWith('/')) {
    return canonicalUrl + '/';
  }

  return canonicalUrl;
};

export default getCanonicalUrl;
