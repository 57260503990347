import 'src/styles/globals.css';
import 'src/utils/polyfill';

import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { ThemeProvider } from '@emotion/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ErrorBoundary } from '@sentry/nextjs';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Provider as JotaiProvider, useSetAtom } from 'jotai';
import { DevTools } from 'jotai-devtools';
import { queryClientAtom } from 'jotai-tanstack-query';
import { useHydrateAtoms } from 'jotai/react/utils';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import { debounceTime, fromEvent } from 'rxjs';

import ErrorComponent from 'src/components/Error';
import FirstOpenManager from 'src/components/FirstOpenManager';
import HeartbeatManager from 'src/components/HeartbeatManager';
import Layout from 'src/components/Layout';
import UTMManager from 'src/components/UTMManager';
import useIsPwa from 'src/hooks/useIsPwa';
import useIsRtl from 'src/hooks/useIsRtl';
import store from 'src/stores';
import { setDTIDAtom } from 'src/stores/auth/atoms';
import theme from 'src/styles/theme';
import { PageMetas } from 'src/types/meta';
import { googleAppId } from 'src/types/OAuthToken';
import getDeviceInfo from 'src/utils/device/info';
import { LanguageProvider } from 'src/utils/language';
import { setViewportUnits } from 'src/utils/layout';
import { isAzarJsV2 } from 'src/utils/webview';
import { useRouter } from 'next/router';
import getCanonicalUrl from 'src/utils/canonicalUrl';

export const queryCache = new QueryCache(); // react-query cache
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  },
});
const HydrateAtoms = ({ children }: { children: ReactNode }) => {
  useHydrateAtoms([[queryClientAtom, queryClient]]);
  return <>{children}</>;
};

const Debug = dynamic(
  () =>
    import('src/components/Debug').catch(
      () => new Promise<React.FC>((resolve) => resolve(() => <></>))
    ),
  { ssr: false, suspense: false }
);

interface PageProps extends PageMetas {
  children?: React.ReactNode;
}

interface Props extends AppProps {
  pageProps: PageProps;
}

export const oneTrustToken = process.env.NEXT_PUBLIC_ONETRUST || '';

const DEFAULT_META_VIEWPORT_CONTENT =
  'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';

function MyApp({ Component, pageProps }: Props) {
  const isRtl = useIsRtl();

  const {
    metas = [
      {
        property: 'og:title',
        content: 'OG_TITLE',
        key: 'og:title',
      },
      {
        name: 'description',
        content: 'OG_DESC',
        key: 'description',
      },
      {
        property: 'og:description',
        content: 'OG_DESC',
        key: 'og:description',
      },
      {
        property: 'og:image',
        content: '/images/og/azarOgImage.png',
        key: 'og:image',
      },
    ],
  } = pageProps;

  const router = useRouter();

  // 현재 페이지의 경로
  const pathname = router.pathname;

  // URL의 쿼리 파라미터
  const query = router.query;

  const setDTID = useSetAtom(setDTIDAtom);

  const [userInteracted, setUserInteracted] = useState(false);

  const { isMobile, isWebKit } = getDeviceInfo();
  const isAndroid = isMobile && !isWebKit;

  useEffect(() => {
    setDTID();
  }, [setDTID]);
  useEffect(() => {
    const onInitialInteraction = () => {
      setUserInteracted(true);
      window.removeEventListener('pointerdown', onInitialInteraction);
    };

    window.addEventListener('pointerdown', onInitialInteraction);

    setViewportUnits();

    const subscription = fromEvent(window.visualViewport ?? window, 'resize')
      .pipe(debounceTime(100))
      .subscribe(() => {
        setViewportUnits();
      });

    return () => {
      subscription.unsubscribe();
    };
  }, []);
  useEffect(() => {
    document.body.classList.add('init');
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'Prod') {
      if (process.env.NEXT_PUBLIC_RELEASE) {
        // eslint-disable-next-line no-console
        console.log(`Build version: ${process.env.NEXT_PUBLIC_RELEASE}`);
      }
    }
  }, []);

  useIsPwa();

  /**
   * https://stackoverflow.com/questions/76026292/why-is-window-innerheight-incorrect-until-i-tap-chrome-android
   * 안드로이드 Chrome 환경에서 window.innerHeight가 정상값보다 크게 측정되고 최초 터치 이후 정상화되는 이슈
   *
   * interactive-widget 키 지원: Blink 엔진 기반 Chrome 180 버전 이상 (iOS는 Webkit 기반 동작이기 때문에 영향 없음)
   * https://developer.chrome.com/blog/viewport-resize-behavior?hl=ko#:%7E:text=Through%20the%20interactive-widget%20key,Visual%20Viewport%20and%20Layout%20Viewport.
   */
  const metaViewportInteractive = useMemo(
    () =>
      isAndroid && !userInteracted
        ? ', interactive-widget=resizes-content'
        : '',
    [isAndroid, userInteracted]
  );
  /**
   * viewport-fit=cover 웹뷰에서 사용시 ios에서 safe-area를 사용할 수 있습니다.
   * iOS 11.2 이상
   * env(safe-area-inset-top);
   * env(safe-area-inset-right);
   * env(safe-area-inset-bottom);
   * env(safe-area-inset-left);
   *
   * iOS 11.0 버전
   * constant() 사용
   */
  const metaViewportFitCover = useMemo(
    () => (isAzarJsV2() && !isAndroid ? ', viewport-fit=cover' : ''),
    [isAndroid]
  );
  const metaViewportContent = `${DEFAULT_META_VIEWPORT_CONTENT}${metaViewportInteractive}${metaViewportFitCover}`;

  return (
    <>
      {
        <Script
          type='text/javascript'
          strategy='beforeInteractive'
          src={`https://cdn.cookielaw.org/consent/${oneTrustToken}/OtAutoBlock.js`}
        />
      }
      <Script
        id='ga'
        dangerouslySetInnerHTML={{
          __html: `
            (function(dataLayer, gtmId) {
              window[dataLayer] = window[dataLayer] || [];
              window[dataLayer].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
              });
              const firstScriptElement = document.getElementsByTagName('script')[0];
              const scriptElement = document.createElement('script');
              const dataLayerQuery = dataLayer !== 'dataLayer' ? '&l=' + dataLayer : '';

              scriptElement.async = true;
              scriptElement.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId + dataLayerQuery;
              firstScriptElement.parentNode.appendChild(scriptElement, firstScriptElement);
            })('dataLayer', 'GTM-WQT6PKJ')
            function gtag(){dataLayer.push(arguments);}
            function addPagePageViewGtag(viewConfig){gtag('config', 'GTM-WQT6PKJ', viewConfig)}
            `,
        }}
      />
      <Head>
        <meta
          name='facebook-domain-verification'
          content='obrg4zuhup370msixm63loi0g8abga'
        />
        <meta name='viewport' content={metaViewportContent} />
        {pageProps.noindex && (
          <meta name='robots' content='noindex, nofollow' />
        )}
        {pageProps.canonical && (
          <link rel='canonical' href={getCanonicalUrl(pathname, query)} />
        )}
      </Head>
      <GoogleOAuthProvider clientId={googleAppId}>
        <QueryClientProvider client={queryClient}>
          <JotaiProvider store={store}>
            <DevTools store={store} />
            {/* HydrateAtoms queryClient를 inject해주는 역할 */}
            <HydrateAtoms>
              <ThemeProvider
                theme={{
                  ...theme,
                  isRtl,
                }}
              >
                <Layout metas={metas}>
                  <Debug />
                  <UTMManager />
                  <FirstOpenManager />
                  <HeartbeatManager />
                  <Component {...pageProps} />
                </Layout>
                <ReactQueryDevtools />
              </ThemeProvider>
            </HydrateAtoms>
          </JotaiProvider>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </>
  );
}

function WithLanguageProvider(props: Props) {
  return (
    <ErrorBoundary fallback={() => <ErrorComponent />}>
      <LanguageProvider>
        <MyApp {...props} />
      </LanguageProvider>
    </ErrorBoundary>
  );
}

export default WithLanguageProvider;
