import { getEmptyAudioTrack } from './empty-track';
import { attachMediaElement, detachMediaElement } from './view';

/**
 * Silent audio를 재생해서 WebKit에서 autoplay 실패하는 상황 방지하는 처리
 *
 * iOS WebKit에서 Camera / Mic Permission이 없으면, user interaction 없이, audio autoplay가 안되는 문제가 있음
 * 이때, 실제 audio 대신 Silent Audio를 재생해 놓으면, 그 이후엔 autoplay 이슈 없이 audio를 재생할 수 있음
 *
 * Audio 재생은 user interaction (eg. Click)의 handler에서 해야함
 * Silent Audio 재생이 한번 됐더라도, 시간이 경과하면 다시 autoplay가 실패할 수 있음
 * 따라서, Audio가 재생될 가능성이 있는 동안에는 Silent Audio를 계속 재생하고 있어야 함
 */
export class SilentAudioPlayer {
  private static _instance?: SilentAudioPlayer;

  private audio: HTMLAudioElement;

  private started = false;

  private constructor() {
    this.audio = new Audio();
  }

  public static get Instance() {
    if (this._instance === undefined) {
      this._instance = new this();
    }

    return this._instance;
  }

  public start() {
    if (this.started) return;

    const emptyAudioTrack = getEmptyAudioTrack();
    const stream = new MediaStream([emptyAudioTrack]);

    const handleCanPlay = () => {
      this.started = true;
    };
    const handlePlayFailed = () => {
      this.started = false;
    };

    attachMediaElement(this.audio, stream, handleCanPlay, handlePlayFailed);
  }

  public stop() {
    detachMediaElement(this.audio);
    this.started = false;
  }
}
