import React, { useEffect, useRef } from 'react';

const useIntersection = (
  handleIntersect: IntersectionObserverCallback,
  options?: IntersectionObserverInit
) => {
  const observer = useRef<IntersectionObserver | void>();
  const observerRef = React.useCallback(
    (target: HTMLElement | null) => {
      if (target) {
        observer.current = new IntersectionObserver(handleIntersect, options);
        observer.current.observe(target);
      }
    },
    [handleIntersect, options]
  );
  useEffect(
    () => () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    },
    []
  );

  return { observerRef };
};

export default useIntersection;
