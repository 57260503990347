import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { showModalAtom } from 'src/stores/modal/atoms';
import { webToAppLastModalShownAtom } from 'src/stores/webToApp/atom';
import { ModalType } from 'src/types/Modal';
import WebToAppModal from 'src/components/WebToAppModal';
import { WebToAppModalType } from 'src/types/WebToApp';
import { differenceInHours } from 'date-fns';
import getDeviceInfo from 'src/utils/device/info';
import { eventMatchPageAtom } from 'src/stores/match/atoms';
import useLogin from 'src/hooks/useLogin';
import { OrderedModalHookType } from './types';

export const useWebToAppModal: OrderedModalHookType = () => {
  const [lastModalShown, setLastModalShown] = useAtom(
    webToAppLastModalShownAtom
  );
  const showModal = useSetAtom(showModalAtom);
  const eventMatchPage = useAtomValue(eventMatchPageAtom);
  const { isLogin } = useLogin();
  const [isResolved, setIsResolved] = useState(false);

  const shouldShowModal = useCallback(() => {
    if (!isLogin) return false;
    const { isMobile } = getDeviceInfo();
    if (!isMobile) return false;

    if (eventMatchPage !== 'main') return false;
    if (lastModalShown === null) return true;

    const now = new Date();
    const lastShownDate = lastModalShown;
    const hoursDiff = differenceInHours(now, lastShownDate);

    return hoursDiff >= 24;
  }, [eventMatchPage, lastModalShown, isLogin]);

  const handleShowModal = useCallback(() => {
    const now = new Date();
    setLastModalShown(now);
    showModal({
      key: ModalType.WEB_TO_APP,
      component: () => (
        <WebToAppModal
          type={WebToAppModalType.BASIC}
          onClose={() => setIsResolved(true)}
        />
      ),
    });
  }, [setLastModalShown, showModal]);

  const show = useCallback(() => {
    if (!shouldShowModal()) {
      setIsResolved(true);
      return;
    }
    handleShowModal();
  }, [shouldShowModal, handleShowModal]);

  return {
    isResolved,
    show,
  };
};
