import styled from '@emotion/styled';

import RtlContainer from 'src/components/RtlContainer';

export const RootStyle = styled(RtlContainer)`
  overflow: hidden;
  display: flex;
  flex-grow: 1;
`;
export const Container = styled.div`
  display: flex;
  flex-grow: 1;
`;
