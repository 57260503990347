export enum GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NONBINARY = 'NONBINARY',
}

export enum LoginType {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  APPLE = 'APPLE',
  SMS = 'SMS',
}

export interface RegisterParamsBase {
  birthYear: number;
  birthMonth: number;
  birthDay: number;
  gender: GENDER;
  // gender === NONBINARY이면 childGender는 필수
  childGender?: string;
  consents: Record<string, string>; // 회원가입을 위해 유저가 동의한 필수 약관
}

export interface NonBinaryChildGender {
  key: string;
  localizedText: string;
}

export type BirthdayUIType = 'BIRTHDAY_WEB_A' | 'BIRTHDAY_WEB_B';

export interface SignUpSettingsResponse {
  genderSettings: {
    nonBinaryChildGenders: Array<NonBinaryChildGender>;
    nonBinaryEnabled: boolean;
    hideGenderOptionEnabled: boolean;
    hideGenderDefaultValue: boolean;
  };
  // @see https://www.notion.so/hpcnt/AB-113ce00cd3548038b0fbcb8a01caa8e4?pvs=4#119ce00cd35480c9badef132bda8f776
  birthdaySettings: {
    uiType: BirthdayUIType;
    blockAlertAge: number;
  };
}
