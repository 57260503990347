import { ParsedUrlQuery } from 'querystring';
import * as Sentry from '@sentry/nextjs';

export const hexToRgb = (hex: string) =>
  /^#?([a-fA-F\d]{2})([a-fA-F\d]{2})([a-fA-F\d]{2})$/i
    .exec(hex)
    ?.slice(1, 4)
    .map((a) => parseInt(a, 16))
    .join(',');

export const numFormat = (num: number, digits = 1) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((el) => num >= el.value);
  return item
    ? `${num / item.value}`.slice(0, 2 + digits).replace(rx, '$1') + item.symbol
    : '0';
};

export const numComma = (num: number) => {
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ',');
};

type UnknownObject = Record<string, unknown>;
export function deepEqual(a: unknown, b: unknown): boolean {
  // 두 값이 동일한 경우
  if (a === b) {
    return true;
  }

  // 두 값 중 하나라도 객체가 아닌 경우
  if (
    typeof a !== 'object' ||
    a === null ||
    typeof b !== 'object' ||
    b === null
  ) {
    return false;
  }

  // 두 객체의 속성 수가 다른 경우
  if (Object.keys(a).length !== Object.keys(b).length) {
    return false;
  }

  // 모든 속성들이 동일한지 확인
  for (const prop in a) {
    if (
      !Object.prototype.hasOwnProperty.call(b, prop) ||
      !deepEqual((a as UnknownObject)[prop], (b as UnknownObject)[prop])
    ) {
      return false;
    }
  }

  return true;
}

export const formatDuration = (duration: number) => {
  const minute = 60 * 1000;
  if (duration < minute) {
    return `${Math.max(Math.floor(duration / 1000), 1)}s`;
  }
  let ret = '';
  const hour = 60 * minute;
  if (duration >= hour) {
    ret += `${Math.floor(duration / hour)}h `;
  }
  const m = Math.floor((duration % hour) / minute);
  if (m) {
    ret += `${m}m`;
  }
  return ret.trim();
};

/* eslint-disable no-useless-escape */
export const filterPrivacy = (text: string) => {
  const regEmail =
    /[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}/gi; //이메일
  const regPhone =
    /[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3,4}[-\s\.]?[0-9]{3,6}/gi; //전화번호
  // eslint-disable-next-line no-irregular-whitespace
  const regUserNumber = /(\d{6}[ ,-]-?[1-4]\d{6})|(\d{6}[ ,-]?[1-4])/gi; //주민번호
  const regBankNumber = /([0-9,\-]{3,6}\-[0-9,\-]{2,6}\-[0-9,\-])/gi; //계좌
  return text
    .replace(regEmail, '****')
    .replace(regPhone, '****')
    .replace(regUserNumber, '****')
    .replace(regBankNumber, '****');
};
/* eslint-enable no-useless-escape */

export const getUTMParams = (query: ParsedUrlQuery) => {
  const newUTMParams: Record<string, string | string[] | undefined> = {};
  for (const param of Object.keys(query)) {
    if (param.includes('utm_')) {
      newUTMParams[param] = query[param];
    }
  }
  return newUTMParams;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const debounce = (callback: (...args: any) => void, delay: number) => {
  let timer: ReturnType<typeof window.setTimeout>;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => callback(...args), delay);
  };
};

const getDummyTextarea = () => {
  const textarea = document.createElement('textarea') as HTMLTextAreaElement;
  textarea.style.top = '0';
  textarea.style.left = '0';
  textarea.style.display = 'fixed';

  return textarea;
};

export const isClipboardSupported = () => navigator?.clipboard != null;
export const isClipboardCommandSupported = () =>
  document.queryCommandSupported?.('copy') ?? false;

/**
 * 인자로 받은 텍스트를 클립보드에 복사합니다.
 * @param text 복사할 텍스트
 *
 * @example
 * ```ts
 * const result = await copyToClipboard('하이');
 * if (result) {
 *   console.log('클립보드에 복사 성공');
 * } else {
 *   console.log('클립보드에 복사 실패');
 * }
 * ```
 */
export const copyToClipboard = (text: string) =>
  new Promise<boolean>((resolve) => {
    const rootElement = document.body;

    // if (isClipboardSupported()) {
    //   await navigator.clipboard.writeText(text);
    //   resolve(true);
    //   return;
    // }

    if (isClipboardCommandSupported()) {
      const textarea = getDummyTextarea();
      textarea.value = text;

      rootElement.appendChild(textarea);

      textarea.focus();
      textarea.select();

      document.execCommand('copy');
      rootElement.removeChild(textarea);
      resolve(true);
      return;
    }

    resolve(false);
    return;
  });

export const isShareSupported = () =>
  typeof navigator !== 'undefined' && 'share' in navigator;

type ShareParam = ShareData & { imageUrl?: string };

/**
 * @param data AzarJs.shareV2의 text에는 url을 넣거나 text를 넣어준다. text가 없을시 url을 사용
 * @param onCopy
 * @param onFailure
 * @returns
 */
export const share = async (
  data: ShareParam,
  onCopy: () => void,
  onFailure: () => void
) => {
  if (!data.url) {
    return;
  }
  if (window.AzarJs?.shareV2) {
    window.AzarJs.shareV2(
      data.text || data.url,
      data.title || '',
      data.imageUrl || ''
    );
    return;
  }
  if (window.AzarJs?.share) {
    window.AzarJs.share(data.url);
    return;
  }

  if (isShareSupported()) {
    try {
      await navigator.share(data);
    } catch (_e) {
      // ignore share abort error
    }
    return;
  }

  const str = [data.title, data.text, data.url]
    .filter((item) => !!item)
    .join('\n');
  const result = await copyToClipboard(str);

  if (result) {
    onCopy();
    return;
  }
  onFailure();
};

export function getRandomNonRepeatingElements(arr: Array<any>, n: number) {
  // n 개의 임의의 중복되지 않는 element를 빼옴
  const selectedNumbers = [];
  for (let i = 0; i < n; i++) {
    const randomIndex = Math.floor(Math.random() * arr.length);
    const randomNum = arr.splice(randomIndex, 1)[0];
    selectedNumbers.push(randomNum);
  }

  return selectedNumbers;
}

export const emailReg = (text: string) => {
  /**
   * RFC 5321 길이 제한과 RFC 5322 이메일 형식 표준
   * API 서버의 공통 이메일 포멧 검증 로직과 동일하게 적용
   */
  const emailPattern = new RegExp(
    "^(?=.{1,64}@.{1,255}$)[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*" +
      '@(?:[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?\\.)+[A-Za-z]{2,}(?:\\.[A-Za-z]{2,})?$'
  );

  return emailPattern.test(text);
};

export function isObjectKeyTrue<T>(obj: T, key: keyof T) {
  const value = obj[key];
  if (typeof value !== 'string') return !!value;
  return value.toLowerCase() === 'true';
}

export const parseData = (data: any) => {
  try {
    const result = JSON.parse(data);
    return result;
  } catch (error) {
    return data;
  }
};

export const hasCommonElements = <T>(arr1: T[], arr2: T[]): boolean =>
  arr1.some((item) => arr2.includes(item));

/**
 * @description (주의) 성공적으로 변환 작업을 마친 후에는 주어진 타입으로 assertion이 이루어지기 때문에 별도 타입 검증 로직 필요할 수 있음
 * @param resource {T} 변환을 마친 후 기대하는 json의 타입.
 * @returns T | null
 */
export const convertUint8ArrayToJson = <T>(resource: ArrayBuffer): T | null => {
  if (resource instanceof ArrayBuffer && resource.byteLength > 0) {
    try {
      const uint8Array = new Uint8Array(resource);
      const stringArray = Array.from(uint8Array, (byte) =>
        String.fromCharCode(byte)
      );

      // eot 제거를 위한 정규식 추가
      const controlCharactersStart = 0x00;
      const controlCharactersEnd = 0x1f;
      const regex = new RegExp(
        `[${String.fromCharCode(controlCharactersStart)}-${String.fromCharCode(controlCharactersEnd)}]`,
        'g'
      );
      const jsonString = stringArray.join('').replace(regex, '');
      const json = JSON.parse(jsonString) as T;

      return json;
    } catch (error) {
      Sentry.captureMessage(
        `Failed to converting ArrayBuffer to JSON: ${error}`
      );
      return null;
    }
  }
  return null;
};

export const hasProperty = <K extends string>(
  obj: object,
  key: K
): obj is { [P in K]: any } => {
  return key in obj;
};

export const hasProperties = <T extends object, K extends string>(
  obj: T,
  keys: K[]
): obj is T & { [P in K]: any } => {
  return keys.every((key) => hasProperty(obj, key));
};
