import { webRtcDefaultStartBitrateKbps } from './constants';

const DEFAULT_ALPHA = 0.6;

function toIntOrNull(value: number | null): number | null {
  if (value == null || Number.isNaN(value)) {
    return null;
  }
  return Math.floor(value);
}

export class BitrateMovingAverageCalculator {
  public static readonly instance = new BitrateMovingAverageCalculator();

  private alpha = DEFAULT_ALPHA;

  private averageBitrateKBps: number | null = null;

  public getAverageBitrateKBps(): number | null {
    return toIntOrNull(this.averageBitrateKBps);
  }

  public setAlpha(alpha: number) {
    this.alpha = alpha;
  }

  public updateBitrate(newBitrateKBps: number) {
    const prevAverageBitrateKBps =
      this.averageBitrateKBps || webRtcDefaultStartBitrateKbps;
    this.averageBitrateKBps =
      (1.0 - this.alpha) * prevAverageBitrateKBps + this.alpha * newBitrateKBps;
  }
}
