import React, { FC, useState } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

export const InputBase = ({ theme }: { theme: Theme }) => css`
  box-sizing: border-box;
  flex: 1;
  color: ${theme.color.white};

  outline: none;
  background: ${theme.color.gray100__dkGray150};
  border: 1px solid ${theme.color.gray100__dkGray150};
  :focus {
    border: 1px solid ${theme.color.green500};
  }
  border-radius: 8px;
  ::placeholder {
    font-size: 15px;
    color: ${theme.color.dkGray300};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: ${theme.color.white};
    -webkit-box-shadow: 0 0 0px 1000px ${theme.color.gray60__dkGray100} inset;
    transition: background-color 9999s ease-in-out 0s; // 자동완성때문에 인풋창이 노란색으로 변하는걸 막기 위해 delay
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const StyledInput = styled.input`
  ${InputBase}
  padding: ${({ theme }) =>
    theme.isRtl ? '24px 16px 6px 56px' : '24px 56px 6px 16px'};

  line-height: 15px;
  height: 56px;

  ${({ theme }) => theme.screenSize.mobile} {
    padding: 0px;
    padding-bottom: 4px;
    ${({ theme }) => (theme.isRtl ? 'padding-left' : 'padding-right')}: 40px;
    border: none;
    border-radius: 0;
    font-size: 24px;

    border-bottom: 2px solid ${({ theme }) => theme.color.gray700__dkGray700};
    background-color: transparent;
    margin-bottom: 10px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.green500};
    ::placeholder {
      color: ${({ theme }) => theme.color.gray200__dkGray200};
      opacity: 1;
    }
    :focus {
      border: none;
      border-bottom: 2px solid ${({ theme }) => theme.color.gray700__dkGray700};
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
      -webkit-text-fill-color: ${({ theme }) => theme.color.green500};
    }
    width: 100%;
    display: block;
  }
`;

export const Label = styled.label<{ isFocus: boolean }>`
  z-index: 1;
  position: absolute;
  top: 19px;
  ${({ theme }) => (theme.isRtl ? 'right' : 'left')}: 16px;
  color: ${({ theme }) => theme.color.gray600__dkGray500};
  transition:
    transform 150ms ease-out,
    font-size 150ms ease-out;
  font-size: 15px;
  box-sizing: border-box;
  ${({ isFocus }) =>
    isFocus &&
    css`
      transform: translateY(-60%);
      font-size: 12px;
    `}
  ${({ theme }) => theme.screenSize.mobile} {
    display: none;
  }
`;
const Form = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ theme }) => theme.screenSize.mobile} {
    flex: 1;
  }
`;
const ResetButton = styled.button`
  background-color: transparent;
  position: absolute;
  ${({ theme }) => (theme.isRtl ? 'left' : 'right')}: 16px;
  cursor: pointer;
  padding: 0;
  display: none;
  ${({ theme }) => theme.screenSize.mobile} {
    display: block;
    ${({ theme }) => (theme.isRtl ? 'left' : 'right')}: 0;
    color: ${({ theme }) => theme.color.green500};
  }
  width: 24px;
  height: 24px;
  top: 8px;
`;
const ResetImage = styled.img`
  height: 24px;
`;

export const ErrorRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  gap: 8px;
  width: 100%;
  align-items: flex-start;
  margin-top: 16px;
  ${({ theme }) => theme.screenSize.mobile} {
    margin-top: 0;
  }
`;
export const ErrorText = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.color.red500};
  a {
    color: inherit;
  }
`;
export const ErrorIcon = styled.img``;
export interface InputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  value?: string;
  onChangeText: (text: string) => void;
  onSubmit?: () => void;
  onFocus?: () => void;
  placeholder: string;
  onClickHide?: () => void;
  errorMessage?: string;
  isShowResetButton?: boolean;
}

export const ErrorMessage: React.FC<{ message: string }> = ({ message }) => {
  const { t } = useTranslation();

  return (
    <ErrorRow>
      <ErrorIcon src='/images/icons/icWarningRed.svg' alt='error' />
      <ErrorText dangerouslySetInnerHTML={{ __html: t(message) }}></ErrorText>
    </ErrorRow>
  );
};
const Input: FC<InputProps> = ({
  placeholder,
  value,
  onFocus,
  type,
  errorMessage,
  onChangeText,
  ...props
}) => {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <>
      <Form>
        {placeholder && (
          <Label htmlFor={placeholder} isFocus={isFocus || !!value}>
            {placeholder}
          </Label>
        )}
        <StyledInput
          {...props}
          id={placeholder}
          onFocus={() => {
            setIsFocus(true);
            if (onFocus) onFocus();
          }}
          onBlur={() => {
            setIsFocus(false);
          }}
          onChange={(e) => onChangeText(e.target.value)}
          type={type}
        />
        {!!value && (
          <ResetButton
            type='button'
            onClick={() => {
              onChangeText('');
            }}
          >
            <ResetImage src='/images/icons/icCloseCircle.svg' alt='reset' />
          </ResetButton>
        )}
      </Form>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </>
  );
};

export default Input;
