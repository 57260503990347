import { FC } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Button from 'src/components/Button';
import ExternalLinkBase from 'src/components/ExternalLink';
import { ModalBackground } from 'src/components/Modal';
import ModalLayoutBase, { ModalFooter } from 'src/components/ModalLayout';

const ModalLayout = styled(ModalLayoutBase)`
  min-width: 360px;
  min-height: 489px;
`;

const RootStyle = styled.div`
  width: 100%;
  flex-direction: column;
  height: 480px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.screenSize.mobile} {
    flex: 1;
  }
`;
const ImagePlaceholder = styled.img`
  margin-bottom: 40px;
`;
const Title = styled.p`
  font-weight: 700;
  color: ${({ theme }) => theme.color.white};
  font-size: 20px;
  margin-bottom: 12px;
  text-align: center;
`;
const Description = styled.p`
  color: ${({ theme }) => theme.color.white};
  font-size: 16px;
  width: 300px;
  text-align: center;
  word-break: keep-all;
`;

const ExternalLink = styled(ExternalLinkBase)`
  text-decoration: none;
`;

interface ErrorAccountsModalProps {
  errorMessage?: string;
  isShowSubmitButton?: boolean;
}

const ErrorAccountsModal: FC<ErrorAccountsModalProps> = ({
  errorMessage,
  isShowSubmitButton = true,
}) => {
  const { t } = useTranslation();
  return (
    <ModalBackground>
      <ModalLayout isDim>
        <RootStyle>
          <ImagePlaceholder
            src='/images/icons/imgPlaceholder.svg'
            alt='Error Placeholder'
          />
          <Title>{t('ERROR_PROBLEM')}</Title>
          {errorMessage && <Description>{t(errorMessage)}</Description>}
        </RootStyle>
        <ModalFooter>
          {isShowSubmitButton && (
            <ExternalLink href={t('CUSTOMER_SERVICE')}>
              <Button id='LOGIN__CONTACT_US'>{t('LOGIN__CONTACT_US')}</Button>
            </ExternalLink>
          )}
        </ModalFooter>
      </ModalLayout>
    </ModalBackground>
  );
};

export default ErrorAccountsModal;
export type { ErrorAccountsModalProps };
