import { useEffect } from 'react';

type Listener = () => void;

const useBeforeunload = (listener: Listener) => {
  useEffect(() => {
    window.addEventListener('beforeunload', listener);

    return () => {
      window.removeEventListener('beforeunload', listener);
    };
  }, [listener]);
};

export default useBeforeunload;
