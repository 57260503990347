import styled from '@emotion/styled';

const Container = styled.div`
  flex-grow: 1;
  min-width: 0;
  height: 183px;
  background-color: ${({ theme }) => theme.color.gray100__dkGray150};
  border-radius: 16px;
`;

const Top = styled.div`
  background-color: ${({ theme }) => theme.color.gray200__dkGray200};
  height: 80px;
  border-radius: 16px 16px 0 0;
`;

const Bottom = styled.div`
  padding: 12px;
  height: 103px;
  border-radius: 0 0 16px 16px;
  display: flex;
  justify-content: center;
`;

const BottomLabel = styled.div`
  width: 72px;
  height: 12px;
  border-radius: 100px;
  background: ${({ theme }) => theme.color.gray200__dkGray200};
`;

const GemLackCardSkeleton = () => (
  <Container>
    <Top />
    <Bottom>
      <BottomLabel />
    </Bottom>
  </Container>
);

export default GemLackCardSkeleton;
