import {
  EffectRenderer,
  EffectType,
} from '@hyperconnect/effects/dist/EffectRenderer';
import { OverloadParameters } from 'src/types/utils';
import { ControlledPromise } from 'src/utils/controlled-promise';
import MediaStreamPipe from 'src/utils/media/pipes/base';
import * as Sentry from '@sentry/nextjs';

class EffectRendererPipe extends MediaStreamPipe {
  // SSR 불가능해서 런타임에 생성
  private static effectRenderer: EffectRenderer | null;

  private static initPromise: ControlledPromise<boolean> | null = null;

  public static async initialize() {
    if (EffectRendererPipe.initPromise) {
      return EffectRendererPipe.initPromise.promise;
    }

    EffectRendererPipe.initPromise = new ControlledPromise<boolean>();

    try {
      const effectRenderer = new EffectRenderer();
      const isInitSuccess = await effectRenderer.initialize(
        `mediapipe/tasksvision`,
        `mediapipe/face_landmarker.task`
      );

      if (isInitSuccess) {
        EffectRendererPipe.effectRenderer = effectRenderer;
      }
      EffectRendererPipe.initPromise.resolve(isInitSuccess);
    } catch (err) {
      EffectRendererPipe.initPromise.resolve(false);
    }
    return EffectRendererPipe.initPromise.promise;
  }

  public static removeAllEffects() {
    EffectRendererPipe.effectRenderer?.removeAll();
  }

  public static async setEffect(
    ...args: OverloadParameters<EffectRenderer['setEffect']>
  ) {
    const isInitSuccess = await EffectRendererPipe.initialize();
    if (!isInitSuccess || !EffectRendererPipe.effectRenderer) return false;

    try {
      const [effectType, arrayBuffer] = args;

      const result = await EffectRendererPipe.effectRenderer.setEffect(
        effectType as any,
        arrayBuffer
      );

      switch (effectType) {
        case EffectType.FaceRetouch:
          EffectRendererPipe.effectRenderer.setEffectStrength(effectType, 1.0);
          break;
        case EffectType.BackgroundEffect:
          EffectRendererPipe.effectRenderer.setEffectStrength(effectType, 1.0);
          break;
      }

      return result;
    } catch (error) {
      Sentry.captureException(error);
      return false;
    }
  }

  public async renderVideo() {
    const inputVideoElement = this.videoEl;
    const outputCanvasElement = this.canvasEl;

    if (!outputCanvasElement || !inputVideoElement) {
      return;
    }

    if (EffectRendererPipe.initPromise === null) {
      EffectRendererPipe.initialize();
    }

    this.render = async () => {
      if (
        !inputVideoElement ||
        !outputCanvasElement ||
        inputVideoElement.readyState !== HTMLMediaElement.HAVE_ENOUGH_DATA
      ) {
        return;
      }

      if (
        EffectRendererPipe.initPromise?.ended &&
        EffectRendererPipe.effectRenderer
      ) {
        const { videoWidth, videoHeight } = inputVideoElement;
        outputCanvasElement.width = videoWidth;
        outputCanvasElement.height = videoHeight;
        EffectRendererPipe.effectRenderer.render(
          inputVideoElement,
          outputCanvasElement
        );
      } else {
        this.drawOriginalVideo();
      }
    };
  }
}

export default EffectRendererPipe;
