import { useRouter } from 'next/router';
import QueryString from 'qs';
import { useEffect } from 'react';
import useEvent from 'src/hooks/useEvent';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { getUTMParams } from 'src/utils/common';

export default function useScreenOpen() {
  const router = useRouter();
  const pushEvent = useEvent();
  useEffect(() => {
    if (router.isReady) {
      window.addPagePageViewGtag({ page_path: router.pathname });
      const debounceScreenOpenEvent = setTimeout(() => {
        pushEvent({
          eventType: EVENT_TYPE.WEB,
          eventName: EVENT_NAME.SCREEN_OPENED,
          eventParams: {
            path: router.pathname,
            queryParams: QueryString.stringify(router.query),
          },
          options: { utm_params: getUTMParams(router.query) },
        });
      }, 100);
      return () => {
        clearTimeout(debounceScreenOpenEvent); // 이 전의 timer를 clear합니다.
      };
    }
  }, [pushEvent, router.pathname, router.isReady, router.query]);
}
