import { FC } from 'react';

import styled from '@emotion/styled';

import color from 'src/styles/color';

const RootStyle = styled.div`
  display: flex;
  background-color: ${color.dkGray50};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin: 0px;
  padding: 0 32px 24px 32px;
`;
const Title = styled.p`
  font-weight: 600;
  font-size: 40px;
  /* identical to box height */
  margin: 0;
  margin-top: 40px;
  text-align: center;
  line-height: 60px;
  color: ${color.dkGray900};
`;
const Desc = styled.p`
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  padding: 0px 32px;
  color: ${color.dkGray400};
  margin-top: 8px;
`;
const PlaceholderImage = styled.img``;
const Error: FC = () => (
  <RootStyle>
    <PlaceholderImage
      src='/images/error/imgPlaceholder.svg'
      alt='Error placeholder'
    />
    <Title>Something went wrong.</Title>
    <Desc>
      An unexpected error occurred. Please refresh the page or try again later.
      Apologies for the inconvenience.
    </Desc>
  </RootStyle>
);
export default Error;
