import { FC } from 'react';

import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';

import ModalLayoutBase, {
  MobileModalType,
  ModalHeader,
  ModalInner,
} from 'src/components/ModalLayout';
import {
  backStepAtom,
  isRegisterLoadingAtom,
  stepAtom,
} from 'src/stores/register/atoms';
import { loginModalHeaderAtom } from 'src/stores/smsLogin/atoms';

import RegisterStep1 from './RegisterStep1';
import RegisterStep2 from './RegisterStep2';
import RegisterStep3 from './RegisterStep3';
import RegisterStep4 from './RegisterStep4';
import { ModalNavButtonType } from 'src/types/Modal';
import { useGetConsents } from 'src/hooks/useGetConsents';

const Steps = [RegisterStep1, RegisterStep2, RegisterStep3, RegisterStep4];

const ModalLayout = styled(ModalLayoutBase)<{ minHeight?: string }>`
  width: 500px;

  ${({ minHeight }) =>
    minHeight
      ? `
      min-height: ${minHeight};
      height: auto;

      & > ${ModalInner} {
        min-height: inherit;
      }
  `
      : `
    height: 600px;
  `}
`;

const Register: FC = () => {
  const step = useAtomValue(stepAtom);
  const backStep = useSetAtom(backStepAtom);
  const loginModalHeader = useAtomValue(loginModalHeaderAtom);
  const isRegisterLoading = useAtomValue(isRegisterLoadingAtom);
  const { data: consentsData } = useGetConsents();
  const { uiType: consentsUiType } = consentsData?.data?.result || {};
  const Step = Steps[step - 1];

  const handleBack = () => {
    backStep();
    if (step === 2 && consentsUiType === 'CONSENT_WEB_B') {
      backStep();
    }
  };

  return (
    <ModalLayout
      onBack={loginModalHeader?.onBack || (step !== 4 ? handleBack : undefined)}
      navButtonType={
        step !== 4 ? ModalNavButtonType.BACK : ModalNavButtonType.NONE
      }
      mobileType={MobileModalType.SCREEN_MODAL}
      isDim
      closeWhenClickOutside={step !== 4 && !isRegisterLoading}
      minHeight={step === 1 || step === 4 ? '600px' : undefined}
    >
      {loginModalHeader?.component || (step !== 4 && <ModalHeader />)}
      <Step />
    </ModalLayout>
  );
};

export default Register;
