import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { hoverAndActiveCss } from 'src/utils/styled';

export const HEADER_HEIGHT = '72px';
export const TABLET_HEADER_HEIGHT = '54px';

export const TopPadding = styled.div`
  flex-shrink: 0;
  height: ${HEADER_HEIGHT};
  ${({ theme }) => theme.screenSize.tablet} {
    height: ${TABLET_HEADER_HEIGHT};
  }
`;
export const RootStyle = styled(TopPadding)<{
  isShow: boolean;
  isTop?: boolean;
}>`
  position: fixed;
  z-index: 31;
  transform: translateY(${({ isShow }) => (isShow ? '0%' : '-100%')});
  transition:
    transform 0.5s ease-out,
    background-color 0.3s ease-out;
  ${({ isTop }) => {
    if (isTop) {
      return 'background-color: transparent;';
    }
    return `
      backdrop-filter: blur(10px);
      background-color: rgba(0, 0, 0, 0.2);
    `;
  }}
  width: 100%;
  flex-direction: row;
  padding: 16px 24px;
  ${({ theme }) => theme.screenSize.tablet} {
    padding: 11px 16px;
    flex-direction: row-reverse;
  }
  align-items: center;
  display: flex;
`;

export const TabLabel = styled.div<{ active?: boolean }>`
  white-space: nowrap;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  ${({ theme }) => theme.screenSize.tablet} {
    line-height: 22px;
    font-size: 16px;
  }
  cursor: pointer;
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  color: ${({ theme }) => theme.color.white};
`;

export const Tab = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
`;
export const ActiveTabBar = styled.div<{ isShow: boolean }>`
  visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')};
  width: 100%;
  height: 4px;
  border-radius: 7px;
  background-color: ${({ theme }) => theme.color.white};
`;
export const Logo = styled.img`
  cursor: pointer;
`;

export const SNSIcon = ({ theme }: { theme: Theme }) => css`
  fill: ${theme.color.gray30__dkGray50};
  width: 14px;
  height: 14px;
  ${theme.screenSize.tablet} {
    display: none;
  }
`;

export const SNSDivider = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${({ theme }) => theme.color.gray200};
  ${({ theme }) => theme.screenSize.tablet} {
    display: none;
  }
`;
export const LoginButton = styled('button' as 'div')`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 100px;
  padding: 8px 24px;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  color: ${({ theme }) => theme.color.black};
  justify-content: center;
  align-items: center;
  gap: 10px;
  line-height: 22px;
  white-space: nowrap;
  ${({ theme }) => theme.screenSize.tablet} {
    font-size: 13px;
    padding: 8px 16px;
    height: 32px;
    line-height: 16px;
  }
  cursor: pointer;
  ${({ theme }) =>
    hoverAndActiveCss(`
      background-color: ${theme.color.whiteBg__dkBlackBg};
      color: ${theme.color.gray900__dkGray970};
      > svg {
        filter: brightness(20);
      }
      > ${SNSDivider} {
        color: ${theme.color.gray200};
      }
  `)}
`;

export const ShopButtonLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: inherit;
`;

export const ImgGemSquare = styled.img`
  width: 24px;
  height: 24px;

  ${({ theme }) => theme.screenSize.tablet} {
    width: 17.45px;
    height: 17.45px;
  }
`;

export const GemPadding = styled.div`
  flex-shrink: 0;
  width: 6px;
  ${({ theme }) => theme.screenSize.tablet} {
    width: 4px;
  }
`;

export const GemAmount = styled.div`
  font-size: inherit;
  ${({ theme }) => theme.screenSize.tablet} {
    font-size: 13px;
  }
`;
