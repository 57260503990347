import { PurchaseType } from '@hyperconnect/azar-jsapi';
import { loadTossPayments } from '@tosspayments/payment-sdk';

import { GemProduct } from 'src/types/Payments';

import { numComma } from './common';

const clientKey = process.env.NEXT_PUBLIC_TOSS_CLIENT_KEY || '';

export const initPayments = () => loadTossPayments(clientKey);

export interface IProductCalcGem {
  num: number;
  base: number;
  bonus: number;
  promo: number;
  text: string;
  baseText: string;
  bonusText: string;
  promoText: string;
}

export const getProductCalcGem = (product: Partial<GemProduct> | undefined) => {
  if (!product) {
    return {
      num: 0,
      base: 0,
      bonus: 0,
      promo: 0,
      text: '',
      baseText: '',
      bonusText: '',
      promoText: '',
    };
  }
  const {
    baseGemAmount = 0,
    bonusGemAmount = 0,
    membershipGemAmount = 0,
    promoGemAmount = 0,
  } = product;

  const base = baseGemAmount;
  const bonus = membershipGemAmount;
  const promo = promoGemAmount + bonusGemAmount;
  const bonusTotal = bonus + promo;

  return {
    num: base + bonusTotal,
    text: `${numComma(base)}${bonusTotal ? ` + ${numComma(bonusTotal)}` : ''}`,
    base,
    baseText: numComma(base),
    bonus,
    bonusText: numComma(bonus),
    promo,
    promoText: numComma(promo),
  };
};

export const getGemProductName = (product: Partial<GemProduct> | undefined) =>
  `${getProductCalcGem(product).text} Gems`;

export const getPromotion = (products: GemProduct[] | undefined) => {
  if (!products) {
    return { isPromotion: false, promoPercent: 0, promoTotalPercent: 0 };
  }

  const mostPromotionProduct = products.reduce(
    (acc, cur) => {
      const { base, bonus, promo } = getProductCalcGem(cur);

      const promoPercent = Math.floor((promo / base) * 100);
      const totalPercent = Math.floor(((promo + bonus) / base) * 100);

      return {
        promoPercent: Math.max(acc.promoPercent, promoPercent),
        totalPercent: Math.max(acc.totalPercent, totalPercent),
      };
    },
    {
      promoPercent: 0,
      totalPercent: 0,
    }
  );

  return {
    isPromotion: mostPromotionProduct.promoPercent > 0,
    promoPercent: mostPromotionProduct.promoPercent,
    promoTotalPercent: mostPromotionProduct.totalPercent,
  };
};

export const startPurchase = (productId: string, type?: PurchaseType) => {
  const platform = window.AzarJs?.getPlatform();
  if (platform) {
    if (platform === 'ANDROID') {
      window.AzarJs?.startPurchaseV2?.(productId, type || 'inapp');
    } else {
      window.AzarJs?.startPurchase(productId);
    }
  }
};

export default getGemProductName;
