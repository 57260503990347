import { FC, useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import 'react-mobile-datepicker-ts/dist/main.css';

import Button from 'src/components/Button';
import InputBase from 'src/components/Input';
import { ModalFooter, ModalTitle } from 'src/components/ModalLayout';
import Select from 'src/components/Select';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import useEvent from 'src/hooks/useEvent';
import useVerifyPreparation from 'src/hooks/useVerifyPreparation';
import {
  sendVerifyCodeToUserAtom,
  smsLoginStep1PhoneNumberAtom,
  smsLoginStep2VerifyCodeAtom,
} from 'src/stores/smsLogin/atoms';
import { DropdownItem } from 'src/types/DateSelector';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';

import { LoginModalHeader, Wrap } from './styles';

const SelectWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme }) => theme.screenSize.mobile} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;
const CountrySelect = styled(Select)`
  width: 100%;
  margin-bottom: 12px;
  ${({ theme }) => (theme.isRtl ? 'margin-left' : 'margin-right')}: 8px;
  ${({ theme }) => theme.screenSize.mobile} {
    margin-bottom: 8px;
    display: none;
  }
` as typeof Select;

const DescWrap = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;
`;
const Desc = styled.p`
  font-size: 12px;
  line-height: 18px;

  color: ${({ theme }) => theme.color.gray500__dkGray400};
  margin-top: 16px;
  ${({ theme }) => theme.screenSize.mobile} {
    margin-top: 0;
  }
`;
const WarningWrap = styled.div`
  display: flex;
  align-items: flex-start;
`;
const WarningIcon = styled.img`
  ${({ theme }) => (theme.isRtl ? 'margin-left' : 'margin-right')}: 8px;
`;
const WarningText = styled.p`
  font-size: 12px;
  line-height: 18px;

  color: ${({ theme }) => theme.color.gray700__dkGray700};
`;
const Form = styled.form`
  flex: 1;
`;

const MobileCountryCode = styled.select`
  display: none;
  outline: none;
  height: min-content;
  ${({ theme }) => theme.screenSize.mobile} {
    height: 44px;
    margin-bottom: 10px;
    display: block;
    ${({ theme }) => (theme.isRtl ? 'margin-left' : 'margin-right')}: 8px;
    padding: 0px;
    padding-bottom: 4px;
    border: none;
    border-radius: 0;

    border-bottom: 2px solid ${({ theme }) => theme.color.gray700__dkGray700};
    background-color: transparent;
    font-weight: 700;
    font-size: 24px;
    width: 70px;
    color: ${({ theme }) => theme.color.green500};
    ::placeholder {
      color: ${({ theme }) => theme.color.gray200__dkGray200};
      opacity: 1;
    }
    :focus {
      border: none;
      border-bottom: 2px solid ${({ theme }) => theme.color.gray700__dkGray700};
    }
  }
`;
const Input = styled(InputBase)`
  ${({ theme }) => theme.screenSize.mobile} {
    min-height: 44px;
  }
`;

const SmsLoginStep1: FC = () => {
  const { t } = useTranslation();
  const [number, setNumber] = useAtom(smsLoginStep1PhoneNumberAtom);
  const [phone, setPhone] = useState(number.phone);
  const device = useCssMediaDevice();

  const { status } = useAtomValue(smsLoginStep2VerifyCodeAtom);
  const sendVerifyCodeToUser = useSetAtom(sendVerifyCodeToUserAtom);
  const pushEvent = useEvent();
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SCREEN_OPENED__PHONE_NUMBER,
    });
  }, [pushEvent]);

  const handleClickNext = () => {
    setNumber({ ...number, phone });
    if (number.verifyPreparation.countryPrefixNumber) sendVerifyCodeToUser();
  };

  const { options, prefixList } = useVerifyPreparation();
  const handleChangeCountry = (v: DropdownItem | null) => {
    setNumber({
      ...number,
      verifyPreparation:
        prefixList.find((prefix) => prefix.countryName === v?.value) ||
        prefixList[0],
    });
  };
  const handleMobileChangeCountry = (
    v: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setNumber({
      ...number,
      verifyPreparation:
        prefixList.find((prefix) => prefix.countryName === v.target.value) ||
        prefixList[0],
    });
  };
  const onChangePhone = (text: string) => {
    if (number?.errorMessage) {
      setNumber({ ...number, errorMessage: '' });
    }
    if (!isNaN(Number(text))) {
      setPhone(text);
    }
  };

  return (
    <>
      <Wrap>
        <LoginModalHeader>
          <ModalTitle>{t('SIGNUP_PHONE_TITLE')}</ModalTitle>
        </LoginModalHeader>
        <SelectWrap>
          <CountrySelect
            absolute
            isSearchable={false}
            label={t('SIGNUP_PHONE_COUNTRY_PLACEHOLDER')}
            options={options}
            onChange={handleChangeCountry}
            value={options.find(
              (option) => option.value === number.verifyPreparation.countryName
            )}
          />
          <MobileCountryCode
            onChange={handleMobileChangeCountry}
            value={number.verifyPreparation.countryName}
            onBlur={() => {}}
          >
            {options.map((item) => (
              <option
                value={item.value}
                key={item.value}
                label={item.label.split(' ')[0]}
              >
                {item.label.split(' ')}
              </option>
            ))}
          </MobileCountryCode>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleClickNext();
            }}
          >
            <Input
              placeholder={t('SIGNUP_PHONE_PLACEHOLDER')}
              type='number'
              pattern='\d*'
              value={phone}
              errorMessage={number?.errorMessage}
              onChangeText={onChangePhone}
              autoFocus={device !== 'mobile'}
            />
          </Form>
        </SelectWrap>
        {!number?.errorMessage && (
          <DescWrap>
            <Desc>{t('SIGNUP_PHONE_INPUT_DESC')}</Desc>
            <WarningWrap>
              <WarningIcon src='/images/icons/icWarning.svg' alt='warning' />
              <WarningText>{t('SIGNUP_PHONE_NUMBER_GUIDE')}</WarningText>
            </WarningWrap>
          </DescWrap>
        )}
      </Wrap>
      <ModalFooter>
        <Button
          id='SIGNUP__NEXT__SMS_1'
          onClick={handleClickNext}
          disabled={phone.length < 3 || !!number?.errorMessage}
          loading={status === 'SENDING'}
        >
          {t('SIGNUP__NEXT')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default SmsLoginStep1;
