import getDeviceInfo from './device/info';

function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode?.removeChild(outer);

  return scrollbarWidth;
}

export const setVw = (includeScrollbar = false) => {
  const doc = document.documentElement;

  const width =
    window.innerWidth - (includeScrollbar ? 0 : getScrollbarWidth());
  doc.style.setProperty('--vw', `${width * 0.01}px`);
  doc.style.setProperty('--100vw', `${width}px`);
};

/**
 * var(--vh): 주소창을 제외한 vh
 * 관련 Web API 미지원 시 fallback var(--vh)를 적용 -> --vh 적용 뒤에 호출되어야 함
 */
const setVh = () => {
  const doc = document.documentElement;

  // svh 단위 지원 브라우저
  if (CSS.supports('height: 100svh')) {
    /**
     * viewport에 딱 맞게 렌더링해야되는 경우는 스크롤 개념이 배제된 fullscreen 상태
     * scroll하지 않았을 때에는 주소창 노출되어 있으므로 svh로 적용
     */
    doc.style.setProperty('--vh', '1svh');
    doc.style.setProperty('--100vh', '100svh');
    return;
  }

  const { isWebKit, isMobile } = getDeviceInfo();

  const isIOS = isWebKit && isMobile;

  /**
   * iOS 환경에서는 window.innerHeight에 가상 키보드 영역 제외됨
   * 일관되게 가상키보드 노출여부 무관하게 배제하도록 연산
   */
  const height =
    isIOS && window.visualViewport?.height
      ? window.visualViewport.height + window.visualViewport.offsetTop
      : window.innerHeight;
  doc.style.setProperty('--vh', height * 0.01 + 'px');
  doc.style.setProperty('--100vh', height + 'px');
};

/**
 * var(--dvh): 주소창과 가상 키보드를 제외한 vh
 */
const setDvh = () => {
  const doc = document.documentElement;

  if (window?.visualViewport) {
    const height = window.visualViewport.height;
    doc.style.setProperty('--dvh', height * 0.01 + 'px');
    doc.style.setProperty('--100dvh', height + 'px');
  } else {
    const computedStyle = getComputedStyle(doc);
    doc.style.setProperty('--dvh', computedStyle.getPropertyValue('--vh'));
    doc.style.setProperty(
      '--100dvh',
      computedStyle.getPropertyValue('--100vh')
    );
  }
};

export const setViewportUnits = () => {
  setVw();
  setVh();
  setDvh();
};
