import theme from 'src/styles/theme';

export type ButtonColor = 'primary' | 'secondary' | 'tertiary';
export type ButtonVariant = 'contained' | 'outlined' | 'text';
export type ButtonStatus = '&' | '&:hover' | '&:active' | '&:disabled';

type ThemedColorMap = {
  [status in Partial<ButtonStatus>]?: {
    [variant in ButtonVariant]?: {
      [buttonColor in ButtonColor]?: (typeof theme.color)[keyof typeof theme.color];
    };
  };
};

export const buttonColorMap: ThemedColorMap = {
  '&': {
    contained: {
      primary: theme.color.green500,
      secondary: theme.color.gray800__dkGray900,
      tertiary: theme.color.gray100__dkGray150,
    },
    outlined: {
      primary: 'transparent',
      secondary: 'transparent',
      tertiary: 'transparent',
    },
    text: {
      primary: 'transparent',
      secondary: 'transparent',
      tertiary: 'transparent',
    },
  },
  '&:hover': {
    contained: {
      primary: theme.color.green600,
      secondary: theme.color.gray700__dkGray700,
      tertiary: theme.color.gray300__dkGray250,
    },
    outlined: {
      primary: theme.color.gray30__dkGray50,
      secondary: theme.color.gray30__dkGray50,
      tertiary: theme.color.gray30__dkGray50,
    },
    text: {
      primary: 'transparent',
      secondary: 'transparent',
      tertiary: 'transparent',
    },
  },
  '&:active': {
    contained: {
      primary: theme.color.green600,
      secondary: theme.color.gray700__dkGray700,
      tertiary: theme.color.gray300__dkGray250,
    },
    outlined: {
      primary: theme.color.gray30__dkGray50,
      secondary: theme.color.gray30__dkGray50,
      tertiary: theme.color.gray30__dkGray50,
    },
    text: {
      primary: 'transparent',
      secondary: 'transparent',
      tertiary: 'transparent',
    },
  },
  '&:disabled': {
    contained: {
      primary: theme.color.gray200__dkGray200,
      secondary: theme.color.gray200__dkGray200,
      tertiary: theme.color.gray200__dkGray200,
    },
    outlined: {
      primary: 'transparent',
      secondary: 'transparent',
      tertiary: 'transparent',
    },
    text: {
      primary: 'transparent',
      secondary: 'transparent',
      tertiary: 'transparent',
    },
  },
};

export const textColorMap: ThemedColorMap = {
  '&': {
    contained: {
      primary: theme.color.black,
      secondary: theme.color.white__dkBlack,
      tertiary: theme.color.gray800__dkGray900,
    },
    outlined: {
      primary: theme.color.gray900__dkGray970,
      secondary: theme.color.gray900__dkGray970,
      tertiary: theme.color.gray900__dkGray970,
    },
    text: {
      primary: theme.color.gray600__dkGray500,
      secondary: theme.color.gray600__dkGray500,
      tertiary: theme.color.gray600__dkGray500,
    },
  },
  '&:hover': {
    text: {
      primary: theme.color.gray400__dkGray300,
      secondary: theme.color.gray400__dkGray300,
      tertiary: theme.color.gray400__dkGray300,
    },
  },
  '&:active': {
    text: {
      primary: theme.color.gray400__dkGray300,
      secondary: theme.color.gray400__dkGray300,
      tertiary: theme.color.gray400__dkGray300,
    },
  },
  '&:disabled': {
    contained: {
      primary: theme.color.gray400__dkGray300,
      secondary: theme.color.gray400__dkGray300,
      tertiary: theme.color.gray400__dkGray300,
    },
    outlined: {
      primary: theme.color.gray400__dkGray300,
      secondary: theme.color.gray400__dkGray300,
      tertiary: theme.color.gray400__dkGray300,
    },
    text: {
      primary: theme.color.gray200__dkGray200,
      secondary: theme.color.gray200__dkGray200,
      tertiary: theme.color.gray200__dkGray200,
    },
  },
};

export const borderColorMap: ThemedColorMap = {
  '&': {
    outlined: {
      primary: theme.color.gray400__dkGray300,
      secondary: theme.color.gray400__dkGray300,
      tertiary: theme.color.gray400__dkGray300,
    },
  },
  '&:hover': {
    outlined: {
      primary: theme.color.gray300__dkGray250,
      secondary: theme.color.gray300__dkGray250,
      tertiary: theme.color.gray300__dkGray250,
    },
  },
  '&:active': {
    outlined: {
      primary: theme.color.gray300__dkGray250,
      secondary: theme.color.gray300__dkGray250,
      tertiary: theme.color.gray300__dkGray250,
    },
  },
  '&:disabled': {
    outlined: {
      primary: theme.color.gray300__dkGray250,
      secondary: theme.color.gray300__dkGray250,
      tertiary: theme.color.gray300__dkGray250,
    },
  },
};

export const ColorMap: {
  [key: string]: ThemedColorMap;
} = {
  'background-color': buttonColorMap,
  color: textColorMap,
  'border-color': borderColorMap,
};
