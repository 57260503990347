export enum ToastType {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
}

export interface ToastProps {
  uuid: string;
  message: string;
  type: ToastType; // non-webview only
  duration?: number;
  title?: string; // webview only
  buttonLabel?: string; // webview only
  onButtonClick?: () => void; // webview only
  iconUrl?: string; // webview only
}
