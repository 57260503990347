import { ReactElement } from 'react';

import { AxiosRequestConfig } from 'axios';
import { atom } from 'jotai';

import {
  loginSMSAtom,
  retryLoginWithPhoneVerifyAtom,
  socialTokenAtom,
} from 'src/stores/auth/atoms';
import { eventMutateAtom } from 'src/stores/event/atoms';
import {
  FALLBACK_SIGNUP_SETTINGS,
  featureSettingParamsFromOptionalConsentsAtom,
  fetchSignUpSettingsAtom,
  LoginModalType,
  loginModalTypeAtom,
  registerAtom,
} from 'src/stores/register/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ErrorAlertMessage, ErrorResponse } from 'src/utils/api';
import { isAxiosError } from 'src/utils/error';
import { getLanguageCode } from 'src/utils/language';

import {
  checkAccountExistAPI,
  getVerifyPreparationAPI,
  sendVerifyCodeToUserAPI,
  verifyAccountAPI,
  verifyEmailAPI,
  VerifyPreparation,
} from './apis';
import { ModalType } from 'src/types/Modal';
import SMSRestrictedModal from 'src/components/SMSRestrictedModal';
import { atomWithReset, RESET } from 'jotai/utils';
import { UserSettingType } from 'src/stores/user/types';
import { showModalAtom } from 'src/stores/modal/atoms';

interface RetryData {
  type: LoginModalType;
  config: AxiosRequestConfig;
  cmpConsentsAccepted: boolean;
}
export const smsLoginStepAtom = atomWithReset(1);

export const retryDataAtom = atom<RetryData | null>(null);
export const retryWithSmsLoginAtom = atom(
  null,
  (get, set, params: RetryData) => {
    set(loginModalTypeAtom, LoginModalType.SMS_LOGIN);
    set(smsLoginStepAtom, 2);
    set(retryDataAtom, params);
  }
);

export const errorMessageAtom = atom<undefined | string>('');
const initSmsLoginStep1PhoneNumber = {
  phone: '',
  verifyPreparation: { countryName: undefined, countryPrefixNumber: undefined },
  errorMessage: '',
};
export const smsLoginStep1PhoneNumberAtom = atom<{
  phone: string;
  verifyPreparation: VerifyPreparation;
  errorMessage?: string;
}>(initSmsLoginStep1PhoneNumber);

const initSmsLoginStep2VerifyCode = {
  verifyCode: '',
  formattedPhoneNumber: '',
  errorMessage: '',
};
export const smsLoginStep2VerifyCodeAtom = atom<{
  verifyCode: string;
  formattedPhoneNumber: string;
  errorMessage?: string;
  status?: 'SENDING' | 'SENDED' | 'ERROR' | 'TURNSTILE';
}>(initSmsLoginStep2VerifyCode);

export const smsLoginVerifyPhoneAtom = atom<{
  phoneId: string;
  phoneToken: string;
  status?: 'CHECKING' | 'CHECKED' | 'ERROR';
}>({
  phoneId: '',
  phoneToken: '',
});

const initSmsLoginStep3Email = {
  email: '',
  errorMessage: '',
};
export const smsLoginStep3EmailAtom = atom(initSmsLoginStep3Email);

export const smsLoginBackStepAtom = atom(null, (get, set) => {
  switch (get(smsLoginStepAtom)) {
    case 1:
      set(loginModalTypeAtom, LoginModalType.LOGIN);
      break;
    case 2:
      set(smsLoginStep1PhoneNumberAtom, initSmsLoginStep1PhoneNumber);
      break;
    case 3:
      set(smsLoginStep2VerifyCodeAtom, initSmsLoginStep2VerifyCode);
      break;
    case 4:
      set(smsLoginStep3EmailAtom, initSmsLoginStep3Email);
  }
  if (get(smsLoginStepAtom) > 1) {
    set(smsLoginStepAtom, get(smsLoginStepAtom) - 1);
  }
});

const resetSMSLoginAtom = atom(null, (_get, set) => {
  set(smsLoginStep1PhoneNumberAtom, initSmsLoginStep1PhoneNumber);
  set(smsLoginStep2VerifyCodeAtom, initSmsLoginStep2VerifyCode);
  set(smsLoginStep3EmailAtom, initSmsLoginStep3Email);
  set(smsLoginStepAtom, RESET);
});

export const verifyPreparationAtom = atom<VerifyPreparation[]>([]);
export const getVerifyPreparationAtom = atom(null, (get, set) => {
  getVerifyPreparationAPI({
    languageCode: getLanguageCode(),
    platform: 'WEBCLIENT',
  }).then((data) => {
    const hasCountryName =
      get(smsLoginStep1PhoneNumberAtom).verifyPreparation.countryName !==
      undefined;
    const userLocationInfos = data.data.result.userLocationInfos || [];
    if (
      // SMS 인증 불가 국가
      !hasCountryName &&
      userLocationInfos[0] === undefined
    ) {
      set(showModalAtom, {
        key: ModalType.ERROR,
        component: SMSRestrictedModal,
      });
      return;
    }

    set(verifyPreparationAtom, userLocationInfos);
    set(smsLoginStep1PhoneNumberAtom, {
      ...get(smsLoginStep1PhoneNumberAtom),
      verifyPreparation:
        hasCountryName || userLocationInfos[0] === undefined
          ? get(smsLoginStep1PhoneNumberAtom).verifyPreparation
          : userLocationInfos[0],
    });
  });
});
export const sendVerifyCodeToUserAtom = atom(
  null,
  async (get, set, onSuccess?: () => void) => {
    const [countryPrefix, phone] = [
      get(smsLoginStep1PhoneNumberAtom).verifyPreparation.countryPrefixNumber,
      get(smsLoginStep1PhoneNumberAtom).phone,
    ];
    if (!countryPrefix) return;
    set(smsLoginStep2VerifyCodeAtom, {
      ...get(smsLoginStep2VerifyCodeAtom),
      status: 'SENDING',
    });
    try {
      const response = await sendVerifyCodeToUserAPI({
        countryPrefixNumber: countryPrefix,
        localeLanguageCode: getLanguageCode(),
        phoneNumber: phone,
        platform: 'WEBCLIENT',
      });
      set(smsLoginStep2VerifyCodeAtom, {
        ...get(smsLoginStep2VerifyCodeAtom),
        verifyCode: '',
        errorMessage: '',
        formattedPhoneNumber: response.data.result.formattedPhoneNumber,
        status: 'SENDED',
      });
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.SIGN_UP,
        eventName: EVENT_NAME.VERIFY_CLICK_GET_VERIFICATION_CODE,
      });
      set(smsLoginStepAtom, 3);
      onSuccess?.();
    } catch (error) {
      if (!isAxiosError<ErrorResponse<ErrorAlertMessage>>(error)) return;
      if (error.response?.data?.error.code === 'TURNSTILE_REQUIRED') {
        set(smsLoginStepAtom, 3);
        set(smsLoginStep2VerifyCodeAtom, {
          ...get(smsLoginStep2VerifyCodeAtom),
          status: 'TURNSTILE',
        });
        return;
      }
      set(smsLoginStep1PhoneNumberAtom, {
        ...get(smsLoginStep1PhoneNumberAtom),
        errorMessage: error.response?.data.error?.data?.alertBody,
      });
      set(smsLoginStep2VerifyCodeAtom, {
        ...get(smsLoginStep2VerifyCodeAtom),
        errorMessage: error.response?.data.error?.data?.alertBody,
        status: undefined,
      });
    }
    return;
  }
);
export const isExistAccountAtom = atom(false);

const verifyErrorMessages: {
  [key: string]: string;
} = { VERIFICATION_FAILED: 'SIGNUP_PHONE_VERIFICATION_FAIL' };

export const verifyAccountAtom = atom(null, async (get, set) => {
  set(smsLoginVerifyPhoneAtom, {
    ...get(smsLoginVerifyPhoneAtom),
    status: 'CHECKING',
  });
  const retryData = get(retryDataAtom);
  if (retryData) {
    const { verifyCode, formattedPhoneNumber } = get(
      smsLoginStep2VerifyCodeAtom
    );
    const signUpSettings =
      (await get(fetchSignUpSettingsAtom)) ?? FALLBACK_SIGNUP_SETTINGS;
    const retryPayload = {
      phoneVerification: {
        verifyCode,
        formattedPhoneNumber,
      },
      featureSettings: [
        {
          type: UserSettingType.HIDE_GENDER,
          value: signUpSettings.genderSettings.hideGenderDefaultValue,
        },
      ],
      cmpConsentsAccepted: retryData.cmpConsentsAccepted,
    };
    if (retryData?.type === LoginModalType.REGISTER) {
      set(registerAtom, retryPayload);
    } else if (retryData?.type === LoginModalType.LOGIN) {
      const originData = JSON.parse(retryData.config.data);
      set(retryLoginWithPhoneVerifyAtom, {
        ...retryData.config,
        data: {
          ...originData,
          params: [
            {
              ...originData.params[0],
              ...retryPayload,
            },
          ],
        },
      });
    }
    set(retryDataAtom, null);
    return;
  }
  verifyAccountAPI({
    localeInfo: {
      countryCode: null,
      languageCode: getLanguageCode(),
    },
    phoneVerification: {
      verifyCode: get(smsLoginStep2VerifyCodeAtom).verifyCode,
      formattedPhoneNumber: get(smsLoginStep2VerifyCodeAtom)
        .formattedPhoneNumber,
    },
    platform: 'WEBCLIENT',
  })
    .then((response) => {
      set(smsLoginStepAtom, 4);
      set(socialTokenAtom, {
        id: response.data.result.phoneId,
        phoneToken: response.data.result.phoneToken,
      });
      set(smsLoginVerifyPhoneAtom, {
        ...get(smsLoginVerifyPhoneAtom),
        ...response.data.result,
        status: 'CHECKED',
      });
      if (response.data.result?.phoneId) {
        checkAccountExistAPI(response.data.result.phoneId)().then(
          (checkAccountResponse) => {
            set(isExistAccountAtom, checkAccountResponse.data.result);
          }
        );
      }
    })
    .catch(({ response }) => {
      set(smsLoginStep2VerifyCodeAtom, {
        ...get(smsLoginStep2VerifyCodeAtom),
        errorMessage:
          verifyErrorMessages[response?.data.error.code] ||
          'SIGNUP_PHONE_VERIFICATION_FAIL',
      });
      set(smsLoginVerifyPhoneAtom, {
        ...get(smsLoginVerifyPhoneAtom),
        status: 'ERROR',
      });
    });
});

export const verifyEmailAtom = atom(null, (get, set) => {
  verifyEmailAPI({
    email: get(smsLoginStep3EmailAtom).email,
    phoneId: get(smsLoginVerifyPhoneAtom).phoneId,
    phoneToken: get(smsLoginVerifyPhoneAtom).phoneToken,
    localeInfo: {
      countryCode: null,
      languageCode: getLanguageCode(),
    },
  })
    .then(() => {
      set(loginSMSAtom);
      set(featureSettingParamsFromOptionalConsentsAtom, RESET);
    })
    .catch(({ response }) => {
      set(smsLoginStep3EmailAtom, {
        ...get(smsLoginStep3EmailAtom),
        errorMessage: response.data.error?.data.alertBody || '',
      });
    });
});

export const loginModalHeaderAtom = atom<{
  component?: ReactElement;
  onBack?: () => void;
} | void>(undefined);

export const exitSMSLoginAtom = atom(null, (get, set) => {
  set(loginModalTypeAtom, LoginModalType.LOGIN);
  set(resetSMSLoginAtom);
});
