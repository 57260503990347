import { FC, useCallback } from 'react';

import styled from '@emotion/styled';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import ResizingText from 'src/components/ResizingText';
import useEvent from 'src/hooks/useEvent';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { showPaymentPopupAtom } from 'src/stores/shop/atoms';
import { ShopOrigin } from 'src/stores/shop/types';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalType } from 'src/types/Modal';
import { GemProduct } from 'src/types/Payments';
import { hexToRgb, numComma } from 'src/utils/common';
import { hoverAndActiveCss } from 'src/utils/styled';

const InfoContainer = styled.div`
  height: 103px;
  display: flex;
  padding: 12px 12px 16px 12px;
  flex-direction: column;
  border-radius: 0 0 16px 16px;
  justify-content: center;
`;

const GemAmount = styled(ResizingText)`
  margin-top: 8px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.color.gray900__dkGray970};
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
`;

const GemPriceContainer = styled.div`
  padding: 10px 14px;
  border-radius: 20px;
  font-size: 13px;
  color: ${({ theme }) => theme.color.gray900__dkGray970};
  background-color: ${({ theme }) => theme.color.gray200__dkGray200};
  margin-left: auto;
  margin-right: auto;
  line-height: 16px;
`;

const GemLackCardContainer = styled.button`
  flex: 1;
  min-width: 0;
  height: 183px;
  box-shadow: 0px 2px 20px 0px
    rgba(${({ theme }) => hexToRgb(theme.color.black)}, 0.25);
  border-radius: 16px;
  background-color: ${({ theme }) => theme.color.gray100__dkGray150};
  ${({ theme }) =>
    hoverAndActiveCss(`
      ${InfoContainer} {
        background-color: ${theme.color.gray60__dkGray100};
        ${GemPriceContainer} {
          background-color: ${theme.color.gray100__dkGray150};
        }
      }
  `)}
  cursor: pointer;
  position: relative;
`;

const ImageContainer = styled.div<{ backgroundColor: string }>`
  height: 80px;
  overflow: hidden;
  position: relative;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 16px 16px 0 0;
  display: flex;
  justify-content: center;
`;

const GemImage = styled.img`
  object-fit: contain;
  position: relative;
`;

const GemVideoBackground = styled.video`
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 16px 16px 0 0;
`;

const SaveLabel = styled.div`
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 11px;
  background-color: ${({ theme }) => theme.color.red500};
  font-size: 12px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.white};
`;

interface GemLackCardProps {
  gemProduct?: GemProduct;
  isSecond?: boolean;
  savePercent?: number;
}

const GemLackCard: FC<GemLackCardProps> = ({
  gemProduct,
  isSecond,
  savePercent,
}) => {
  const { t } = useTranslation();
  const showPaymentPopup = useSetAtom(showPaymentPopupAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const pushEvent = useEvent();
  const onClick = useCallback(() => {
    if (!gemProduct) return;
    pushEvent({
      eventName: EVENT_NAME.GEM_LACK_POPUP__ITEM_CLICK,
      eventType: EVENT_TYPE.SHOP,
      eventParams: {
        isMoreExpensive: isSecond,
        productId: gemProduct.productId,
        productName: gemProduct.productName,
      },
    });
    showPaymentPopup({ gemProduct, origin: ShopOrigin.GEM_LACK });
    closeModal(ModalType.GEM_LACK);
  }, [closeModal, gemProduct, showPaymentPopup, isSecond, pushEvent]);
  const isShowSaveLabel = !!savePercent && savePercent >= 4;

  if (!gemProduct) return null;

  return (
    <GemLackCardContainer onClick={onClick}>
      <ImageContainer backgroundColor={gemProduct.backgroundColor}>
        {isSecond && (
          <GemVideoBackground autoPlay muted loop playsInline>
            <source
              src='/videos/shop/gem-lack-background.webp'
              type='video/webp'
            />
            <source
              src='/videos/shop/gem-lack-background.mp4'
              type='video/mp4'
            />
            {t('VIDEO_NOT_SUPPORTED')}
          </GemVideoBackground>
        )}
        <GemImage
          src={gemProduct.iconUrl}
          alt={gemProduct.productName + 'gems'}
        />
      </ImageContainer>
      {isShowSaveLabel && (
        <SaveLabel>
          {t('COMMON_SAVED_PERCENTAGE', { percent: savePercent })}
        </SaveLabel>
      )}
      <InfoContainer>
        <GemAmount minFontSize={13}>{gemProduct.productName}</GemAmount>
        <GemPriceContainer>
          <ResizingText minFontSize={10}>
            {getSymbolFromCurrency(gemProduct.currency)}
            {numComma(gemProduct.price)}
          </ResizingText>
        </GemPriceContainer>
      </InfoContainer>
    </GemLackCardContainer>
  );
};

export default GemLackCard;
