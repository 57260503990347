import { keyframes } from '@emotion/react';

//animation
export const fadeInTop = keyframes`
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
`;

export const rotation = keyframes`
  0% {
    transform: rotate(-360deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const fadeInBottom = keyframes`
  0% {
    transform: translateY(50px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const BottomToTop = keyframes`
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ToastBounce = keyframes`
  from {
    transform: translate(-50%, 0px);
  }
  to {
    transform: translate(-50%, 50px);
  }
`;

export const ToastFadeInOut = keyframes`
  0%{
    opacity: 0;
  }
  5%, 90%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

export const Rolling = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
`;

export const Dot = keyframes`
  0% {
    opacity: 1;
  }
  25%{
    opacity: 1;
  }
  50%{
    opacity: 0;
  }
  75%{
    opacity: 0;
  }
  100% {
    opacity: 1;
  }  
`;

export const CursorOpacity = keyframes`
0% {
  opacity: 1;
}
25%{
  opacity: 1;
}
75%{
  opacity: 0;
}
100% {
  opacity: 1;
}  
`;

/**
 * 애니메이션 체이닝으로 애니메이션 종료된 후 visibility를 숨기고 싶을 때 사용
 */
export const hide = keyframes`
  from {
    visibility: visible;
  }
  to {
    visibility: hidden;
  }
`;
