import QueryString from 'qs';

import { client } from 'src/utils/api';
import { getLoginParam } from 'src/utils/auth';

import { ShopEntryBanner } from './types';

export const getShopEntryBannerAPI = () => {
  const {
    localeInfo: {
      languageCode: localeLanguageCode,
      countryCode: localeCountryCode,
    },
    clientProperties: { platform },
  } = getLoginParam();
  const params = {
    localeLanguageCode,
    localeCountryCode,
    platform,
  };
  return client.get<ShopEntryBanner>(
    '/api/v2/banners/shop-entry' +
      QueryString.stringify(params, { addQueryPrefix: true })
  );
};
