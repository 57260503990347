import { FC, HtmlHTMLAttributes, useContext } from 'react';

import styled from '@emotion/styled';

import { ModalContentContext } from 'src/context/ModalContext/ModalContentContext';
import { hexToRgb } from 'src/utils/common';

import { ModalDim } from '.';

const ModalFooterLayout = styled.div<{ isWebview: boolean }>`
  padding: 16px 24px
    ${({ isWebview }) => (isWebview ? 'calc(16px + var(--sab))' : '24px')} 24px;
  max-width: 100%;
  position: relative;
  border-top: 1px solid transparent;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  display: flex;
  flex-direction: column;
`;

export const FooterDim = styled.div<{ isShow: boolean }>`
  ${({ isShow }) => ModalDim(isShow)}
  height: 40px;
  top: -40px;
  background: linear-gradient(
    180deg,
    rgba(${({ theme }) => hexToRgb(theme.color.gray900)}, 0) 0%,
    var(--background-color) 100%
  );
`;

const ModalFooter: FC<HtmlHTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => {
  const { isBottomReached } = useContext(ModalContentContext);

  return (
    <ModalFooterLayout {...props} isWebview={!!window.AzarJs}>
      {/* max-height 넘어가면 하단 딤노출 */}
      <FooterDim isShow={!isBottomReached} />
      {children}
    </ModalFooterLayout>
  );
};

export default ModalFooter;
