import { forwardRef } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Gap from 'src/components/Gap';
import ResizingText from 'src/components/ResizingText';
import { ToastProps } from 'src/types/Toast';
import { hexToRgb } from 'src/utils/common';

import { ToastRootStyle as RootStyleBase } from './style';

const RootStyle = styled(RootStyleBase)`
  --top: -34px;
  --gap: 8px;

  background-color: rgba(${({ theme }) => hexToRgb(theme.color.black)}, 0.9);
  width: calc(var(--100vw) - 16px);
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const IconBack = styled.div`
  width: 36px;
  height: 36px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Title = styled(ResizingText)`
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.color.white};
`;

const Message = styled(ResizingText)`
  color: ${({ theme }) => theme.color.white};
  font-size: 14px;
  text-align: start;
  flex: 1;
`;

interface Props {
  toast: ToastProps;
  index: number;
}

const WebviewToast = forwardRef<HTMLDivElement, Props>(
  ({ toast, index }, ref) => {
    const { message, iconUrl, title, duration, type } = toast;
    const { t } = useTranslation();

    return (
      <RootStyle
        key={toast.uuid}
        ref={ref}
        data-testid='toast'
        durationMs={duration}
        index={index}
      >
        <Inner>
          {iconUrl && (
            <>
              <IconBack>
                <Icon
                  src={iconUrl}
                  alt='toast-icon'
                  data-testid={`toast-icon-${type}`}
                />
              </IconBack>
              <Gap width={8} />
            </>
          )}
          <Right>
            {title && (
              <Title minFontSize={14} maxLines={1}>
                {t(title)}
              </Title>
            )}
            <Message minFontSize={14} maxLines={2}>
              {t(message)}
            </Message>
          </Right>
        </Inner>
      </RootStyle>
    );
  }
);

WebviewToast.displayName = 'WebviewToast';

export default WebviewToast;
export type { ToastProps };
