import React from 'react';

import useIsRtl from 'src/hooks/useIsRtl';

const RtlContainer = (props: React.HTMLAttributes<HTMLDivElement>) => {
  const isRtl = useIsRtl();
  return <div dir={isRtl ? 'rtl' : 'ltr'} {...props} />;
};

export default RtlContainer;
