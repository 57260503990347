export interface ShopEntryBanner {
  id: number;
  bannerText: string;
  exposureTimeSeconds: number;
  exposureRepeatCount: number;
  exposureLimitCount: number;
  iconUrl: string;
  backgroundColor: string;
  textColor: string;
  deeplink: string;
  excludeItemCategoryId?: string;
}
export const enum ShopOrigin {
  GEM_LACK = 'GEM_LACK',
  GEM_SHOP = 'GEM_SHOP',
  VIP_RATING = 'VIP_RATING',
}
