import axios, { AxiosError } from 'axios';

type ErrorMap = Record<string, string>;

export const SERVER_ERROR_MAP: ErrorMap = {
  NOT_AUTHORIZATION: 'COMMON__ERROR_HAS_OCCURRED',
  NOT_PERMISSION: 'ROOM__AUDIENCE__ERROR_CODE_LIVE_UNAUTHORIZED',
  NOT_FOUND: 'ROOM__HOST__ERROR_CODE_LIVE_NOT_FOUND_ROOM',

  INVALID_PARAMETER: 'HOME__ERROR_CODE_NETWORK_DISCONNECT',
  NOT_EXIST_LIVE: 'ROOM__HOST__ERROR_CODE_LIVE_NOT_FOUND_ROOM',
  NOT_FOUND_STREAM: 'ROOM__HOST__ERROR_CODE_LIVE_NOT_FOUND_ROOM',
  NOT_ALLOWED_ENTER_LIVE: 'ROOM__AUDIENCE__JOIN__ERROR_CODE_LIVE_REPORTED_USER',

  INTERNAL_ERROR: 'HOME__ERROR_CODE_NETWORK_DISCONNECT',
  UNAVAILABLE_SERVICE: 'HOME__ERROR_CODE_NETWORK_DISCONNECT',
  TOO_MANY_REQUEST: 'HOME__ERROR_CODE_NETWORK_DISCONNECT',
};

interface ErrorWithCode extends Error {
  code?: string;
}

export const parseError = (error: ErrorWithCode) =>
  SERVER_ERROR_MAP[error?.code || 'NOT_AUTHORIZATION'];

export const isAxiosError = <ResponseDataType>(
  error: unknown
): error is AxiosError<ResponseDataType> => axios.isAxiosError(error);

export function isError(value: unknown): value is Error {
  return value instanceof Error;
}
