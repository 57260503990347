import { FC, useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import 'react-mobile-datepicker-ts/dist/main.css';

import Button from 'src/components/Button';
import Input from 'src/components/Input';
import {
  ModalFooter,
  ModalTitle,
  ModalTitleDescription,
} from 'src/components/ModalLayout';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import useEvent from 'src/hooks/useEvent';
import {
  isExistAccountAtom,
  smsLoginStep3EmailAtom,
  smsLoginStepAtom,
  verifyEmailAtom,
} from 'src/stores/smsLogin/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { emailReg } from 'src/utils/common';

import { LoginModalHeader, Wrap } from './styles';

const EmailRow = styled.div`
  gap: 8px;
  width: 100%;
`;
const SmsLoginStep3: FC = () => {
  const { t } = useTranslation();
  const device = useCssMediaDevice();

  const pushEvent = useEvent();
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SCREEN_OPENED__EMAIL_INFO,
    });
  }, [pushEvent]);

  const verifyEmail = useSetAtom(verifyEmailAtom);
  const [email, setEmail] = useState('');
  const [step, setStep] = useAtom(smsLoginStepAtom);
  const [step3Input, setStep3Input] = useAtom(smsLoginStep3EmailAtom);
  const isAccountExist = useAtomValue(isExistAccountAtom);
  const handleVerifyEmail = () => {
    setStep3Input({ email, errorMessage: '' });
    if (isAccountExist) {
      verifyEmail();
    } else {
      setStep(step + 1);
    }
  };
  const handleInput = (text: string) => {
    setEmail(text);
    if (step3Input.errorMessage) {
      setStep3Input({ ...step3Input, errorMessage: '' });
    }
  };

  return (
    <>
      <Wrap>
        <LoginModalHeader>
          <ModalTitle>{t('SIGNUP_PHONE_EMAIL_TITLE')}</ModalTitle>
          <ModalTitleDescription>
            {t(
              isAccountExist
                ? 'SIGNUP_PHONE_EMAIL_DESC_AU'
                : 'SIGNUP_PHONE_EMAIL_DESC_RU'
            )}
          </ModalTitleDescription>
        </LoginModalHeader>
        <EmailRow>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (emailReg(email) && !step3Input.errorMessage) {
                handleVerifyEmail();
              }
            }}
          >
            <Input
              type='email'
              value={email}
              onChangeText={handleInput}
              placeholder={t('SIGNUP_PHONE_EMAIL_PLACEHOLDER')}
              errorMessage={step3Input.errorMessage}
              autoFocus={device !== 'mobile'}
            />
          </form>
        </EmailRow>
      </Wrap>
      <ModalFooter>
        <Button
          id='SIGNUP__NEXT__SMS_3'
          onClick={handleVerifyEmail}
          disabled={!emailReg(email) || !!step3Input.errorMessage}
        >
          {t('SIGNUP__NEXT')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default SmsLoginStep3;
