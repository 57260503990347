import { FC } from 'react';

import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';

import ModalLayoutBase, {
  MobileModalType,
  ModalHeader,
  ModalInner,
} from 'src/components/ModalLayout';
import { closeAllModalAtom } from 'src/stores/modal/atoms';
import { isRegisterLoadingAtom } from 'src/stores/register/atoms';
import {
  exitSMSLoginAtom,
  loginModalHeaderAtom,
  smsLoginBackStepAtom,
  smsLoginStepAtom,
} from 'src/stores/smsLogin/atoms';

import RegisterStep1 from './RegisterStep1';
import RegisterStep2 from './RegisterStep2';
import RegisterStep3 from './RegisterStep3';
import RegisterStep4 from './RegisterStep4';
import SmsLoginStep1 from './SmsLoginStep1';
import SmsLoginStep2 from './SmsLoginStep2';
import SmsLoginStep3 from './SmsLoginStep3';
import { ModalNavButtonType } from 'src/types/Modal';
import { useGetConsents } from 'src/hooks/useGetConsents';

const ModalLayout = styled(ModalLayoutBase)<{ minHeight?: string }>`
  width: 500px;

  ${({ minHeight }) =>
    minHeight
      ? `
      min-height: ${minHeight};
      height: auto;

      & > ${ModalInner} {
        min-height: inherit;
      }
  `
      : `
    height: 600px;
  `}
`;

const Steps = [
  RegisterStep1,
  SmsLoginStep1,
  SmsLoginStep2,
  SmsLoginStep3,
  RegisterStep2,
  RegisterStep3,
  RegisterStep4,
];

const SmsLogin: FC = () => {
  const step = useAtomValue(smsLoginStepAtom);
  const loginModalHeader = useAtomValue(loginModalHeaderAtom);
  const isRegisterLoading = useAtomValue(isRegisterLoadingAtom);
  const exitSMSLogin = useSetAtom(exitSMSLoginAtom);
  const { data: consentsData } = useGetConsents();
  const { uiType: consentsUiType } = consentsData?.data?.result || {};

  const Step = Steps[step - 1];

  const backStep = useSetAtom(smsLoginBackStepAtom);
  const closeModal = useSetAtom(closeAllModalAtom);
  const handleCloseModal = () => {
    exitSMSLogin();
    closeModal();
  };

  const onBack = () => {
    backStep();
    if (step === 2 && consentsUiType === 'CONSENT_WEB_B') {
      backStep();
    }
  };

  return (
    <ModalLayout
      onBack={onBack}
      navButtonType={
        step !== 7 ? ModalNavButtonType.BACK : ModalNavButtonType.NONE
      }
      isDim
      closeWhenClickOutside={step !== 7 && !isRegisterLoading}
      onClose={handleCloseModal}
      mobileType={MobileModalType.SCREEN_MODAL}
      minHeight={step === 1 || step === 7 ? '600px' : undefined}
    >
      {loginModalHeader?.component || (step !== 7 && <ModalHeader />)}
      <Step />
    </ModalLayout>
  );
};

export default SmsLogin;
