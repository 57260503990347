import { FC, useEffect } from 'react';

import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';

import ButtonBase from 'src/components/Button';
import ModalLayout, {
  MobileModalType,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderTitle,
} from 'src/components/ModalLayout';
import useEvent from 'src/hooks/useEvent';
import { acceptCmpConsentsAtom, cmpConsentsAtom } from 'src/stores/auth/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalType } from 'src/types/Modal';

const Wrap = styled.div`
  max-width: 400px;
  ${({ theme }) => theme.screenSize.mobile} {
    max-width: 100%;
  }
`;
const Button = styled(ButtonBase)`
  margin-bottom: 18px;
`;
const Desc = styled.div`
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;

  color: ${({ theme }) => theme.color.gray500__dkGray400};
  a {
    color: ${({ theme }) => theme.color.gray500__dkGray400};
    font-weight: 700;
  }
`;

const CmpConsentsModal: FC = () => {
  const consent = useAtomValue(cmpConsentsAtom);
  const acceptCmp = useSetAtom(acceptCmpConsentsAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const pushEvent = useEvent();

  useEffect(() => {
    if (consent) {
      pushEvent({
        eventName: EVENT_NAME.CONSENT_POPUP__IMPRESSION,
        eventType: EVENT_TYPE.SIGN_UP,
      });
    }
  }, [pushEvent, consent]);
  const handleAccept = () => {
    acceptCmp();
    closeModal(ModalType.CMP_CONSENTS);
  };

  return consent ? (
    <ModalLayout
      isDim
      closeWhenClickOutside={false}
      mobileType={MobileModalType.BOTTOM_SHEET}
      data-testid='Cmp-Consent-Modal'
    >
      <Wrap>
        <ModalHeader>
          <ModalHeaderTitle mobileType={MobileModalType.BOTTOM_SHEET}>
            {consent?.popup.title}
          </ModalHeaderTitle>
        </ModalHeader>
        <ModalContent>
          <Desc
            dangerouslySetInnerHTML={{
              __html: consent?.popup.description || '',
            }}
          />
        </ModalContent>
      </Wrap>
      <ModalFooter>
        <Button
          data-testid='Consent-button-agree'
          onClick={handleAccept}
          id='CONSENT_AGREE'
        >
          {consent?.popup.confirmButtonText}
        </Button>
      </ModalFooter>
    </ModalLayout>
  ) : null;
};

export default CmpConsentsModal;
