import { FC, useEffect, useState } from 'react';

import Counter from 'src/components/Counter';
import useBeforeunload from 'src/hooks/useBeforeunload';

const START = 102421;
let ws: WebSocket | void;

// 최근 10분 매치 수
const RecentMatchCounter: FC<{ className?: string }> = ({ className }) => {
  const [end, setEnd] = useState(START);
  useBeforeunload(() => ws?.close());

  useEffect(() => {
    if (!ws) {
      ws = new WebSocket(
        process.env.NEXT_PUBLIC_MATCH_COUNT_API_ORIGIN + '/10m' || ''
      );
    }
    const onMessage = (e: MessageEvent<number>) => {
      setEnd(e.data);
    };
    ws.addEventListener('message', onMessage);
    return () => {
      ws?.removeEventListener('message', onMessage);
    };
  }, []);

  return <Counter start={START} end={end} className={className} />;
};

export default RecentMatchCounter;
