import axiosOriginal, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from 'axios';
import QueryString from 'qs';
import axiosRetry from 'axios-retry';

import store from 'src/stores';
import {
  turnstileTokenAtom,
  turnstileWidgetSiteKeyAtom,
  userDataAtom,
} from 'src/stores/auth/atoms';
import { isAxiosError } from 'src/utils/error';
import { REST_API_HOST, RPC_API_HOST } from './endpoint';

export const getJsonRPCConfig = () => ({
  jsonrpc: '2.0',
  id: Math.ceil(Math.random() * 10000000),
});
const restApiConfig = {
  baseURL: REST_API_HOST,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    'X-Azar-API-Version': '9999',
  },
  timeout: 30000,
};
export const client = axiosOriginal.create(restApiConfig);

axiosRetry(client, {
  retries: 3, // 재시도 횟수
  retryDelay: (retryCount) => {
    return retryCount * 1000; // 재시도 간격 계산 (단위: 밀리초)
  },
  retryCondition: (error) => {
    // 재시도 조건을 정의 (HTTP 상태 코드 등)
    // CORS 오류는 통상적으로 status code가 나타나지 않기 때문에, error 요청 자체를 검토합니다.
    const ret =
      error.code === 'ECONNABORTED' ||
      error.message === 'Network Error' ||
      (!!error.response && error.response.status >= 500);
    if (ret) {
      console.error('server error', error, error.response);
    }
    return ret;
  },
});

export const clientWithoutAuth = axiosOriginal.create(restApiConfig);
client.interceptors.request.use((req) => {
  const userData = store.get(userDataAtom);
  const turnstileToken = store.get(turnstileTokenAtom);
  req.headers = {
    ...req.headers,
    ...(userData?.accessToken && {
      Authorization: `Bearer ${userData?.accessToken}`,
    }),
    ...(turnstileToken && { 'X-Azar-TR': turnstileToken }),
  };
  return req;
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!isAxiosError<ErrorResponse<ErrorAlertMessage>>(error))
      Promise.reject(error);
    if (error.response?.data?.error?.code === 'TURNSTILE_REQUIRED') {
      store.set(
        turnstileWidgetSiteKeyAtom,
        error.response.headers['x-azar-tk']
      );
    }
    return Promise.reject(error);
  }
);

export interface ErrorResponse<T> {
  error: {
    code: string;
    data?: T;
    message?: string;
  };
}

interface ApiResponse<T> {
  code: number;
  result: T;
}

// keepalive가 아닌 일반적인 호출
const createGeneralApiCall =
  <TParams = void, TResponse = void>(
    method: Method,
    url: string,
    instance: AxiosInstance = client
  ) =>
  (
    params: TParams | null = null
  ): Promise<AxiosResponse<ApiResponse<TResponse>>> => {
    const config: AxiosRequestConfig = {
      method,
      url,
    };

    // GET 요청인 경우 params(=URL), POST 요청인 경우 body에 파라미터 추가
    if (method.toLowerCase() === 'get') {
      config.params = params;
    } else {
      config.data = params;
    }
    return instance(config);
  };

export const customFetch: typeof fetch = async (...args) => {
  const [input, init] = args;
  const userData = store.get(userDataAtom);
  const turnstileToken = store.get(turnstileTokenAtom);

  const url = input.toString().startsWith('/') ? REST_API_HOST + input : input;
  const response = await fetch(url, {
    ...init,
    headers: {
      ...restApiConfig.headers,
      ...init?.headers,
      ...(userData?.accessToken && {
        Authorization: `Bearer ${userData?.accessToken}`,
      }),
      ...(turnstileToken && { 'X-Azar-TR': turnstileToken }),
    },
  });

  // cache hit 고려
  if (response.ok || response.status === 304) {
    return response;
  }

  try {
    const jsonResponse = await response.clone().json();
    const turnstileWidgetSiteKey = response.headers.get('x-azar-tk');
    if (
      jsonResponse.error?.code === 'TURNSTILE_REQUIRED' &&
      turnstileWidgetSiteKey
    ) {
      store.set(turnstileWidgetSiteKeyAtom, turnstileWidgetSiteKey);
    }
  } catch (_e) {
    // response가 json 변형이 안 되는 타입일 경우 고려
  }
  return response;
};

export const buildURLWithParameters = <TParams = void>(
  url: string,
  params: TParams
) => {
  if (!params) return url;

  return url + (url.includes('?') ? '&' : '?') + QueryString.stringify(params);
};

/**
 * 페이지 밖에서 생존 가능한 요청 날리기 위해 사용 (beforeunload event와 함께 사용 가능)
 * 주의) keepalive 옵션은 post와만 사용 가능
 */
const createKeepAliveAPICall =
  <TParams = void>(method: Method, url: string) =>
  (params: TParams) => {
    const urlWithParams =
      method.toLowerCase() === 'get'
        ? buildURLWithParameters(url, params)
        : url;

    // axios는 페이지 밖에서 생존가능한 요청 날리는 keepalive 옵션 미지원 -> fetch로 대체
    return customFetch(urlWithParams, {
      method,
      keepalive: true,
      ...(params ? { body: JSON.stringify(params) } : {}),
    });
  };

export function createApiCall<TParams = void, TResponse = void>(
  method: Method,
  url: string,
  instance: AxiosInstance = client
) {
  const apiCallFunc: {
    (
      params: TParams,
      keepAlive: boolean
    ): Promise<Response> | Promise<AxiosResponse<ApiResponse<TResponse>>>;
    (params: TParams, keepAlive: true): Promise<Response>;
    (
      params: TParams,
      keepAlive?: false
    ): Promise<AxiosResponse<ApiResponse<TResponse>>>;
  } = (params: TParams, keepAlive = false): any => {
    // 함수 오버로딩 위해서 any 리턴타입 사용
    if (keepAlive) {
      return createKeepAliveAPICall<TParams>(method, url)(params);
    } else {
      return createGeneralApiCall<TParams, TResponse>(
        method,
        url,
        instance
      )(params);
    }
  };

  return apiCallFunc;
}

export const setClientLocale = (locale?: string) => {
  client.locale = locale;
};

export const rpcAxios = axiosOriginal.create({
  baseURL: RPC_API_HOST,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    'X-Azar-API-Version': '9999',
  },
  timeout: 30000,
});

export const setDTID = (DTID?: string) => {
  if (DTID) {
    rpcAxios.defaults.headers.common = {
      ...rpcAxios.defaults.headers.common,
      'X-Azar-DTID': DTID,
    };
    client.defaults.headers.common = {
      ...client.defaults.headers.common,
      'X-Azar-DTID': DTID,
    };
  }
};

export interface Throwable {
  reason: string;
}

interface RPCError<E = Throwable> {
  code: number;
  data: {
    throwable: E;
    exceptionTypeName: string;
    message?: string;
  };
  message: string;
}
export interface ErrorAlertMessage {
  alertBody: string;
  alertTitle: string;
  alertType: string;
  reason: string;
  linkButtonTitle?: string;
  linkButtonUrl?: string;
}

export interface RPCResponse<T, E> {
  result: T;
  error?: RPCError<E>;
  jsonrpc: '2.0';
  id: number;
}
export const rpcClient =
  <
    Req = Record<string, unknown>,
    Res = Record<string, unknown>,
    E = Record<string, unknown>,
  >(
    method: string,
    option?: AxiosRequestConfig
  ) =>
  (params?: Req) =>
    rpcAxios.post<RPCResponse<Res, E>>(
      '/json',
      {
        ...getJsonRPCConfig(),
        method,
        //params가 배열이면 배열로 넘기고, 아니면 객체로 넘김
        params: Array.isArray(params)
          ? params
          : params
            ? [{ ...params }]
            : undefined,
      },
      option
    );
