import { useAtomValue, useSetAtom } from 'jotai';

import {
  cmpConsentsAtom,
  getCmpConsentsAtom,
  isGettingCmpConsentsAtom,
} from 'src/stores/auth/atoms';
import { OrderedModalHookType } from './types';
import { useCallback } from 'react';
import { showModalAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import CmpConsentsModal from 'src/components/CmpConsentsModal';

export const useCmpConsentsModal: OrderedModalHookType = () => {
  const getCmpConsents = useSetAtom(getCmpConsentsAtom);
  const cmpConsents = useAtomValue(cmpConsentsAtom);
  const isGettingCmpConsents = useAtomValue(isGettingCmpConsentsAtom);
  const showModal = useSetAtom(showModalAtom);

  const show = useCallback(async () => {
    if (isGettingCmpConsents) return;

    if (cmpConsents === undefined) {
      await getCmpConsents();
      return;
    }

    if (cmpConsents) {
      showModal({
        key: ModalType.CMP_CONSENTS,
        component: CmpConsentsModal,
      });
    }
  }, [cmpConsents, getCmpConsents, showModal]);

  return {
    isResolved: cmpConsents === null,
    show,
  };
};
