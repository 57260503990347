import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { setCookie, getCookie, deleteCookie, hasCookie } from 'cookies-next';
import { atom } from 'jotai';

enum StorageType {
  LOCAL_STORAGE = 'LOCAL_STORAGE',
  SESSION_STORAGE = 'SESSION_STORAGE',
}

function checkStorage(type: StorageType) {
  let isSupported = false;

  try {
    isSupported = !!(type === StorageType.LOCAL_STORAGE
      ? window.localStorage
      : window.sessionStorage);
  } catch (error) {
    //
  }

  return isSupported;
}
/**
 * getAtomWithStorage에 RESET 동작이 필요해질 경우, 아래 PR을 참고하여 구현한다.
 * @see https://git.dev.hpcnt.com/hyperconnect/azar-web-client/pull/1128
 */
export function getAtomWithStorage<T>(
  key: string,
  value: T,
  // 조건에 따라 파라미터의 optional 여부 결정하기 위해 rest parameter 사용
  ...rest: Exclude<T, Date> extends Date
    ? [JsonStorageOptionsWithReviver<T>]
    : [DefaultStorageOptions?]
) {
  const [storageOptions] = rest;
  if (checkStorage(StorageType.LOCAL_STORAGE)) {
    const storage = createJSONStorage<T>(
      () => window.localStorage,
      storageOptions
    );
    return atomWithStorage<T>(key, storage.getItem(key, value), storage);
  }
  return atom(value);
}

export function getAtomWithSessionStorage<T>(
  key: string,
  value: T,
  // 조건에 따라 파라미터의 optional 여부 결정하기 위해 rest parameter 사용
  ...rest: Exclude<T, Date> extends Date
    ? [JsonStorageOptionsWithReviver<T>]
    : [DefaultStorageOptions?]
) {
  const [storageOptions] = rest;
  if (checkStorage(StorageType.SESSION_STORAGE)) {
    const storage = createJSONStorage<T>(
      () => window.sessionStorage,
      storageOptions
    );
    // jotai v2로 바뀌면서 storage에 있는 값을 처음부터 꺼내도록 변경
    return atomWithStorage<T>(key, storage.getItem(key, value), storage);
  }
  return atom(value);
}

export function getAtomWithCookies<T>(
  key: string,
  initialValue: T, // 조건에 따라 파라미터의 optional 여부 결정하기 위해 rest parameter 사용
  ...rest: Exclude<T, Date> extends Date
    ? [JsonStorageOptionsWithReviver<T>]
    : [DefaultStorageOptions?]
) {
  const [storageOptions] = rest;

  const storage = createJSONStorage<T>(
    () => ({
      getItem: () => {
        if (hasCookie(key)) return getCookie(key) as string;
        return null; // will set atom with initial value
      },
      setItem: (ctx, value) =>
        setCookie(key, value, {
          maxAge: 2147483647, // set as permanent
        }),
      removeItem: () => deleteCookie(key),
    }),
    storageOptions
  );
  return atomWithStorage<T>(key, storage.getItem(key, initialValue), storage);
}

type DefaultStorageOptions = Parameters<typeof createJSONStorage>[1];
type JsonStorageOptions = Exclude<DefaultStorageOptions, undefined>;
// T = 저장하고자 하는 데이터의 타입
type JsonStorageOptionsWithReviver<T> = JsonStorageOptions & {
  reviver: (key: string, value: unknown) => T;
};

// Date 객체 사용을 위한 atom storage의 복원 함수
export const reviveDateFromStorage = (key: string, value: unknown) =>
  typeof value === 'string' || typeof value === 'number'
    ? new Date(value)
    : JSON.parse(value as any);
