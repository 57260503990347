/* eslint-disable max-classes-per-file */

export class ControlledPromise<T> {
  public readonly promise: Promise<T>;

  private _resolve?: (value: T | PromiseLike<T>) => void;

  private _reject?: (reason?: unknown) => void;

  private _ended = false;

  /**
   * resolve or reject 됐으면 true
   */
  get ended() {
    return this._ended;
  }

  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public resolve(value: T | PromiseLike<T>) {
    this._resolve?.(value);
    this._ended = true;
  }

  public reject(reason?: unknown) {
    this._reject?.(reason);
    this._ended = true;
  }
}

export class ControlledPromiseQueue {
  private items: {
    controlledPromise: ControlledPromise<void>;
    cb: () => Promise<void>;
  }[] = [];

  public push(cb: () => Promise<void>) {
    const promise =
      this.items[this.items.length - 1]?.controlledPromise.promise;
    const controlledPromise = new ControlledPromise<void>();
    const item = {
      controlledPromise,
      cb: async () => {
        await promise;
        await cb();
        this.pop();
      },
    };
    this.items.push(item);
    item.cb();
  }

  private pop() {
    const item = this.items.shift();
    item?.controlledPromise.resolve();
  }
}
