import { useCallback, useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { userDataAtom } from 'src/stores/auth/atoms';
import {
  getMyProfileAtom,
  lastGetProfileTimeAtom,
  profileAtom,
} from 'src/stores/profile/atoms';
import { differenceInMinutes } from 'date-fns';

const GET_PROFILE_MIN_INTERVAL_MIN = 5;

const useGetMyProfile = () => {
  const userData = useAtomValue(userDataAtom);
  const profile = useAtomValue(profileAtom);
  const lastGetProfileTime = useAtomValue(lastGetProfileTimeAtom);
  const getMyProfile = useSetAtom(getMyProfileAtom);

  const shouldGetMyProfile = useCallback(() => {
    if (!userData?.userId) return false;

    if (!profile || lastGetProfileTime === null) return true;

    const now = new Date();
    const minutesDiff = differenceInMinutes(now, lastGetProfileTime);

    // 어뷰징 방지로 가장 최근에 프로필 갱신한 시점에서 일정 시간 이상 경과했을 때만 갱신
    return minutesDiff >= GET_PROFILE_MIN_INTERVAL_MIN;
  }, [userData?.userId, profile, lastGetProfileTime]);

  useEffect(() => {
    if (shouldGetMyProfile()) {
      getMyProfile();
    }
  }, [getMyProfile, shouldGetMyProfile]);
};

export default useGetMyProfile;
