import { useEffect } from 'react';

import { useSetAtom } from 'jotai';

import useEvent from 'src/hooks/useEvent';
import { getVisitorConfigsAPI } from 'src/stores/visitor/apis';
import { visitorConfigAtom } from 'src/stores/visitor/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';

const useGetVisitorConfigs = () => {
  const setVisitorConfigs = useSetAtom(visitorConfigAtom);
  const pushEvent = useEvent();
  useEffect(() => {
    getVisitorConfigsAPI().then((res) => {
      const visitorConfigs = res.data.configs;
      setVisitorConfigs(visitorConfigs);
      pushEvent({
        eventType: EVENT_TYPE.VISITOR,
        eventName: EVENT_NAME.VISITOR_CONFIG,
        eventParams: visitorConfigs,
      });
    });
  }, [setVisitorConfigs, pushEvent]);
};

export default useGetVisitorConfigs;
