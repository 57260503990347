import { useMemo, useState } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-mobile-datepicker-ts';

import 'react-mobile-datepicker-ts/dist/main.css';
import { ModalFooter } from 'src/components/ModalLayout';
import { getDateConfigWithLocaleOrder } from 'src/types/DateSelector';
import { hexToRgb } from 'src/utils/common';
import { Wrap } from 'src/components/LoginModal/styles';
import { BirthdayInputProps } from './type';
import Button from 'src/components/Button';
import { calculateAge } from 'src/utils/date';
import { defaultBirth } from 'src/stores/register/atoms';

const InnerWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const TitleWrap = styled.div``;

const ModalTitle = styled.div`
  color: ${({ theme }) => theme.color.white};
  text-align: ${({ theme }) => (theme.isRtl ? 'right' : 'left')};
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  padding: 16px 0;
`;

const AgeDescription = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${({ theme }) => theme.color.gray800__dkGray900};
  text-align: ${({ theme }) => (theme.isRtl ? 'right' : 'left')};
`;

const ButtonTitle = styled.span<{ disabled: boolean }>`
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: ${({ theme, disabled }) =>
    disabled ? theme.color.gray400__dkGray300 : theme.color.white};
`;

const ButtonDescription = styled.span`
  color: ${({ theme }) => theme.color.white};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
`;

const MobileDatePickerWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 12px 0;
  width: 100%;
  height: 100%;

  .datepicker.ios {
    background-color: transparent;
    width: 100%;
    position: relative;
    height: 100%;
    max-height: 400px;

    .datepicker-content {
      border-top: 1px solid ${({ theme }) => theme.color.gray200__dkGray200};
      border-bottom: 1px solid ${({ theme }) => theme.color.gray200__dkGray200};
      padding-top: 2px;
      padding-bottom: 2px;
      width: 100%;
      height: 100%;
      max-height: 400px;
    }
    .datepicker-wheel {
      border-top: 1px solid
        rgba(${({ theme }) => hexToRgb(theme.color.gray200__dkGray200)}, 0.8);
      border-bottom: 1px solid
        rgba(${({ theme }) => hexToRgb(theme.color.gray200__dkGray200)}, 0.8);
    }
    .datepicker-viewport {
      height: 100%;
      max-height: 400px;
    }
    .datepicker-viewport::after {
      ${({
        theme: {
          color: { gray900 },
        },
      }) => `
          background: linear-gradient(${gray900}, rgb(${hexToRgb(
            gray900
          )}, 0) 52%, rgba(${hexToRgb(gray900)}, 0) 48%,${gray900});
        `}
    }
    .datepicker-scroll li {
      color: ${({ theme }) => theme.color.gray400__dkGray300};
    }

    /* 선택된 날짜 텍스트를 bold 처리
    datepicker 라이브러이에서 선택된 날짜에 대한 별도의 selector를 제공하지 않음. 따라서, 6번째 li를 선택된 것으로 취급함
    라이브러리 코드를 확인해 봤을 때, 6번째 index = 선택된 날짜 인것을 확인함
    @see https://github.com/lanjingling0510/react-mobile-datepicker/blob/279fb4b62c119cdadd8000b48e594ddd96e9ab46/lib/DatePickerItem.js#L12  */
    .datepicker-scroll li:nth-child(6) {
      color: ${({ theme }) => theme.color.gray900__dkGray970};
      font-weight: 700;
    }
  }
`;

export const ScrollBirthdayInput = ({ onSubmit }: BirthdayInputProps) => {
  const [birth, setBirth] = useState<Date>(defaultBirth);
  const { t } = useTranslation();
  const age = useMemo(() => {
    if (!birth) return null;
    return Math.max(calculateAge(birth), 0);
  }, [birth]);
  const dateConfig = useMemo(() => getDateConfigWithLocaleOrder(), []);
  const [isBirthChanged, setIsBirthChanged] = useState(false);

  const onChange = (date: Date) => {
    setBirth(date);
    setIsBirthChanged(true);
  };

  return (
    <>
      <Wrap>
        <InnerWrap>
          <TitleWrap>
            <ModalTitle>{t('signup_extra_info_birthday')}</ModalTitle>
            <AgeDescription>
              {t('signup_extra_info_birthday_warning_2')}
            </AgeDescription>
          </TitleWrap>
          <MobileDatePickerWrap>
            <DatePicker
              customHeader={<></>}
              dateConfig={dateConfig}
              onChange={onChange}
              showHeader={false}
              showFooter={false}
              value={birth}
              theme='ios'
              isPopup={false}
              isOpen
            />
          </MobileDatePickerWrap>
        </InnerWrap>
      </Wrap>
      <ModalFooter>
        <Button
          onClick={() => onSubmit(birth)}
          disabled={!isBirthChanged}
          id='SIGNUP__NEXT_REGISTER_STEP_2'
          data-testid='RegisterStep2-button-next'
        >
          {!isBirthChanged ? (
            <ButtonTitle disabled={true}>
              {t('signup_info_birth_title')}
            </ButtonTitle>
          ) : (
            <>
              <ButtonTitle disabled={false}>
                {t('signup_extra_info_birthday_age', { age })}
              </ButtonTitle>
              <ButtonDescription>
                {t('signup_extra_info_birthday_warning_3')}
              </ButtonDescription>
            </>
          )}
        </Button>
      </ModalFooter>
    </>
  );
};
