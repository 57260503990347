import { FC, useEffect } from 'react';

import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import InfoModal from 'src/components/InfoModal';
import useEvent from 'src/hooks/useEvent';
import { grantVideoAtom } from 'src/stores/match/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalType } from 'src/types/Modal';

const Label = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.color.gray900__dkGray970};
  padding-bottom: 16px;
`;

interface Props {
  label: string;
}

const PermissionErrorModal: FC<Props> = ({ label }) => {
  const { t } = useTranslation();
  const pushEvent = useEvent();
  const grantVideo = useSetAtom(grantVideoAtom);
  const closeModal = useSetAtom(closeModalAtom);

  const onClick = () => {
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME.OBS_REJECT_CLOSE,
    });
    grantVideo();
    closeModal(ModalType.ERROR);
  };

  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.VIDEO_CHAT,
      eventName: EVENT_NAME.OBS_REJECT_OPEN,
      eventParams: { program: label },
    });
  }, [pushEvent, label]);

  return (
    <InfoModal
      title={t('POPUP__VIRTUAL_CAMERA_TITLE')}
      closeWhenClickOutside={false}
      desc={t('POPUP__VIRTUAL_CAMERA_DESC')}
      Contents={<Label>{label}</Label>}
      buttonLabel={t('COMMON__CLOSE')}
      onClickSubmit={onClick}
    />
  );
};

export default PermissionErrorModal;
