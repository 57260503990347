import { VisitorConfigs } from 'src/types/Visitor';
import { clientWithoutAuth } from 'src/utils/api';
import { getLoginParam } from 'src/utils/auth';

export const getVisitorConfigsAPI = () => {
  const loginParam = getLoginParam();
  return clientWithoutAuth.post<VisitorConfigs>('/api/v1/visitors/configs', {
    deviceId: loginParam.deviceId,
    clientProperties: loginParam.clientProperties,
  });
};
