import { v4 } from 'uuid';

import store from 'src/stores';
import { deviceIdAtom } from 'src/stores/user/atom';

const getDeviceId = () => {
  const deviceId = store.get(deviceIdAtom);
  if (deviceId) {
    return deviceId;
  }
  const newUuid = v4();
  store.set(deviceIdAtom, newUuid);
  return newUuid;
};

export default getDeviceId;
