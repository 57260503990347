import { FC } from 'react';

import styled from '@emotion/styled';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useSetAtom } from 'jotai';

import Gap from 'src/components/Gap';
import LoadingIndicatorBase from 'src/components/LoadingIndicator';
import useEnableRemoteConfig from 'src/hooks/useEnableRemoteConfig';
import { showPaymentPopupAtom } from 'src/stores/shop/atoms';
import { ShopOrigin } from 'src/stores/shop/types';
import color from 'src/styles/color';
import { GemProduct } from 'src/types/Payments';
import { numComma } from 'src/utils/common';
import { getProductCalcGem, IProductCalcGem } from 'src/utils/payments';
import roundNumber from 'src/utils/roundNumber';
import { hoverAndActiveCss } from 'src/utils/styled';

const RootStyle = styled.div`
  width: 100%;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  background-color: ${({ theme }) => theme.color.gray200__dkGray200};
  border-radius: 20px;
  font-size: 13px;
  color: ${({ theme }) => theme.color.gray900__dkGray970};
  padding: 6px 12px 6px 12px;

  ${({ theme }) => theme.screenSize.mobile} {
    height: inherit;
  }
`;

const LowPriceWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gray200__dkGray200};
  border-radius: 12px;
  padding: 8px 16px;

  ${({ theme }) => theme.screenSize.mobile} {
    width: 100%;
    margin-top: 16px;
    margin-left: 0px;
  }
`;

const Box = styled.button`
  display: flex;
  width: 100%;
  background-color: ${({ theme }) => theme.color.gray100__dkGray150};
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  align-items: stretch;
  text-align: unset;
  min-height: 94px;

  & + & {
    margin-top: 10px;
  }
  ${({ theme }) => theme.screenSize.mobile} {
    min-height: 108px;
  }
  ${({ theme }) =>
    hoverAndActiveCss(`
      background-color: ${theme.color.gray200__dkGray200};
      ${Button} {
        background-color: ${theme.color.gray300__dkGray250};
      }
      ${LowPriceWrap} {
        background-color: ${theme.color.gray300__dkGray250};
      }
  `)}
`;

const ImageWrapper = styled.div<{ backgroundColor: string }>`
  display: flex;
  width: 117px;
  line-height: 100%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  justify-content: center;
  align-items: center;
`;

const ItemImage = styled.img`
  width: 100%;
  height: auto;
`;

const GemPrice = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.black__dkWhite};
  margin-bottom: 4px;
`;

const GemCountText = styled.p<{ colorCode: string }>`
  font-size: 14px;
  color: ${({ colorCode }) => colorCode};
`;

const BonusGemWrapper = styled.div`
  display: flex;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 16px;

  ${({ theme }) => theme.screenSize.mobile} {
    flex-direction: column;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const LoadingIndicator = styled(LoadingIndicatorBase)`
  width: 30px;
  height: 30px;
`;

const PriceText = styled.div`
  text-decoration: line-through;
  font-size: 12px;
  color: ${({ theme }) => theme.color.gray600__dkGray500};
`;

const LowPriceTextWrap = styled.div`
  display: flex;
  margin-top: 2px;
`;

const LowPricePercent = styled.span`
  color: ${({ theme }) => theme.color.red500};
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
`;

const LowPrice = styled.div`
  color: ${({ theme }) => theme.color.gray900__dkGray970};
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
`;

const GemPriceWrapper = styled.div`
  width: 100%;

  ${GemPrice} {
    margin-bottom: 0px;
    line-height: 22px;
  }
  ${BonusGemWrapper} {
    flex-direction: column;
    ${GemCountText} {
      font-weight: 400;
      margin-top: 2px;
    }
  }
`;

interface ItemListProps {
  gemProducts?: Array<GemProduct>;
}

interface IBonusGemComponent {
  gemCount: IProductCalcGem;
}

const BonusGemComponent: FC<IBonusGemComponent> = ({ gemCount }) => {
  const gemCountObject = [
    {
      key: 'bonus',
      count: gemCount.bonus,
      color: color.green500,
      text: gemCount.bonusText,
    },
    {
      key: 'promo',
      count: gemCount.promo,
      color: color.purple400,
      text: gemCount.promoText,
    },
  ];
  return (
    <BonusGemWrapper>
      {gemCountObject
        .filter((v) => !!v.count)
        .map((item) => (
          <GemCountText key={item.key} colorCode={item.color}>
            {`+ ${item.text} Gems`}
          </GemCountText>
        ))}
    </BonusGemWrapper>
  );
};

const ItemList: FC<ItemListProps> = ({ gemProducts }) => {
  const showPaymentPopup = useSetAtom(showPaymentPopupAtom);
  const enableWebLowPrice = useEnableRemoteConfig('enableWebLowPrice');

  if (!gemProducts) {
    return (
      <LoadingWrapper>
        <LoadingIndicator size={32} />
      </LoadingWrapper>
    );
  }
  const handleClick = (item: GemProduct) => {
    showPaymentPopup({ gemProduct: item, origin: ShopOrigin.GEM_SHOP });
  };

  return (
    <RootStyle>
      {gemProducts.map((item, i) => {
        const gemCount = getProductCalcGem(item);
        const symbolFromCurrency = getSymbolFromCurrency(item.currency);
        const numCommaText = numComma(item.price);

        return (
          <Box
            key={`Payments-ItemList-${item.productId}`}
            data-amount={gemCount.num}
            data-testid={`Payments-ItemList-${i}`}
            onClick={() => handleClick(item)}
          >
            <ImageWrapper backgroundColor={item.backgroundColor}>
              <ItemImage src={item.iconUrl} alt='item' />
            </ImageWrapper>
            <LabelWrapper>
              <GemPriceWrapper>
                <GemPrice>{`${gemCount.baseText} Gems`}</GemPrice>
                <BonusGemComponent gemCount={gemCount} />
              </GemPriceWrapper>
              <Gap width={8} />
              <LowPriceWrap>
                {enableWebLowPrice && (
                  <PriceText>
                    {symbolFromCurrency}
                    {numComma(
                      roundNumber(item.price * (1 / (1 - 0.1)), 1, 'ceil')
                    )}
                  </PriceText>
                )}
                <LowPriceTextWrap>
                  {enableWebLowPrice && (
                    <>
                      <LowPricePercent>10%</LowPricePercent>
                      <Gap width={5} />
                    </>
                  )}
                  <LowPrice>
                    {symbolFromCurrency}
                    {numCommaText}
                  </LowPrice>
                </LowPriceTextWrap>
              </LowPriceWrap>
            </LabelWrapper>
          </Box>
        );
      })}
    </RootStyle>
  );
};

export default ItemList;
export type { ItemListProps };
