import { useAtomValue } from 'jotai';

import { remoteConfigAtom } from 'src/stores/auth/atoms';
import { RemoteConfig } from 'src/types/AzarUser';
import { isObjectKeyTrue } from 'src/utils/common';

const useEnableRemoteConfig = (key: keyof RemoteConfig) => {
  const remoteConfig = useAtomValue(remoteConfigAtom);
  return remoteConfig?.[key] !== undefined
    ? isObjectKeyTrue(remoteConfig, key)
    : undefined;
};

export default useEnableRemoteConfig;
