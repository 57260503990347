import { MutableRefObject, useEffect } from 'react';

import { disableBodyScroll, enableBodyScroll } from 'src/utils/bodyScrollLock';
import { setVw } from 'src/utils/layout';

export default function useBodyScrollLock(
  ref: MutableRefObject<HTMLElement | null>,
  allowScroll = false
) {
  useEffect(() => {
    const target = ref.current;
    if (!target || allowScroll) return;
    disableBodyScroll(target, {
      allowTouchMove: () => allowScroll || !!window.AzarJs,
    });
    setVw(true);
    return () => {
      enableBodyScroll(target);
      setVw(false);
    };
  }, [allowScroll, ref]);
}
