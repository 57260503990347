import { hexToRgb } from 'src/utils/common';

import color from './color';

const scroll = `
  &::-webkit-scrollbar {
    width: 2px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  &:focus, &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: rgba(${hexToRgb(color.white)}, 0.2);
    }
  }
`;

export default scroll;
