import { FC } from 'react';

import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';

import ButtonBase from 'src/components/Button';
import {
  MobileModalType,
  ModalFooter,
  ModalContent,
  ModalHeader,
  ModalHeaderDescriptionRow,
  ModalHeaderTitle,
} from 'src/components/ModalLayout';
import ModalLayoutBase from 'src/components/ModalLayout';
import { userDataAtom } from 'src/stores/auth/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { ConsentInfo } from 'src/types/AzarUser';
import { MakeRequired } from 'src/types/utils';
import { useRestMutation } from 'src/hooks/useRestMutation';
import { ModalType } from 'src/types/Modal';
import { showToastAtom } from 'src/stores/toast/atoms';
import { ToastType } from 'src/types/Toast';
import { ConsentsMapRequest } from 'src/types/consent';
import { ConsentApiList } from 'src/stores/auth/apis';

const ModalLayout = styled(ModalLayoutBase)`
  max-width: 500px;
  ${({ theme }) => theme.screenSize.desktop} {
    max-width: 400px;
  }
`;

const Button = styled(ButtonBase)``;
const Disagree = styled(ButtonBase)`
  font-weight: 600;
  margin-bottom: 16px;
`;
const Desc = styled(ModalHeaderDescriptionRow)`
  color: ${({ theme }) => theme.color.gray700__dkGray700};
  a {
    color: ${({ theme }) => theme.color.gray700__dkGray700};
  }
`;

interface Props {
  consent: MakeRequired<ConsentInfo, 'consentRevisionPopup'>;
}

const TosReApprovalModal: FC<Props> = ({ consent }) => {
  const closeModal = useSetAtom(closeModalAtom);
  const setUserData = useSetAtom(userDataAtom);
  const showToast = useSetAtom(showToastAtom);
  const { mutate, isLoading } = useRestMutation<ConsentsMapRequest>({
    path: ConsentApiList.ReportConsent,
    method: 'post',
    options: {
      onSuccess: () => {
        closeModal(ModalType.TOS_RE_APPROVAL);
        setUserData((prev) => {
          if (!prev) return prev;

          return {
            ...prev,
            consentsRequired: prev.consentsRequired?.filter(
              (requiredConsent) =>
                requiredConsent.consentId !== consent.consentId
            ),
          };
        });
      },
      onError: () => {
        showToast({
          message: 'common__problem_occurred',
          type: ToastType.ERROR,
        });
      },
    },
  });

  const handleAccept = () => {
    mutate({
      consents: {
        [consent.type]: consent.consentId,
      },
    });
  };

  return (
    <ModalLayout
      isDim
      closeWhenClickOutside={false}
      mobileType={MobileModalType.BOTTOM_SHEET}
      data-testid='Consent-ReApproval-Modal'
    >
      <ModalHeader>
        <ModalHeaderTitle mobileType={MobileModalType.BOTTOM_SHEET}>
          {consent.consentRevisionPopup.title}
        </ModalHeaderTitle>
      </ModalHeader>
      <ModalContent>
        <Desc
          dangerouslySetInnerHTML={{
            __html: consent.consentRevisionPopup.content,
          }}
        />
      </ModalContent>
      <ModalFooter>
        <Disagree
          id='TOS_DISAGREE'
          variant='outlined'
          onClick={() => window.open(consent.url, '_blank')}
        >
          {consent.consentRevisionPopup.linkLabel}
        </Disagree>
        <Button
          data-testid='Consent-button-agree'
          onClick={handleAccept}
          id='TOS_AGREE'
          disabled={isLoading}
        >
          {consent.consentRevisionPopup.buttonLabel}
        </Button>
      </ModalFooter>
    </ModalLayout>
  );
};

export default TosReApprovalModal;
