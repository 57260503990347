import { useEffect } from 'react';

import { useSetAtom } from 'jotai';
import { useRouter } from 'next/router';

import { isPwaAtom } from 'src/stores/event/atoms';

const useIsPwa = () => {
  const router = useRouter();
  const setIsPwa = useSetAtom(isPwaAtom);

  useEffect(() => {
    if (!router.isReady) return;
    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsPwa(true);
    }
  }, [router.isReady, router.query.isPwa, setIsPwa]);
};

export default useIsPwa;
