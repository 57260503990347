const kCodecParamStartBitrate = 'x-google-start-bitrate';

/**
 * SDP 를 수정하여 Video Payload Type 에 대한 Start Bitrate 를 설정한다.
 * @param sdp SDP(Session Description Protocol) 문자열
 * @param startBitrate Start Bitrate 값. 단위는 kbps
 */
export function mungeSDPVideoStartBitrate(
  sdp: string,
  startBitrate: number
): string {
  if (startBitrate < 0 || isNaN(startBitrate)) {
    throw new Error(`Invalid start bitrate: ${startBitrate}`);
  }
  // SDP에 소수점을 넣지 않기 위해 정수로 변환
  const startBitrateTruncated = Math.trunc(startBitrate);

  const sdpLines = sdp.split('\n').map((line) => {
    return line.trimEnd();
  });
  let mLineIndex = -1;

  // Find video m-line
  for (let i = 0; i < sdpLines.length; i++) {
    if (sdpLines[i].startsWith('m=video')) {
      mLineIndex = i;
      break;
    }
  }

  if (mLineIndex === -1) {
    throw new Error('No video m-line found in SDP');
  }

  const payloadTypeToCodecInfo = new Map<
    number,
    {
      codecName: string | null;
      rtpMapLineNo: number | null;
      fmtpLineNo: number | null;
    }
  >();

  for (let i = mLineIndex + 1; i < sdpLines.length; i++) {
    const line = sdpLines[i];
    if (line.startsWith('m=')) {
      break;
    }

    if (line.startsWith('a=fmtp')) {
      const fmtpLineNo = i;
      const payloadType = parseInt(line.split(' ')[0].substring(7));
      const codecInfo = payloadTypeToCodecInfo.get(payloadType);
      payloadTypeToCodecInfo.set(payloadType, {
        codecName: codecInfo?.codecName ?? null,
        rtpMapLineNo: codecInfo?.rtpMapLineNo ?? null,
        fmtpLineNo: fmtpLineNo,
      });
    } else if (line.startsWith('a=rtpmap')) {
      const rtpMapLineNo = i;
      const payloadType = parseInt(line.split(' ')[0].substring(9));
      const codecName = line.split(' ')[1].split('/')[0];
      const codecInfo = payloadTypeToCodecInfo.get(payloadType);
      payloadTypeToCodecInfo.set(payloadType, {
        codecName: codecName,
        rtpMapLineNo: rtpMapLineNo,
        fmtpLineNo: codecInfo?.fmtpLineNo ?? null,
      });
    }
  }

  for (const [payloadType, codecInfo] of payloadTypeToCodecInfo) {
    const codecName = codecInfo.codecName;
    const rtpMapLineNo = codecInfo.rtpMapLineNo;
    const fmtpLineNo = codecInfo.fmtpLineNo;
    if (codecName === null || rtpMapLineNo === null) {
      continue;
    }
    // rtx, red 등은 생략
    if (
      codecName == 'rtx' ||
      codecName == 'red' ||
      codecName == 'ulpfec' ||
      codecName.startsWith('flexfec')
    ) {
      continue;
    }

    if (fmtpLineNo == null) {
      // a=fmtp 라인이 없는 경우: 새로 만들어서 추가한다.
      sdpLines[rtpMapLineNo] =
        sdpLines[rtpMapLineNo] +
        '\r\n' +
        'a=fmtp:' +
        payloadType +
        ' ' +
        `${kCodecParamStartBitrate}=${startBitrateTruncated}`;
      continue;
    }

    const fmtpLine = sdpLines[fmtpLineNo];
    if (fmtpLine === undefined) {
      continue;
    }

    // parameter 가 있는지 확인한다.
    const parameters = fmtpLine.split(' ')[1].split(';');

    if (parameters.length == 0) {
      // 길이가 0인 경우가 있을 수 있는지 잘 모르겠지만, 일단 추가한다.
      sdpLines[fmtpLineNo] =
        'a=fmtp:' +
        payloadType +
        ' ' +
        `${kCodecParamStartBitrate}=${startBitrateTruncated}`;
    } else {
      sdpLines[fmtpLineNo] =
        'a=fmtp:' +
        payloadType +
        ' ' +
        parameters.join(';') +
        `;${kCodecParamStartBitrate}=${startBitrateTruncated}`;
    }
  }

  return sdpLines.join('\r\n');
}
