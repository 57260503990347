import { css } from '@emotion/react';

export const mouseHoverCss = (style: string) => css`
  @media (hover: hover) and (pointer: fine) {
    :hover,
    :active {
      ${style}
    }
  }
`;

export const touchActiveCss = (style: string) => css`
  @media (pointer: coarse) {
    :active {
      ${style}
    }
  }
`;

export const hoverAndActiveCss = (style: string) => css`
  ${mouseHoverCss(style)}
  ${touchActiveCss(style)}
`;
