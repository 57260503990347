import { FC, useCallback, useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { useTurnstile } from 'react-turnstile';

import Button from 'src/components/Button';
import { ErrorMessage } from 'src/components/Input';
import { LoginModalHeader, Wrap } from 'src/components/LoginModal/styles';
import { ModalFooter, ModalTitle } from 'src/components/ModalLayout';
import TurnstileWidget from 'src/components/Turnstile';
import { VerifyCodeInput, VerifyCodeRow } from 'src/components/VerifyCode';
import useEvent from 'src/hooks/useEvent';
import useInterval from 'src/hooks/useInterval';
import { turnstileWidgetSiteKeyAtom } from 'src/stores/auth/atoms';
import {
  sendVerifyCodeToUserAtom,
  smsLoginStep1PhoneNumberAtom,
  smsLoginStep2VerifyCodeAtom,
  smsLoginVerifyPhoneAtom,
  verifyAccountAtom,
} from 'src/stores/smsLogin/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import getDeviceInfo from 'src/utils/device/info';

const DescWrap = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  margin-bottom: 32px;
`;
const Desc = styled.p`
  font-size: 16px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.color.gray700__dkGray700};
`;
const ResendRow = styled.div`
  display: flex;
  align-items: flex-start;
`;
const ResendText = styled.button`
  background-color: transparent;
  text-decoration: underline;
  color: ${({ theme }) => theme.color.gray700__dkGray700};
  cursor: pointer;
  font-weight: 300;
  font-size: 14px;
  padding: 0;
`;
const WarningText = styled.p`
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 14px;
  word-break: keep-all;

  color: ${({ theme }) => theme.color.gray500__dkGray400};
  ${({ theme }) => theme.screenSize.mobile} {
    margin-bottom: 8px;
  }
`;
const InfoText = styled(WarningText)`
  font-size: 14px;
`;
const TimerText = styled(WarningText)`
  font-size: 14px;
  color: ${({ theme }) => theme.color.green500};
`;

const VERIFY_CODE_LENGTH = 6;

const SmsLoginStep2: FC = () => {
  const { t } = useTranslation();
  const pushEvent = useEvent();
  useEffect(() => {
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SCREEN_OPENED__PHONE_VERIFICATION,
    });
  }, [pushEvent]);

  const [number] = useAtom(smsLoginStep1PhoneNumberAtom);
  const [code, setCode] = useState('');
  const [resendTimer, setResendTimer] = useState<number>(30);
  const [verify, setVerify] = useAtom(smsLoginStep2VerifyCodeAtom);
  const verifyPhone = useAtomValue(smsLoginVerifyPhoneAtom);
  const checkVerifyCode = useSetAtom(verifyAccountAtom);
  const inputRef = useRef<HTMLInputElement>(null);
  const { isMobile } = getDeviceInfo();

  const handleVerifyCode = (verifyCode: string) => {
    if (isMobile) {
      inputRef.current?.blur();
    }
    setVerify({ ...verify, verifyCode });
    checkVerifyCode();
  };
  const [isFocus, setIsFocus] = useState(false);

  const isVerifyCodeFilledButInvalid = () =>
    !!(code.length >= VERIFY_CODE_LENGTH && verify.errorMessage);

  const handleChangeVerifyCode = (text: string) => {
    const numReg = /^$|^[0-9]+$/;
    if (!numReg.test(text)) return;

    // 실패된 인증코드 상태에서 추가 입력시 초기화
    if (isVerifyCodeFilledButInvalid()) {
      text = text.slice(VERIFY_CODE_LENGTH);
    }

    if (text.length > VERIFY_CODE_LENGTH) return;

    setCode(text);

    if (verify.errorMessage) {
      setVerify({ ...verify, errorMessage: '' });
    }

    if (text.length === VERIFY_CODE_LENGTH) {
      handleVerifyCode(text);
    }
  };

  const handleFocusVerifyCodeInput = () => {
    if (isVerifyCodeFilledButInvalid()) {
      setCode('');
    }
  };

  const sendVerifyCodeToUser = useSetAtom(sendVerifyCodeToUserAtom);
  const handleResend = useCallback(() => {
    pushEvent({
      eventType: EVENT_TYPE.SIGN_UP,
      eventName: EVENT_NAME.SIGN_UP_CLICK_RESEND,
    });
    setResendTimer(32); //32초로 시작함. 2초는 아래 SIGNUP_PHONE_CODE_RESENT 메시지를 노출해야함
    sendVerifyCodeToUser(() => setCode(''));
  }, [pushEvent, sendVerifyCodeToUser]);

  useInterval(() => {
    if (resendTimer) setResendTimer(resendTimer - 1);
  }, 1000);

  const SendMessage = useCallback(() => {
    switch (verify.status) {
      case 'SENDING':
        return <InfoText> {t('SIGNUP_PHONE_CODE_REQUEST')}</InfoText>;
      case 'SENDED':
        if (resendTimer > 30) {
          return <InfoText> {t('SIGNUP_PHONE_CODE_RESENT')}</InfoText>;
        } else {
          return (
            <TimerText>00:{String(resendTimer).padStart(2, '0')}</TimerText>
          );
        }
      default:
        return <InfoText> {t('SIGNUP_PHONE_CODE_RESENT')}</InfoText>;
    }
  }, [verify.status, resendTimer, t]);
  const turnstileWidgetSiteKey = useAtomValue(turnstileWidgetSiteKeyAtom);

  const InfoMessage = useCallback(() => {
    if (verify.errorMessage) {
      return (
        <>
          <ErrorMessage message={verify.errorMessage} />
          <ResendRow>
            <ResendText onClick={handleResend}>
              {t('SIGNUP_PHONE_CODE_RETRY')}
            </ResendText>
          </ResendRow>
        </>
      );
    }
    if (resendTimer) return <SendMessage />;
    return (
      <>
        <WarningText>{t('SIGNUP_PHONE_NUMBER_SPAM')}</WarningText>
        <ResendRow>
          <ResendText onClick={handleResend}>
            {t('SIGNUP_PHONE_CODE_RETRY')}
          </ResendText>
        </ResendRow>
      </>
    );
  }, [verify.errorMessage, handleResend, resendTimer, SendMessage, t]);

  const turnstile = useTurnstile();
  return (
    <>
      <Wrap>
        <LoginModalHeader>
          <ModalTitle>{t('SIGNUP_PHONE_VERIFICATION_TITLE')}</ModalTitle>
        </LoginModalHeader>
        <DescWrap>
          <Desc>
            +{number.verifyPreparation.countryPrefixNumber} {number.phone}
          </Desc>
          <Desc>{t('SIGNUP_PHONE_VERIFY_TITLE')}</Desc>
        </DescWrap>
        {turnstileWidgetSiteKey ? (
          <TurnstileWidget
            callback={() =>
              sendVerifyCodeToUser(() => {
                turnstile.reset();
              })
            }
          />
        ) : (
          <>
            <VerifyCodeRow inputRef={inputRef}>
              <VerifyCodeInput
                isError={!!verify.errorMessage}
                code={code}
                onChange={handleChangeVerifyCode}
                onFocus={handleFocusVerifyCodeInput}
                setIsFocus={setIsFocus}
                isFocus={isFocus}
                numOfArray={VERIFY_CODE_LENGTH}
                inputRef={inputRef}
                isEditable={verifyPhone.status !== 'CHECKING'}
              />
            </VerifyCodeRow>
            <InfoMessage />
          </>
        )}
      </Wrap>
      <ModalFooter>
        <Button
          id='SIGNUP__NEXT__SMS_2'
          onClick={() => handleVerifyCode(code)}
          disabled={
            !(code.length === VERIFY_CODE_LENGTH) || !!verify.errorMessage
          }
          loading={
            verify.status === 'SENDING' || verifyPhone.status === 'CHECKING'
          }
        >
          {t('SIGNUP__NEXT')}
        </Button>
      </ModalFooter>
    </>
  );
};

export default SmsLoginStep2;
