/**
 * 주어진 html 문자열 내 포함된 모든 a 태그에 target=_blank 설정하여 리턴
 * 원본은 변환되지 않음
 */
export const setTargetBlankToLinks = (htmlString: string) => {
  if (typeof document === 'undefined') {
    return htmlString;
  }

  const div = document.createElement('div');
  div.innerHTML = htmlString;

  const links = div.getElementsByTagName('a');
  for (const link of links) {
    link.setAttribute('target', '_blank');
  }

  return div.innerHTML;
};
