import { useAtomValue } from 'jotai';

import { userInventoryAtom } from 'src/stores/auth/atoms';
import { getUserGemsAmount } from 'src/utils/user';

const useGemAmount = () => {
  const inventoryItems = useAtomValue(userInventoryAtom);

  return getUserGemsAmount(inventoryItems);
};

export default useGemAmount;
