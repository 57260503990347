import { atom } from 'jotai';

import { eventMutateAtom } from 'src/stores/event/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalType } from 'src/types/Modal';

const initialState: {
  key: ModalType;
  component: React.FC | true;
}[] = [];

interface InlineModal {
  key: ModalType;
  isInline: true;
}
interface PortalModal {
  key: ModalType;
  isInline?: false;
  component: React.FC;
}
export type ModalProps = InlineModal | PortalModal;
export const openedModalsAtom = atom<
  {
    key: ModalType;
    component: React.FC | true;
  }[]
>(initialState);

export const showModalAtom = atom(null, (get, set, params: ModalProps) => {
  const { key, isInline } = params;
  set(eventMutateAtom, {
    eventName: EVENT_NAME.POPUP_OPEN,
    eventType: EVENT_TYPE.WEB,
    eventParams: { name: key },
  });

  set(openedModalsAtom, [
    ...get(openedModalsAtom),
    {
      key,
      component: isInline ? true : params?.component,
    },
  ]);
});

export const closeModalAtom = atom(null, (get, set, key: ModalType) => {
  set(
    openedModalsAtom,
    [...get(openedModalsAtom)].filter((modal) => modal.key !== key)
  );
});

export const closeAllModalAtom = atom(null, (get, set) => {
  set(openedModalsAtom, initialState);
});
