function getDefaultApiURL() {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'Prod') {
    return 'https://api.azarlive.com';
  }

  if (
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'Qa' ||
    process.env.NEXT_PUBLIC_USE_QA_SERVER_IN_DEV === 'true'
  ) {
    return 'https://api.dev.an1.azarlive.hpcnt.com';
  }

  return `https://${process.env.NEXT_PUBLIC_NAMESPACE}-api.kube-dev-an1.azarlive.com`;
}

// Spinnaker 값을 먼저 사용하고, 없으면 환경별 기본값 사용
export const REST_API_HOST =
  process.env.NEXT_PUBLIC_REST_API_ORIGIN || getDefaultApiURL();
export const RPC_API_HOST =
  process.env.NEXT_PUBLIC_RPC_API_ORIGIN || getDefaultApiURL();
