import { atom } from 'jotai';

import { azarTokenAtom } from 'src/stores/auth/atoms';
import { matchInfoAtom } from 'src/stores/match/atoms';
import getDeviceId from 'src/utils/device/id';

import { heartbeatAPI, heartbeatVisitorAPI } from './apis';

export const heartbeatAtom = atom(null, (get) => {
  const uuid = getDeviceId();
  const azarToken = get(azarTokenAtom);
  const matchInfo = get(matchInfoAtom);

  if (azarToken?.accessToken) {
    heartbeatAPI(matchInfo ? { matchId: matchInfo?.matchId } : {});
    return;
  }
  if (uuid) {
    heartbeatVisitorAPI(uuid);
  }
});
