import { FC } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Button from 'src/components/Button';
import ExternalLink from 'src/components/ExternalLink';
import { ModalFooter } from 'src/components/ModalLayout';
import {
  ErrorCode,
  Wrapper as WrapperBase,
} from 'src/components/Payments/Failure/style';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled(WrapperBase)`
  padding: 60px 0;
  flex-grow: 1;
`;

const Link = styled(ExternalLink)`
  text-decoration: none;
`;

const EmptyGemProductsImage = styled.img``;

const EmptyProducts: FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <EmptyGemProductsImage
          src='/images/shop/emptyGemProducts.svg'
          alt='empty-gem-products'
        />
        <ErrorCode>{t('shop_empty_package')}</ErrorCode>
      </Wrapper>
      <ModalFooter>
        <Link href='https://t64z.adj.st/discover?adj_t=t26roxr&adj_deep_link=azarlive%3A%2F%2Fdiscover&adj_campaign=empty_shop'>
          <Button id='gem-products-empty'>{t('buy_in_app')}</Button>
        </Link>
      </ModalFooter>
    </Container>
  );
};

export default EmptyProducts;
