export enum ReportType {
  VISUAL_ABUSE = 'VISUAL_ABUSE',
  VERBAL_ABUSE = 'VERBAL_ABUSE',
  CHILD_AGE_ABUSE = 'CHILD_AGE_ABUSE',
  VIDEO_PROFILE_ABUSE = 'VIDEO_PROFILE_ABUSE',
  FAKE_GENDER_ABUSE = 'FAKE_GENDER_ABUSE',
  DSA_ILLEGAL_CONTENT = 'DSA_ILLEGAL_CONTENT',
}

export enum DSAContentType {
  PROFILE_IMAGE = 'PROFILE_IMAGE',
  NICKNAME = 'NICKNAME',
  BIO = 'BIO',
}

export interface DSAContext {
  userName?: string;
  userEmail?: string;
  contentType: Omit<DSAContentType, 'BIO'>;
  contentLocation: string;
  reason: string;
  profileImageId?: string;
}

export interface ReportProfileCardRequest {
  cardType: ReportProfileCardType;
  cardId: string;
  reportType: string;
  dsaContext?: Partial<DSAContext>;
}

/**
 * 
 * 웹에서는 아래 type들은 사용하지 않음
  // MY_PROFILE = 'MY_PROFILE',
  FRIEND = 'FRIEND',
  OFFICIAL_ACCOUNT = 'OFFICIAL_ACCOUNT',
  // PEER_ASYNC = 'PEER_ASYNC',
  // MY_ASYNC = 'MY_ASYNC',
  LOUNGE_RECOMMEND = 'LOUNGE_RECOMMEND',
  LOUNGE_LIKE = 'LOUNGE_LIKE',
  TODAY_LOUNGE_RECOMMEND = 'TODAY_LOUNGE_RECOMMEND',
  HISTORY = 'HISTORY',
  AZAR_PICK = 'AZAR_PICK',
  PROFILE_VIEWER = 'PROFILE_VIEWER',
 */
export enum ReportProfileCardType {
  MATCH = 'MATCH',
  HISTORY_CARD = 'HISTORY_CARD',
}

export interface ReportEventParam {
  action_category: string;
  tab: string;
  page: string;
  target: string;
  target_id: string;
  target_detail: string | undefined;
}
