import { getLoginParam } from 'src/utils/auth';
import { useRestQuery } from 'src/hooks/useRestQuery';
import {
  GetConsentsRequest,
  GetConsentsResponse,
} from 'src/stores/register/types';
import { RegisterApiList } from 'src/stores/register/apis';
import getDeviceId from 'src/utils/device/id';

export const useGetConsents = () => {
  const loginParam =
    typeof window !== 'undefined' && typeof window.navigator !== 'undefined'
      ? getLoginParam()
      : undefined;

  return useRestQuery<GetConsentsRequest, GetConsentsResponse, void>({
    path: RegisterApiList.GetConsents,
    params: {
      localeLanguageCode: loginParam?.localeInfo.languageCode,
      platform: 'WEBCLIENT',
      deviceId: getDeviceId(),
      flavor: loginParam?.clientProperties.flavor,
    },
    withoutAuth: true,
    options: {
      staleTime: Infinity,
      cacheTime: Infinity,
      // client side가 아니어서, language 정보가 없으면, query 실행 X
      enabled: !!loginParam,
    },
  });
};
