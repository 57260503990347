export const enum MLModelType {
  Antman = 'Antman',
}

export const enum MLFeatureType {
  MatchBlur = 'MatchBlur',
}

export interface MLModelConfig {
  modelId: string;
  type: MLModelType;
  intervalMs: number;
  maxLatencyLimit: number;
  maxRun?: number;
}

export interface MLFeatureCondition {
  modelType: MLModelType;
  modelIndex: number;
  criteriaWindow: number;
  criteriaThreshold: number;
  threshold: number;
  maxInferencesLimit: number;
}

export interface MLFeatureConfig {
  featureType: MLFeatureType;
  featureCondition: MLFeatureCondition[];
}

interface BaseMLConfig {
  modelId: string;
  modelConfig: MLModelConfig;
}

export interface AntmanConfig extends BaseMLConfig {
  blurConfig: MLFeatureCondition;
}

// ML inference logging
export interface MLInference {
  modelStateInitialized: boolean;
  inferenceAt: number;
  latencyMs: number;
  inference: string[];
}

// 피쳐 threshold를 넘긴 시점에 대한 기록
export interface MLThresholdInference {
  model: string;
  taskIndex: number;
  index: number;
}

// 피쳐가 trigger된 시점에 대한 기록
export interface MLFeatureTriggerInference {
  model: string;
  taskIndex: number;
  index: number;
  criteriaWindow: number;
  triggeredCount: number;
}
