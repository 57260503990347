import { FC, ReactNode, RefObject } from 'react';

import styled from '@emotion/styled';

import Gap from 'src/components/Gap';
import useCssMediaDevice from 'src/hooks/useCssMediaDevice';
import { CursorOpacity } from 'src/styles/animation';

const VerifyCodeRowStyle = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const CodeInputStyle = styled.input`
  opacity: 0;
  position: absolute;
  height: 0px;
  caret-color: transparent;
`;

const VerityCodeInputStyle = styled.div<{
  filled: boolean;
  isFocus: boolean;
  isError: boolean;
}>`
  flex-basis: 48px;
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ theme, filled }) =>
    filled ? theme.color.gray100__dkGray150 : 'transparent'};
  outline: none;
  border: 1px solid
    ${({ theme, isFocus, isError }) => {
      if (isError) {
        return theme.color.red500;
      }
      if (isFocus) {
        return theme.color.green500;
      }
      return theme.color.gray500__dkGray400;
    }};
  text-align: center;
  font-size: 28px;
  color: ${({ theme }) => theme.color.green500};

  border-radius: 4px;
`;

const Cursor = styled.div`
  position: absolute;
  width: 2px;
  height: 32px;
  ${({ theme }) => (theme.isRtl ? 'right' : 'left')}: 8px;
  top: 8px;

  background: ${({ theme }) => theme.color.white};
  border-radius: 8px;
  animation: ${CursorOpacity} 1s ease-in-out infinite;
`;

interface IVerifyCodeRowProps {
  children: ReactNode;
  inputRef: RefObject<HTMLInputElement>;
}

export const VerifyCodeRow: FC<IVerifyCodeRowProps> = ({
  children,
  inputRef,
}) => (
  <VerifyCodeRowStyle
    onClick={() => inputRef.current?.focus()}
    role='button'
    tabIndex={0}
  >
    {children}
  </VerifyCodeRowStyle>
);

interface IVerifyCodeInputProps {
  code: string;
  onChange: (value: string) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  setIsFocus: (bool: boolean) => void;
  inputRef: RefObject<HTMLInputElement>;
  isFocus: boolean;
  numOfArray: number;
  style?: React.CSSProperties;
  isError: boolean;
  isEditable?: boolean;
  className?: HTMLDivElement['className'];
}

export const VerifyCodeInput: FC<IVerifyCodeInputProps> = ({
  inputRef,
  code,
  onChange,
  onFocus,
  setIsFocus,
  isFocus,
  numOfArray,
  style,
  isError,
  isEditable = true,
  className,
}) => {
  const device = useCssMediaDevice();
  return (
    <>
      {
        // eslint-disable-next-line jsx-a11y/no-autofocus, styled-components-a11y/no-autofocus
        <CodeInputStyle
          value={code}
          onChange={(e) => onChange(e.target.value)}
          autoFocus={device !== 'mobile'}
          onFocus={(e) => {
            setIsFocus(true);
            onFocus?.(e);
          }}
          onBlur={() => setIsFocus(false)}
          ref={inputRef}
          type='number'
          pattern='\d*'
          maxLength={numOfArray}
          // disabled 설정시 focus가 풀려버리므로 readOnly 속성으로 수정만 방지
          readOnly={!isEditable}
        />
      }
      {Array.from(Array(numOfArray)).map((index, i) => (
        <>
          <VerityCodeInputStyle
            style={style}
            key={`code-${i}`}
            filled={!!code[i] && isFocus}
            isFocus={isFocus && i === code.length}
            isError={isError}
            className={className}
          >
            {isFocus && i === code.length && <Cursor />}
            {code[i]}
          </VerityCodeInputStyle>
          {i < numOfArray - 1 && <Gap width={device === 'mobile' ? 8 : 12} />}
        </>
      ))}
    </>
  );
};
