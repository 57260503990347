import { useEffect } from 'react';

import { useAtom, useSetAtom } from 'jotai';

import {
  getVerifyPreparationAtom,
  verifyPreparationAtom,
} from 'src/stores/smsLogin/atoms';

export default function useVerifyPreparation() {
  const [list] = useAtom(verifyPreparationAtom);
  const getList = useSetAtom(getVerifyPreparationAtom);
  useEffect(() => {
    getList();
  }, [getList]);
  const selectorList = list.map((country) => ({
    value: country.countryName || '',
    label: `+${country.countryPrefixNumber} (${country.countryName})`,
  }));

  return { options: selectorList, prefixList: list };
}
