export const screenWidth = {
  xs: 480,
  mobile: 768,
  tablet: 1280,
  desktop: 1281,
};

const screenSize = {
  mobile: `@media (max-width: ${screenWidth.mobile}px)`,
  tablet: `@media (max-width: ${screenWidth.tablet}px)`,
  desktop: `@media (min-width: ${screenWidth.desktop}px)`,
  breakpointUp: (size: number) => {
    return `@media (min-width: ${size}px)`;
  },
  breakpointDown: (size: number) => {
    return `@media (max-width: ${size - 1}px)`;
  },
};

export default screenSize;
