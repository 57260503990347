import { FC, useContext, useEffect } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useGoogleLogin } from '@react-oauth/google';
import { useAtomValue, useSetAtom } from 'jotai';
import jwtDecode from 'jwt-decode';
import dynamic from 'next/dynamic';
import AppleLogin, {
  AppleJWT,
  AppleLoginError,
  AppleLoginResponse,
} from 'react-apple-login';
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useTranslation } from 'react-i18next';

import BrandLoginButton, {
  LoginBrandName,
} from 'src/components/BrandLoginButton';
import RecentMatchCounter from 'src/components/Counter/RecentMatchCounter';
import Gap from 'src/components/Gap';
import TextLogoBase from 'src/components/Logo/TextLogo';
import ModalLayoutBase, {
  ModalHeader,
  MobileModalType,
  ModalLoading,
} from 'src/components/ModalLayout';
import useEvent from 'src/hooks/useEvent';
import useHelpCenterUrl from 'src/hooks/useHelpCenterUrl';
import useLogin from 'src/hooks/useLogin';
import {
  // GetFacebookUserInfoAPI,
  GetGoogleUserInfoAPI,
} from 'src/stores/auth/apis';
import {
  isLoginLoadingAtom,
  socialTokenAtom,
  tryLoginTypeAtom,
} from 'src/stores/auth/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { LoginModalType, loginModalTypeAtom } from 'src/stores/register/atoms';
import { showToastAtom } from 'src/stores/toast/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalType } from 'src/types/Modal';
import {
  appleAppId,
  appleRedirectUri,
  facebookAppId,
} from 'src/types/OAuthToken';
import { LoginType } from 'src/types/register';
import { ToastType } from 'src/types/Toast';
import { SSOSocialToken } from 'src/types/Token';
import { LanguageContext } from 'src/utils/language';
import { useGetConsents } from 'src/hooks/useGetConsents';

import { setTargetBlankToLinks } from 'src/utils/html';

const TextLogo = styled(TextLogoBase)`
  margin-bottom: 32px;
`;
const LoginModalCounterDesc = styled.p`
  font-size: 15px;
  color: ${({ theme }) => theme.color.gray900__dkGray970};
  margin-bottom: 40px;
  text-align: center;
  word-break: break-word;
`;
const Contact = styled.a`
  margin-top: 32px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.color.white};
  font-size: 14px;
`;
const TopContainer = styled.div`
  flex-direction: column;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  ${({ theme }) => theme.screenSize.mobile} {
    padding: 0 16px;
  }
`;
const Container = styled.div`
  flex-direction: column;
  display: flex;
  padding: 0 40px 40px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.screenSize.mobile} {
    width: 100%;
    height: calc(100% - 58px);
    padding: 0px 24px 16px 24px;
  }
`;
const LoginModalCounter = styled(RecentMatchCounter)`
  color: ${({ theme }) => theme.color.gray900__dkGray970};
  font-size: 40px;
  font-weight: 700;
  line-height: 56px;
`;

const DownloadContainer = styled.div`
  display: none;
  ${({ theme }) => theme.screenSize.mobile} {
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.color.gray500__dkGray400};
    font-size: 12px;
    font-weight: 700;
    width: 100%;
    align-items: center;
  }
`;

const DownloadButtonContainer = styled.div`
  margin-top: 12px;
  display: flex;
  height: 29px;
`;

const DownloadButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 91px;
  height: 100%;
  background-color: ${({ theme }) => theme.color.gray100__dkGray150};
  border-radius: 100px;
  :active {
    background-color: ${({ theme }) => theme.color.gray200__dkGray200};
  }
`;
const DownloadIcon = ({ theme }: { theme: Theme }) => css`
  fill: ${theme.color.gray900__dkGray970};
  width: 16px;
  height: 16px;
`;
const IcApple = styled(
  dynamic(() => import('src/assets/images/imgBrandApple.svg'))
)`
  ${DownloadIcon}
`;
const IcGooglePlay = styled(
  dynamic(() => import('src/assets/images/imgBrandGooglePlay.svg'))
)`
  ${DownloadIcon}
`;

const ModalLayout = styled(ModalLayoutBase)`
  width: 400px;
`;

const TermsOfUse = styled.span`
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  word-break: keep-all;
  color: ${({ theme }) => theme.color.gray500__dkGray400};
  text-align: center;
  margin-bottom: 24px;

  a {
    color: ${({ theme }) => theme.color.gray500__dkGray400};
    font-weight: 600;
  }
`;

interface Props {
  loginSuccessCallback?: () => void;
}

const Login: FC<Props> = ({ loginSuccessCallback }) => {
  const isLoginLoading = useAtomValue(isLoginLoadingAtom);
  const { loginOAuth, isLogin } = useLogin();
  const closeModal = useSetAtom(closeModalAtom);
  const setLoginModalType = useSetAtom(loginModalTypeAtom);
  useEffect(() => {
    if (isLogin) {
      if (loginSuccessCallback) {
        loginSuccessCallback();
      }
      closeModal(ModalType.LOGIN);
    }
  }, [isLogin, loginSuccessCallback, closeModal]);

  const setTryLoginType = useSetAtom(tryLoginTypeAtom);
  const setSocialToken = useSetAtom(socialTokenAtom);
  const showToast = useSetAtom(showToastAtom);
  const { t } = useTranslation();
  const { isLoading: isConsentsLoading, data: consentsData } = useGetConsents();
  const { consentsRequired, uiType: consentsUiType } =
    consentsData?.data?.result || {};
  const termsOfUse = consentsRequired?.find(
    ({ type }) => type === 'TERM_OF_USE'
  );

  const handleInfo = (loginType: LoginType, token: SSOSocialToken) => {
    setTryLoginType(loginType);
    setSocialToken(token);
    const { accessToken, id } = token;
    loginOAuth({ accessToken, id });
  };

  const handleFBCallback = async (userInfo: ReactFacebookLoginInfo) => {
    const { accessToken, userID: id, email = '' } = userInfo;
    /*
    const {
      data: {
        birthday,
        gender,
      },
    } = await GetFacebookUserInfoAPI(accessToken);
    */
    handleInfo(LoginType.FACEBOOK, {
      accessToken,
      id,
      email,
      // birth: birthday ? new Date(birthday) : undefined,
      // gender: gender?.toUpperCase(),
    });
  };

  const handleGoogleLogin = useGoogleLogin({
    // scope: 'https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/user.gender.read',
    scope: 'https://www.googleapis.com/auth/userinfo.profile',
    flow: 'auth-code',
    onSuccess: async ({ code }) => {
      const [
        {
          data: { accessToken },
        },
        /*
      {
        data: {
          genders: [{ value: gender }] = [{ value: undefined }],
          birthdays: [{ date: birthday }] = [{ date: undefined }],
        },
      }
      */
      ] = await GetGoogleUserInfoAPI(code);
      handleInfo(LoginType.GOOGLE, {
        accessToken,
        /*
        birth: (birthday?.year && birthday?.month >= 0 && birthday?.day) ? new Date(
          birthday.year,
          birthday.month - 1,
          birthday.day,
        ) : undefined,
        gender: gender?.toUpperCase(),
        */
      });
    },
  });
  const handleAppleCallback = (res: AppleLoginError | AppleLoginResponse) => {
    if ('error' in res) {
      if (res.error.error !== 'popup_closed_by_user') {
        showToast({ type: ToastType.ERROR, message: 'LOGIN__ERROR' });
      }
      return;
    }
    const idToken: AppleJWT = jwtDecode(res.authorization.id_token);
    handleInfo(LoginType.APPLE, {
      accessToken: res.authorization.id_token,
      id: idToken.sub,
      email: res.user?.email || idToken.email,
      appleAccountName: '',
    });
  };
  const pushEvent = useEvent();
  const helpCenterURL = useHelpCenterUrl();
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    if (language) {
      pushEvent({
        eventType: EVENT_TYPE.SIGN_UP,
        eventName: EVENT_NAME.SCREEN_OPEN__LOGIN,
        eventParams: { language },
      });
    }
  }, [pushEvent, language]);

  return (
    <ModalLayout
      isDim
      data-testid='login-modal'
      mobileType={MobileModalType.SCREEN_MODAL}
    >
      <ModalHeader />
      {(isLoginLoading || isConsentsLoading) && (
        <ModalLoading type='absolute' />
      )}
      <Container>
        <TopContainer>
          <TextLogo
            width={137}
            height={36}
            arWidth={74}
            arHeight={56}
            ptWidth={175}
            ptHeight={36}
          />
          <LoginModalCounter />
          <LoginModalCounterDesc>
            {t('online__matching_now')}
          </LoginModalCounterDesc>
          {consentsUiType === 'CONSENT_WEB_B' && termsOfUse && (
            <TermsOfUse
              dangerouslySetInnerHTML={{
                __html: setTargetBlankToLinks(
                  termsOfUse.agreementMessage + ' ' + termsOfUse.description
                ),
              }}
            />
          )}
          <BrandLoginButton
            data-testid='Login-button-google'
            onClick={handleGoogleLogin}
            brand={LoginBrandName.GOOGLE}
          />
          <FacebookLogin
            isMobile={false}
            appId={facebookAppId}
            callback={handleFBCallback}
            // scope='public_profile, email, user_birthday, user_gender'
            scope='public_profile, email'
            render={(renderProps) => (
              <BrandLoginButton
                onClick={renderProps.onClick}
                brand={LoginBrandName.FACEBOOK}
              />
            )}
          />
          <BrandLoginButton
            onClick={() => {
              setLoginModalType(LoginModalType.SMS_LOGIN);
              setTryLoginType(LoginType.SMS);
            }}
            brand={LoginBrandName.PHONE}
          />
          <AppleLogin
            clientId={appleAppId}
            redirectURI={appleRedirectUri}
            usePopup={true}
            render={(renderProps) => (
              <BrandLoginButton
                onClick={renderProps.onClick}
                brand={LoginBrandName.APPLE}
              />
            )}
            callback={handleAppleCallback}
            scope='email'
            responseMode='form_post'
          />
          <Contact href={helpCenterURL}>{t('LOGIN__CONTACT_US')}</Contact>
        </TopContainer>
        <DownloadContainer>
          {t('menu_download_app')}
          <DownloadButtonContainer>
            <DownloadButton
              href='https://t64z.adj.st/discover?adj_t=t26roxr&adj_deep_link=azarlive%3A%2F%2Fdiscover&adj_campaign=mobile_login_modal'
              target='_blank'
              rel='noopener noreferrer'
            >
              <IcApple />
            </DownloadButton>
            <Gap width={8} />
            <DownloadButton
              href='https://t64z.adj.st/discover?adj_t=t26roxr&adj_deep_link=azarlive%3A%2F%2Fdiscover&adj_campaign=mobile_login_modal'
              target='_blank'
              rel='noopener noreferrer'
            >
              <IcGooglePlay />
            </DownloadButton>
          </DownloadButtonContainer>
        </DownloadContainer>
      </Container>
    </ModalLayout>
  );
};

export default Login;
