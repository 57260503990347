import { useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import useInterval from 'src/hooks/useInterval';
import useLogin from 'src/hooks/useLogin';
import { remoteConfigAtom } from 'src/stores/auth/atoms';
import { heartbeatAtom } from 'src/stores/Heartbeat/atoms';

const POLLING_INTERVAL = 1000 * 60 * 3;

export default function useHeartbeat() {
  const remoteConfig = useAtomValue(remoteConfigAtom);

  const heartbeat = useSetAtom(heartbeatAtom);
  const { isLogin } = useLogin();

  useEffect(() => {
    if (isLogin) {
      heartbeat();
    }
    if (!isLogin) {
      const id = setTimeout(heartbeat, 3000);
      return () => clearTimeout(id);
    }
  }, [heartbeat, isLogin]);

  useInterval(heartbeat, remoteConfig?.heartbeatIntervalMS || POLLING_INTERVAL);
}
