import { FC, forwardRef } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Gap from 'src/components/Gap';
import { ToastType, ToastProps } from 'src/types/Toast';
import { hexToRgb } from 'src/utils/common';

import IcInfo from 'src/assets/images/icInfo.svg';
import IcError from 'src/assets/images/icError.svg';
import IcSuccess from 'src/assets/images/icSuccess.svg';

import { ToastRootStyle as RootStyleBase } from './style';

const RootStyle = styled(RootStyleBase)`
  --top: 30px;
  --gap: 16px;

  width: max-content;
  min-width: 320px;
  max-width: 640px;

  background-color: ${({ theme }) => theme.color.gray200__dkGray200};
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);

  ${({ theme }) => theme.screenSize.mobile} {
    --top: -34px;
    --gap: 8px;
    width: calc(var(--100vw) - 32px);
    min-width: auto;
    max-width: auto;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.25);
  }
`;

const Inner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) =>
    theme.isRtl ? '16px 16px 16px 24px' : '16px 24px 16px 16px'};
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => theme.screenSize.mobile} {
    padding: 12px 16px;
  }
`;

const IconBack = styled.div`
  border-radius: 43px;
  width: 32px;
  height: 32px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.screenSize.tablet} {
    width: 20px;
    height: 20px;
    padding: 2px;
  }
  background-color: rgba(${({ theme }) => hexToRgb(theme.color.black)}, 0.1);
  position: relative;
  width: 32px;
  height: 32px;
  ${({ theme }) => theme.screenSize.mobile} {
    width: 20px;
    height: 20px;
    padding: 2px;
  }
`;

const Blur = styled.div<{ type: ToastType }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 212px;
  height: 212px;
  ${({ type, theme }) => {
    const color = hexToRgb(
      {
        [ToastType.INFO]: theme.color.yellow500,
        [ToastType.WARNING]: theme.color.yellow500,
        [ToastType.SUCCESS]: theme.color.green500,
        [ToastType.ERROR]: theme.color.red500,
      }[type]
    );
    return `
      background: radial-gradient(50% 50.00% at 50% 50.00%, rgba(${color}, 0.12) 0%, rgba(${color}, 0.00) 100%);
    `;
  }}
`;

const Message = styled.p`
  color: ${({ theme }) => theme.color.black__dkWhite};
  font-weight: 700;
  line-height: 26px;
  font-size: 18px;
  flex: 1;

  ${({ theme }) => theme.screenSize.tablet} {
    font-size: 13px;
    line-height: 16px;
  }
`;

const TOAST_ICONS: {
  [key in ToastType]: FC<React.SVGProps<SVGSVGElement>>;
} = {
  [ToastType.INFO]: IcInfo,
  [ToastType.WARNING]: IcInfo,
  [ToastType.SUCCESS]: IcSuccess,
  [ToastType.ERROR]: IcError,
};

interface Props {
  toast: ToastProps;
  index: number;
}

const NonWebviewToast = forwardRef<HTMLDivElement, Props>(
  ({ toast, index }, ref) => {
    const { message, type = ToastType.INFO, duration } = toast;
    const Icon = TOAST_ICONS[type];

    const { t } = useTranslation();
    return (
      <RootStyle
        key={toast.uuid}
        ref={ref}
        data-testid='toast'
        durationMs={duration}
        index={index}
      >
        <Inner>
          <IconBack>
            <Blur type={type} />
            <Icon
              width={'100%'}
              height='100%'
              data-testid={`toast-icon-${type}`}
            />
          </IconBack>
          <Gap width={8} />
          <Message>{t(message)}</Message>
        </Inner>
      </RootStyle>
    );
  }
);

NonWebviewToast.displayName = 'NonWebviewToast';

export default NonWebviewToast;
