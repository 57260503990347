const color = {
  green50: '#E5FEF5',
  green100: '#B9FDE4',
  green200: '#89FCD0',
  green300: '#5EFCBD',
  green400: '#2EFCAC',
  green500: '#00F69D',
  green600: '#09D087',
  green700: '#10A971',
  green800: '#12835A',
  green850: '#175E45',
  green900: '#1E382E',
  yellow50: '#FEF9E8',
  yellow100: '#FDF3D2',
  yellow200: '#FBE8A5',
  yellow300: '#F9DC78',
  yellow400: '#F7D14A',
  yellow500: '#F6C61E',
  yellow600: '#E2932C',
  yellow700: '#A45E19',
  yellow800: '#5E3210',
  yellow850: '#44240E',
  yellow900: '#2B170C',
  pink50: '#FEEDF3',
  pink100: '#FDDCE8',
  pink200: '#FBBAD1',
  pink300: '#F998BB',
  pink400: '#F776A4',
  pink500: '#F5548E',
  pink600: '#C14672',
  pink700: '#8F3656',
  pink800: '#5D2639',
  pink850: '#431F2C',
  pink900: '#2B161E',
  red50: '#FDEBED',
  red100: '#FBD7DC',
  red200: '#F7B0B9',
  red300: '#F48996',
  red400: '#F06272',
  red500: '#ED3B50',
  red600: '#BA3242',
  red700: '#8A2733',
  red800: '#5A1B23',
  red850: '#42161C',
  red900: '#291114',
  orange50: '#FEF1EC',
  orange100: '#FDE3DA',
  orange200: '#FCC7B5',
  orange300: '#FAAC90',
  orange400: '#F9906A',
  orange500: '#F87546',
  orange600: '#C45E3A',
  orange700: '#90482E',
  orange800: '#5E3021',
  orange850: '#44261B',
  orange900: '#2B1A14',
  blue50: '#EBF2FD',
  blue100: '#D8E5FB',
  blue200: '#B2CCF7',
  blue300: '#8CB2F4',
  blue400: '#6699F0',
  blue500: '#4080ED',
  blue600: '#3566BB',
  blue700: '#294D8B',
  blue800: '#1E3459',
  blue850: '#182742',
  blue900: '#121B29',
  indigo50: '#EDEEFC',
  indigo100: '#DCDDFA',
  indigo200: '#B9BBF6',
  indigo300: '#9799F1',
  indigo400: '#7477ED',
  indigo500: '#5256E9',
  indigo600: '#4447B7',
  indigo700: '#353687',
  indigo800: '#252758',
  indigo850: '#1F1E3F',
  indigo900: '#161728',
  purple50: '#F4EDFD',
  purple100: '#E9DBFB',
  purple200: '#D4B7F7',
  purple300: '#BF93F4',
  purple400: '#AA6EF0',
  purple500: '#954BED',
  purple600: '#773EBB',
  purple700: '#59318A',
  purple800: '#3B2359',
  purple850: '#2D1B42',
  purple900: '#1E1529',
  gray30: '#F8F8F8',
  gray60: '#F4F4F4',
  gray100: '#EEEEEE',
  gray200: '#DDDDDD',
  gray300: '#CCCCCC',
  gray400: '#BBBBBB',
  gray500: '#AAAAAA',
  gray600: '#888888',
  gray700: '#666666',
  gray800: '#444444',
  gray900: '#222222',
  dkGray50: '#111111',
  dkGray100: '#222222',
  dkGray150: '#333333',
  dkGray200: '#444444',
  dkGray250: '#555555',
  dkGray300: '#666666',
  dkGray400: '#888888',
  dkGray500: '#AAAAAA',
  dkGray700: '#CCCCCC',
  dkGray900: '#EEEEEE',
  dkGray970: '#F8F8F8',

  brandTwitter: '#1DA1F2',
  brandFacebook: '#1877F2',
  brandTwitterDim: '#1989CE',
  black: '#000000',
  white: '#ffffff',
  transparent: 'transparent',

  opacityBackgroundDark20: 'rgba(0, 0, 0, 0.2)',
  opacityBackgroundDark50: 'rgba(0, 0, 0, 0.5)',
  opacityBackgroundWhite: 'rgba(255, 255, 255, 0.1)',
} as const;
const lightColor = {
  black__dkWhite: '#222222',
  white__dkBlack: '#ffffff',
  blackBg__dkWhiteBg: '#222222',
  whiteBg__dkBlackBg: '#ffffff',
  gray900__dkGray970: '#222222',
  gray800__dkGray900: '#444444',
  gray700__dkGray700: '#666666',
  gray600__dkGray500: '#888888',
  gray500__dkGray400: '#AAAAAA',
  gray400__dkGray300: '#BBBBBB',
  gray300__dkGray250: '#CCCCCC',
  gray200__dkGray200: '#DDDDDD',
  gray100__dkGray150: '#EEEEEE',
  gray60__dkGray100: '#F4F4F4',
  gray30__dkGray50: '#F8F8F8',
};
const darkColor: typeof lightColor = {
  black__dkWhite: '#ffffff',
  white__dkBlack: '#222222',
  blackBg__dkWhiteBg: '#ffffff',
  whiteBg__dkBlackBg: '#111111',
  gray900__dkGray970: '#F8F8F8',
  gray800__dkGray900: '#EEEEEE',
  gray700__dkGray700: '#CCCCCC',
  gray600__dkGray500: '#AAAAAA',
  gray500__dkGray400: '#888888',
  gray400__dkGray300: '#666666',
  gray300__dkGray250: '#555555',
  gray200__dkGray200: '#444444',
  gray100__dkGray150: '#333333',
  gray60__dkGray100: '#222222',
  gray30__dkGray50: '#111111',
};

export default color;
export { lightColor, darkColor };
