import { FC } from 'react';

import styled from '@emotion/styled';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';

import { PageMetas } from 'src/types/meta';

export const RootStyle = styled.div`
  position: fixed;
  bottom: 100%;
  color: transparent;
`;

const SeoKeywords: FC<PageMetas> = ({ metas }) => {
  const { t } = useTranslation();
  return (
    <>
      <Head>
        {((meta) => meta?.content && <title>{t(meta.content)}</title>)(
          metas?.find(({ property }) => property === 'og:title')
        )}
        {metas?.map(({ key, content, ...data }) => (
          <meta {...data} content={t(content)} key={key} />
        ))}
      </Head>
      <RootStyle>{t('seo_keywords')}</RootStyle>
    </>
  );
};

export default SeoKeywords;
