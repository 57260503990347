import * as Sentry from '@sentry/nextjs';
import { atom } from 'jotai';
import { v4 } from 'uuid';

import {
  // remoteConfigAtom,
  userDataAtom,
} from 'src/stores/auth/atoms';
import { UTMParamsAtom } from 'src/stores/utm/atoms';
import { visitorConfigAtom } from 'src/stores/visitor/atoms';
import {
  EVENT_API_URL,
  EventName,
  EventOption,
  EventType,
  SERVICE_CODE,
} from 'src/types/Event';
import { customFetch } from 'src/utils/api';
import getDeviceId from 'src/utils/device/id';
import getDeviceInfo from 'src/utils/device/info';
import store from 'src/stores';
import { statusAtom } from 'src/stores/match/atoms';
import { STATUS } from 'src/types/Match';
import { isUseKeepAliveAtom } from 'src/network/atoms';

export type EventParams<T = object> = {
  eventType: EventType;
  eventName: EventName | string;
  eventParams?: T;
  options?: EventOption;
  url?: string;
};

export interface EventLog<T = object> extends EventParams<T> {
  date: string;
}

export const eventLogsAtom = atom<EventLog[]>([]);

export const isPwaAtom = atom(false);
let eventErrorCount = 0;
export const eventMutateAtom = atom(
  null,
  async (get, set, params: EventParams) => {
    // TODO: isPayment 임시로 endpoint 분기 추후 통합예정
    const { eventType, eventName, eventParams, options, url = '' } = params;
    const isPwa = get(isPwaAtom);
    const addedIsPwa = { ...eventParams, ...(isPwa ? { isPwa } : {}) };
    const { browserName, browserVersion, isMobile, os } = getDeviceInfo();
    const ABConfig = {
      ...(get(visitorConfigAtom) || {}),
      // enableWebCameraOff: get(remoteConfigAtom)?.enableWebCameraOff,
    };
    window.dataLayer?.push({
      event: eventName,
      eventType,
      eventParams: {
        ...addedIsPwa,
        device_id: getDeviceId(),
        os,
        isMobile,
        ABConfig,
      },
      options: { ...options, device_id: getDeviceId() },
      url,
    });
    if (browserName === 'safari' && browserVersion === '14.0.3') {
      //https://hpcnt.slack.com/archives/C02PLFTA5DL/p1666321514312989
      return;
    }
    try {
      let path = window.location.pathname;
      if (window.location.pathname === '/') {
        path = store.get(statusAtom) === STATUS.INITIAL ? '/' : '/match';
      }

      await customFetch(`${EVENT_API_URL}${url}`, {
        method: 'post',
        keepalive: get(isUseKeepAliveAtom),
        body: JSON.stringify({
          event_type: eventType,
          event_name: eventName,
          event_timestamp: new Date().getTime(),
          event_uid: v4(),
          device_params: {
            os:
              typeof window !== 'undefined' && window.AzarJs
                ? 'WEB_VIEW'
                : 'WEB',
          },
          user_id: get(userDataAtom)?.userId || '',
          event_params: {
            path,
            unique_user_key: getDeviceId(),
            ABConfig,
            ...(typeof addedIsPwa === 'object' ? addedIsPwa : {}),
            ...getDeviceInfo(),
          },
          service_code: SERVICE_CODE.AZAR,
          unique_user_key: getDeviceId(),
          utm_params: { ...get(UTMParamsAtom) },
          ...options,
        }),
        headers: { 'x.hpcnt.service.code': SERVICE_CODE.AZAR },
      }).then((response) => {
        if (response.ok) {
          return response;
        }
        return Promise.reject(response);
      });

      set(
        eventLogsAtom,
        [{ ...params, date: new Date().toISOString() }]
          .concat(get(eventLogsAtom))
          .slice(0, 100)
      );
      eventErrorCount = 0;
    } catch (e) {
      // event gateway에 문제가 있는 경우 계속 보낼 수 있음
      if (eventErrorCount < 3) {
        Sentry.captureException(e);
        eventErrorCount++;
      }
    }
  }
);
