import { FC, useContext, useEffect, useMemo, useState } from 'react';

import { css, Global } from '@emotion/react';
import i18next from 'i18next';
import { useAtomValue, useSetAtom } from 'jotai';
import { useRouter } from 'next/router';
import { I18nextProvider } from 'react-i18next';

import OneTrustLoader from 'src/components/OneTrustLoader';
import Modal from 'src/components/Modal';
import SeoKeywords from 'src/components/SeoKeywords';
import Toast from 'src/components/Toast';
import useGetMyProfile from 'src/hooks/useGetMyProfile';
import useGetVisitorConfigs from 'src/hooks/useGetVisitorConfigs';
import useLiveOffModal from 'src/hooks/useLiveOffModal';
import useSegment from 'src/hooks/useSegment';
import useSession from 'src/hooks/useSession';
import useStomp from 'src/hooks/useStomp';
import useWebviewFont from 'src/hooks/useWebviewFont';
import { removeTokenAtom, userDataAtom } from 'src/stores/auth/atoms';
import { closeAllModalAtom } from 'src/stores/modal/atoms';
import theme from 'src/styles/theme';
import { PageMetas } from 'src/types/meta';
import { setClientLocale } from 'src/utils/api';
import {
  defaultI18Instance,
  initializeI18next,
  LanguageContext,
} from 'src/utils/language';

import { Container, RootStyle } from './style';
import useScreenOpen from 'src/hooks/useScreenOpen';
import useLogin from 'src/hooks/useLogin';
import { visitorBirthAtom } from 'src/stores/termsAge/atoms';
import useUpdateInventory from 'src/hooks/useUpdateInventory';
import { signUpSettingsLoadableAtom } from 'src/stores/register/atoms';
import useLoginUserOrderedModal from 'src/hooks/useLoginUserOrderedModal';
import { useGetConsents } from 'src/hooks/useGetConsents';

type Props = PageMetas;

const GlobalStyle = css`
  body {
    background-color: ${theme.color.gray30__dkGray50};
  }
`;

const Layout: FC<Props> = ({ children, metas }) => {
  const router = useRouter();
  const { language, i18nLanguage } = useContext(LanguageContext);
  const [i18n, setI18n] = useState<typeof i18next>(defaultI18Instance);
  const closeAllModal = useSetAtom(closeAllModalAtom);
  const { isLogin } = useLogin();
  const isInit = useMemo(() => !!language && !!i18n, [language, i18n]);

  useSession();
  useSegment();
  useStomp();
  useScreenOpen();
  useLoginUserOrderedModal();
  useEffect(() => {
    if (language) {
      setClientLocale(language);
    }
  }, [language]);

  useEffect(() => {
    if (i18nLanguage) {
      initializeI18next(i18nLanguage).then(setI18n);
    }
  }, [i18nLanguage]);

  useEffect(() => {
    const handleRouteChange = () => {
      closeAllModal();
    };
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [closeAllModal, router.events]);

  useWebviewFont();
  useGetVisitorConfigs();
  useGetMyProfile();
  useLiveOffModal();
  useUpdateInventory();
  // 회원가입 퍼널 UX에서 로딩을 피하기 위해, 생년월일 A/B 테스트 설정을 미리 로드
  useAtomValue(signUpSettingsLoadableAtom);
  // 회원가입 퍼널 UX에서 로딩을 피하기 위해, 약관 A/B 테스트 설정을 미리 로드
  useGetConsents();

  const userData = useAtomValue(userDataAtom);
  const logout = useSetAtom(removeTokenAtom);
  const setVisitorBirth = useSetAtom(visitorBirthAtom);
  useEffect(() => {
    // user-info api 필드 커뮤니케이션 오류로 userProfile이 없는 유저가 있고, userProfile.xx로 접근하면 크래시 나서 로그아웃 처리
    if (userData?.userId && !userData?.userProfile) {
      logout();
    }
  }, [logout, userData?.userId, userData?.userProfile]);

  useEffect(() => {
    if (isLogin) {
      setVisitorBirth(null);
    }
  }, [isLogin, setVisitorBirth]);

  return (
    <>
      <OneTrustLoader>
        <I18nextProvider i18n={i18n}>
          <RootStyle>
            <SeoKeywords metas={metas} />
            {isInit && (
              <>
                <Container>{children}</Container>
                <Toast />
                <Modal />
              </>
            )}
          </RootStyle>
          {typeof window !== 'undefined' && <Global styles={GlobalStyle} />}
        </I18nextProvider>
      </OneTrustLoader>
    </>
  );
};

export default Layout;
