import { useEffect } from 'react';

const useWebviewFont = () => {
  useEffect(() => {
    if (!window.AzarJs) return;
    // 웹뷰일 때 운영체제 폰트를 따라가도록 설정
    const style = document.createElement('style');
    style.innerHTML = 'body { font-family: "system-ui", sans-serif; }';
    document.head.appendChild(style);
  }, []);
};

export default useWebviewFont;
