import { atom } from 'jotai';

import { stepAtom } from 'src/stores/match/atoms';

export const headerAtom = atom(true);

export const setMatchHeaderAtom = atom(null, (get, set, isDesktop: boolean) => {
  const prevHeader = get(headerAtom);
  const matchStep = get(stepAtom);
  if (matchStep === 1) {
    if (prevHeader) return;
    return set(headerAtom, true);
  }
  if (prevHeader !== isDesktop) {
    set(headerAtom, isDesktop);
  }
});
