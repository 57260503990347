import * as Sentry from '@sentry/nextjs';

const AudioLevelWorkletUrl = '/audio-level.worklet.js';
async function createProcessor(audioContext: AudioContext) {
  let processorNode;
  try {
    processorNode = new AudioWorkletNode(audioContext, 'rms-level-processor');
  } catch (e) {
    try {
      await audioContext.audioWorklet.addModule(AudioLevelWorkletUrl);
      processorNode = new AudioWorkletNode(audioContext, 'rms-level-processor');
    } catch (e2) {
      console.error(`Unable to create worklet node: ${e2}`);
      return undefined;
    }
  }

  try {
    await audioContext.resume();
  } catch (e) {
    console.error(`Context resume failed ${e}`);
  }
  return processorNode;
}
interface Params {
  callback: (audioLevel: number) => void;
  stream: MediaStream;
}

class AudioLevelGetter {
  private audioLevelIntervalMs = 100;

  private sampleRate;

  private processor: AudioWorkletNode | void = undefined;

  private callback: Params['callback'];

  private audioContext: AudioContext | void = undefined;

  private mediaStreamSource: MediaStreamAudioSourceNode | void = undefined;

  constructor(params: Params) {
    this.callback = params.callback;
    const mediaTrackSettings = params.stream.getAudioTracks()[0]?.getSettings();
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    if (!AudioContext) {
      Sentry.captureMessage('AudioContext를 지원하지 않습니다.');
      return;
    }
    // browser의 기본 sampleRate
    const originalSampleRate = new AudioContext()?.sampleRate;
    const sampleRate =
      mediaTrackSettings?.sampleRate || originalSampleRate || 48000;
    this.sampleRate = sampleRate;
    this.audioContext = new AudioContext({ sampleRate });
    this.mediaStreamSource = this.audioContext.createMediaStreamSource(
      params.stream
    );
  }

  public pause() {
    this.processor?.disconnect();
    this.processor = undefined;
    return true;
  }

  public stop() {
    this.audioContext?.close();
    return true;
  }

  public async start() {
    if (!this.audioContext) return;
    const processor = await createProcessor(this.audioContext);
    if (processor) {
      const audioLevelIntervalMs = processor.parameters?.get(
        'audioLevelIntervalMs'
      );
      if (audioLevelIntervalMs) {
        audioLevelIntervalMs.value = this.audioLevelIntervalMs;
      }
      const sampleRate = processor.parameters?.get('sampleRate');
      if (sampleRate && this.sampleRate) {
        sampleRate.value = this.sampleRate;
      }
      processor.onprocessorerror = () => {
        console.error(
          'An error from AudioWorkletProcessor.process() was detected.'
        );
      };
      processor.port.onmessage = (ev) => {
        this.callback(ev.data);
      };
      this.mediaStreamSource?.connect(processor);
    }
    this.processor = processor;
  }
}

export const getTargetAudioLevel = (originAudioLevel: number) => {
  // audio-level-worklet에서 return하는 audio level은 약 -80 ~ -20 이므로 적절히 range산정
  const breakpoints = [-60, -50, -40, -30];
  const targetAudioLevel = breakpoints.findIndex(
    (breakpoint) => originAudioLevel <= breakpoint
  );
  return targetAudioLevel === -1 ? 4 : targetAudioLevel;
};

export default AudioLevelGetter;
