import { FC, useEffect } from 'react';

import styled from '@emotion/styled';
import { useAtom, useSetAtom } from 'jotai';

import ButtonBase from 'src/components/Button';
import ModalLayout, {
  MobileModalType,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalHeaderDescriptionRow,
  ModalHeaderTitle,
} from 'src/components/ModalLayout';
import useEvent from 'src/hooks/useEvent';
import { featureSettingsPopupsAtom } from 'src/stores/auth/atoms';
import { closeModalAtom } from 'src/stores/modal/atoms';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { ModalType } from 'src/types/Modal';
import { setFeatureSettingsAtom } from 'src/stores/user/atom';

const Wrap = styled.div`
  max-width: 500px;
`;

const AgreeButton = ButtonBase;
const Disagree = styled(ButtonBase)`
  font-weight: 600;
  margin-bottom: 18px;
`;
const Desc = styled(ModalHeaderDescriptionRow)`
  color: ${({ theme }) => theme.color.gray700__dkGray700};
  a {
    color: ${({ theme }) => theme.color.gray700__dkGray700};
  }
`;

const FeatureSettingsConsentsModal: FC = () => {
  const [featureSettingsPopups, setFeatureSettingsPopups] = useAtom(
    featureSettingsPopupsAtom
  );
  const popup = featureSettingsPopups?.[0];
  const setFeatureSettings = useSetAtom(setFeatureSettingsAtom);
  const closeModal = useSetAtom(closeModalAtom);
  const pushEvent = useEvent();

  useEffect(() => {
    if (popup) {
      pushEvent({
        eventName: EVENT_NAME.SIGNUP__IMPRESSION_FEATURE_CONSENT_POPUP,
        eventType: EVENT_TYPE.SIGN_UP,
        eventParams: {
          page: 'main',
          action_category: 'impression',
          target: 'consent_popup',
          tab: 'mirror',
          consent_category: popup.type,
        },
      });
    }
  }, [pushEvent, popup]);
  const handleAccept = () => {
    if (!popup) return;
    setFeatureSettings([
      {
        type: popup.type,
        value: true,
      },
    ]);
    pushEvent({
      eventName: EVENT_NAME.SIGNUP__CLICK_FEATURE_CONSENT_POPUP,
      eventType: EVENT_TYPE.SIGN_UP,
      eventParams: {
        page: 'main',
        action_category: 'click',
        target: 'button',
        tab: 'mirror',
        result: 'accept',
      },
    });
    setFeatureSettingsPopups((prev) => (prev ? prev.slice(1) : []));
    closeModal(ModalType.FEATURE_SETTINGS_CONSENTS);
  };

  const handleDisagree = () => {
    if (!popup) return;
    setFeatureSettings([
      {
        type: popup.type,
        value: false,
      },
    ]);
    pushEvent({
      eventName: EVENT_NAME.SIGNUP__CLICK_FEATURE_CONSENT_POPUP,
      eventType: EVENT_TYPE.SIGN_UP,
      eventParams: {
        page: 'main',
        action_category: 'click',
        target: 'button',
        tab: 'mirror',
        result: 'reject',
      },
    });
    setFeatureSettingsPopups((prev) => (prev ? prev.slice(1) : []));
    closeModal(ModalType.FEATURE_SETTINGS_CONSENTS);
  };

  return popup ? (
    <ModalLayout
      isDim
      closeWhenClickOutside={false}
      mobileType={MobileModalType.BOTTOM_SHEET}
      data-testid='Feature-Settings-Consent-Modal'
    >
      <Wrap>
        <ModalHeader>
          <ModalHeaderTitle mobileType={MobileModalType.BOTTOM_SHEET}>
            {popup.title}
          </ModalHeaderTitle>
        </ModalHeader>
        <ModalContent>
          <Desc
            dangerouslySetInnerHTML={{
              __html: popup.content,
            }}
          />
        </ModalContent>
      </Wrap>
      <ModalFooter>
        {popup.disagreeButtonText && (
          <Disagree
            id='CONSENTS_DISAGREE'
            onClick={handleDisagree}
            color='tertiary'
          >
            {popup.disagreeButtonText}
          </Disagree>
        )}
        <AgreeButton
          data-testid='Consent-button-agree'
          onClick={handleAccept}
          id='CONSENT_AGREE'
        >
          {popup.agreeButtonText}
        </AgreeButton>
      </ModalFooter>
    </ModalLayout>
  ) : null;
};

export default FeatureSettingsConsentsModal;
