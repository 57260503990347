export function clearStream(
  stream?: DeprecatedMediaStream,
  filter?: { audio: boolean; video: boolean }
) {
  if (!stream) return;

  if (stream.stop) {
    stream.stop();
  }
  const tracks = filter
    ? [
        ...(filter.audio ? stream.getAudioTracks() : []),
        ...(filter.video ? stream.getVideoTracks() : []),
      ]
    : stream.getTracks();

  tracks.forEach((track) => {
    track.stop();
    stream.removeTrack(track);
  });
}

export const supportCaptureStream = async () => {
  if ('function' !== typeof HTMLCanvasElement.prototype.captureStream) {
    return false;
  }
  // create a test canvas
  const canvas = document.createElement('canvas');
  // we need to init a context on the canvas
  const ctx = canvas.getContext('2d');
  const stream = canvas.captureStream();
  const clean = () => stream.getTracks().forEach((track) => track.stop());
  const vid = document.createElement('video');
  vid.muted = true;
  vid.srcObject = stream;

  // Safari needs we draw on the canvas
  // asynchronously after we requested the MediaStream
  setTimeout(() => {
    if (ctx) {
      ctx.fillStyle = '#FF0000';
      ctx.fillRect(0, 0, 300, 150);
    }
  });

  try {
    await vid.play();
  } catch (e) {
    // failed to load, no need to go deeper
    // it's not supported
    clean();
    return false;
  }
  // here we should have our canvas painted on the video
  // let's keep this image on the video
  await vid.pause();

  // now draw it back on the canvas
  let red_channel;
  if (ctx) {
    ctx.clearRect(0, 0, 300, 150);
    ctx.drawImage(vid, 0, 0);
    const pixel_data = ctx.getImageData(5, 5, 1, 1).data;
    red_channel = pixel_data[0];
  }
  clean();
  return !!red_channel && red_channel > 0; // it has red
};

export function switchAudio(stream: MediaStream, enabled: boolean) {
  stream.getAudioTracks().forEach((track) => {
    track.enabled = enabled;
  });
}

export function disableAudio(stream: MediaStream) {
  switchAudio(stream, false);
}

export function enableAudio(stream: MediaStream) {
  switchAudio(stream, true);
}

export function switchVideo(stream: MediaStream, enabled: boolean) {
  stream.getVideoTracks().forEach((track) => {
    track.enabled = enabled;
  });
}

export function disableVideo(stream: MediaStream) {
  switchVideo(stream, false);
}

export function enableVideo(stream: MediaStream) {
  switchVideo(stream, true);
}

export function findTrackByKind(stream: MediaStream, kind: string) {
  return stream.getTracks().find((track) => track.kind === kind);
}

type DeprecatedMediaStream = { stop?: () => void } & MediaStream;
