import MediaStreamPipe from './base';
import { getImage } from './utils';

class RotateMediaStream extends MediaStreamPipe {
  private degree: number;

  private resize: boolean;

  constructor(degree: number, resize: boolean) {
    super();
    this.degree = degree;
    this.resize = resize;
  }

  public renderVideo() {
    const canvasEl = this.canvasEl;
    const videoEl = this.videoEl;
    if (!canvasEl || !videoEl) {
      return;
    }
    let { videoWidth: width, videoHeight: height } = videoEl;
    const { videoWidth, videoHeight } = videoEl;
    if (this.resize) {
      if (width > (height / 16) * 9) {
        width = (height / 16) * 9;
      } else if (height > (width / 9) * 16) {
        height = (width / 9) * 16;
      }
    }
    [height, width] = [width, height];
    canvasEl.width = width;
    canvasEl.height = height;
    const context = canvasEl.getContext('2d');
    if (!context) {
      return;
    }
    this.render = () => {
      if (!width || !height || !videoWidth || !videoHeight) {
        return;
      }
      context.save();
      context.clearRect(0, 0, width, height);
      context.translate(width / 2, height / 2);
      context.rotate((this.degree * Math.PI) / 180);
      const image = getImage(videoEl);
      if (image) {
        context.drawImage(image, -videoWidth / 2, -videoHeight / 2);
      }
      context.restore();
    };
  }
}

export default RotateMediaStream;
