import { atom } from 'jotai';
import { RESET, atomWithReset } from 'jotai/utils';
import { remoteConfigAtom } from 'src/stores/auth/atoms';
import { getMLModelConfigsAPI } from './apis';
import * as Sentry from '@sentry/nextjs';
import { AntmanConfig, MLFeatureType, MLModelType } from './types';
import { AntmanResultResponse } from 'src/utils/media/pipes/antman';

const AntmanMediaStreamPromise = import('src/utils/media/pipes/antman');

export const antmanEnableAtom = atom<boolean>(true);

// antman이 동작해야하나 성능 등의 이슈로 중간에 동작이 중단되었는지 여부
export const antmanSuspendedAtom = atomWithReset(false);

/**
 * 적용하려는 앤트맨의 설정값이나 모델 부재 등 부득이한 경우에 fallback으로 사용하는 모델 정보
 * ver: tflite 2.0.0
 */
const FALLBACK_ANTMAN_CONFIG: AntmanConfig = {
  modelId: '99914b932bd37a50b983c5e7c90ae93b',
  modelConfig: {
    modelId: '99914b932bd37a50b983c5e7c90ae93b',
    type: MLModelType.Antman,
    intervalMs: 100,
    maxLatencyLimit: 1000,
  },
  blurConfig: {
    modelType: MLModelType.Antman,
    modelIndex: 1,
    criteriaWindow: 1,
    criteriaThreshold: 1,
    threshold: 0.98,
    maxInferencesLimit: 60,
  },
} as const;

export const antmanConfigAtom = atomWithReset(FALLBACK_ANTMAN_CONFIG);

export const applyFallbackAntmanModelAtom = atom(null, (get, set) => {
  set(antmanConfigAtom, RESET);
});

export const getMLConfigAtom = atom(null, async (get, set) => {
  const antmanModelIdFromRemoteConfig =
    get(remoteConfigAtom)?.antmanModelId ?? get(antmanConfigAtom).modelId;

  const modelIds = [antmanModelIdFromRemoteConfig];
  try {
    const {
      data: { models, features },
    } = await getMLModelConfigsAPI({
      modelIds,
    });
    const antmanModelConfig = models.find(
      (modelConfig) => modelConfig.modelId === antmanModelIdFromRemoteConfig
    );
    const antmanBlurConfig = features
      .find(
        (featureConfig) => featureConfig.featureType === MLFeatureType.MatchBlur
      )
      ?.featureCondition?.find(
        (featureCondition) => featureCondition.modelType === MLModelType.Antman
      );

    if (antmanModelConfig && antmanBlurConfig) {
      set(antmanConfigAtom, {
        modelId: antmanModelIdFromRemoteConfig,
        modelConfig: antmanModelConfig,
        blurConfig: antmanBlurConfig,
      });
    } else {
      if (!antmanModelConfig) {
        Sentry.captureMessage(
          `Failed to get Antman Model configs for: ${antmanModelIdFromRemoteConfig}`
        );
      } else {
        Sentry.captureMessage(
          `Failed to get Antman Blur configs for: ${antmanModelIdFromRemoteConfig}`
        );
      }
      set(applyFallbackAntmanModelAtom);
    }
  } catch (error) {
    Sentry.captureMessage(
      `Failed to get ML configs for: ${modelIds} - ${error}`
    );
    set(applyFallbackAntmanModelAtom);
  }
});

interface StartAntmanProps {
  onInitSuccess?: () => void;
  onInitFail?: () => void;
  onResult?: (result: AntmanResultResponse) => void;
  onTrigger?: (result: AntmanResultResponse) => void;
  onSuspend?: (latency: number) => void;
}

export const startAntmanAtom = atom(
  null,
  async (
    get,
    set,
    {
      onInitSuccess,
      onInitFail,
      onResult,
      onTrigger,
      onSuspend,
    }: StartAntmanProps
  ) => {
    const { default: AntmanMediaStream, AbuseType: ABUSE_TYPE } =
      await AntmanMediaStreamPromise;

    const { modelId, modelConfig, blurConfig } = get(antmanConfigAtom);

    const antmanMediaStream = new AntmanMediaStream(
      modelId,
      modelConfig.intervalMs,
      (antmanResult) => {
        if (!get(antmanEnableAtom)) return;

        if ('isInitSuccess' in antmanResult) {
          if (antmanResult.isInitSuccess) {
            onInitSuccess?.();
          } else {
            Sentry.captureException(antmanResult.reason);
            antmanMediaStream.dispose();
            onInitFail?.();
          }
          return;
        }

        const { result, time, skipSuspend } = antmanResult;

        onResult?.(antmanResult);

        if (result[ABUSE_TYPE.ABUSE] >= blurConfig.threshold) {
          onTrigger?.(antmanResult);
        }
        // antman predict가 max latency 초과하면 turn off
        if (!skipSuspend && time >= modelConfig.maxLatencyLimit) {
          set(antmanSuspendedAtom, true);
          set(antmanEnableAtom, false);
          onSuspend?.(time);
        }
      }
    );

    return antmanMediaStream;
  }
);

export const prepareAntmanAtom = atom(null, async (get, set) => {
  if (!get(antmanEnableAtom)) return;

  const antmanMediaStream = await set(startAntmanAtom, {
    onInitSuccess: () => {
      antmanMediaStream.dispose();
    },

    /**
     * 잘못된 modelId가 설정되어있거나 해당 모델 파일이 없을 경우를 고려하여
     * 앤트맨 구동이 실패한 최초 1회는 fallback으로 변경 후 재시도
     * fallback 앤트맨 모델로도 구동 실패시 앤트맨 비활성화
     */
    onInitFail: async () => {
      set(applyFallbackAntmanModelAtom);
      const fallbackAntmanMediaStream = await set(startAntmanAtom, {
        onInitSuccess: () => {
          fallbackAntmanMediaStream.dispose();
        },
        onInitFail: () => {
          set(antmanEnableAtom, false);
        },
      });
    },
  });
});
