import { createApiCall } from 'src/utils/api';

export const checkAccountExistAPI = (phoneId: string) =>
  createApiCall<void, boolean>('get', `/api/v1/phones/${phoneId}`);

export interface VerifyPreparationParams {
  languageCode: string;
  platform: 'WEBCLIENT';
}

export interface VerifyPreparation {
  countryName?: string;
  countryPrefixNumber?: number;
}

export interface VerifyPreparationResponse {
  userLocationInfos: VerifyPreparation[];
}

export const getVerifyPreparationAPI = createApiCall<
  VerifyPreparationParams,
  VerifyPreparationResponse
>('get', '/api/v1/phones/verifications/preparation');

export interface SendVerifyCodeToUserResponse {
  formattedPhoneNumber: string;
}

export interface SendVerifyCodeToUserParams {
  countryPrefixNumber: number;
  localeLanguageCode: string;
  phoneNumber: string;
  platform: 'WEBCLIENT';
}

export const sendVerifyCodeToUserAPI = createApiCall<
  SendVerifyCodeToUserParams,
  SendVerifyCodeToUserResponse
>('post', '/api/v1/phones/verifications/send');

export interface VerifyAccountParams {
  localeInfo: {
    countryCode: null;
    languageCode: string;
  };
  phoneVerification: {
    verifyCode: string;
    formattedPhoneNumber: string;
  };
  platform: 'WEBCLIENT';
}

interface VerifyAccountResponse {
  phoneToken?: string;
  phoneId?: string;
}

export const verifyAccountAPI = createApiCall<
  VerifyAccountParams,
  VerifyAccountResponse
>('post', '/api/v1/phones/verifications/validate');

export interface LocaleInfo {
  countryCode: string | null;
  languageCode: string;
}

export interface VerifyEmailParams {
  email: string;
  phoneId: string;
  phoneToken: string;
  localeInfo: LocaleInfo;
}

export interface VerifyEmailResponse {
  phoneId: string;
  phoneToken: string;
}

export const verifyEmailAPI = createApiCall<
  VerifyEmailParams,
  VerifyEmailResponse
>('post', '/api/v2/user-emails/verifications/validate');
