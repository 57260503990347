import { ShopOrigin } from 'src/stores/shop/types';

export const enum PgPaymentMethod {
  ADYEN = 'ADYEN',
  TOSS = 'TOSS',
}

export type PGPaymentMethod =
  (typeof PgPaymentMethod)[keyof typeof PgPaymentMethod];

export const PAYMENT_METHODS = {
  CARD: '카드',
  PHONE: '휴대폰',
  GIFT_CARD: '문화상품권',
} as const;

export type PaymentMethodsName =
  (typeof PAYMENT_METHODS)[keyof typeof PAYMENT_METHODS];

export interface OrderInfo {
  card?: {
    company: string;
    number: string;
    installmentPlanMonths: number;
  };
  mobilePhone?: {
    carrier: string;
    customerMobilePhone: string;
  };
  method: string;
  orderId: string;
  totalAmount: string;
  product: GemProduct;
}

export interface GemProduct {
  productId: string;
  productName: string;
  baseGemAmount: number;
  bonusGemAmount: number;
  promoGemAmount: number;
  membershipGemAmount: number;
  price: number;
  currency: string;
  iconUrl: string;
  backgroundColor: string;
}
export interface PaymentPopupInfo {
  origin: ShopOrigin;
  gemProduct: GemProduct;
}

export const PG_ERROR_CODE = {
  PAY_PROCESS_CANCELED: 'PAY_PROCESS_CANCELED',
  PAY_PROCESS_ABORTED: 'PAY_PROCESS_ABORTED',
  REJECT_CARD_COMPANY: 'PAY_REJECT_CARD_COMPANY',
  NOT_FOUND_PRODUCT: 'API_NOT_FOUND_PRODUCT',
  INVALID_AMOUNT: 'API_INVALID_AMOUNT',
  INVALID_RESERVED_USER: 'API_INVALID_RESERVATION',
  NOT_FOUND_RESERVED_PAYMENT: 'API_INVALID_RESERVATION',
  FAILED_APPROVE_PAYMENT: 'API_FAILED_APPROVE_PAYMENT',
  INVALID_BILLING: 'INVALID_BILLING',
  TIME_OUT: 'TIME_OUT',
  ERROR: 'ERROR',
  NETWORK_ERROR: 'NETWORK_ERROR',
  IMPLEMENTATION_ERROR: 'IMPLEMENTATION_ERROR',
  COMMON_ERROR: 'COMMON_ERROR',
} as const;

type ResponseCode = keyof typeof PG_ERROR_CODE;

export const getErrorCode = (code?: string) => {
  if (!code) return PG_ERROR_CODE.ERROR;

  return PG_ERROR_CODE[code as ResponseCode] || PG_ERROR_CODE.ERROR;
};

export const tossTestClientKey = process.env.NEXT_PUBLIC_TOSS_CLIENT_KEY || '';

export const adyenClientKey = process.env.NEXT_PUBLIC_ADYEN_CLIENT_KEY || '';

export const paymentsEnvironment =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'Prod' ? 'live' : 'test';

export interface PaymentsCompleteRequest {
  paymentKey?: string;
  amount: string;
  currency: string;
  orderId: string;
  paymentMethod: string;
  shopperInteraction?: 'Ecommerce';
  recurringProcessingModel?: 'CardOnFile';
}

export interface PaymentsCompleteResponse {
  orderId: string;
  product: {
    baseGemAmount: number;
    bonusGemAmount: number;
    isGemProduct: boolean;
    productName: string;
    promoGemAmount: number;
  };
  totalAmount: string;
}

export interface BillingInfoRequest {
  productId: string;
  price: number;
  currency: string;
}

export interface BillingInfoResponse {
  paymentMethods: {
    method: PGPaymentMethod;
    bonusGemAmount?: number;
    bonusGemRates?: BonusGemRate[];
  }[];
  product: {
    baseGemAmount: number;
    promoGemAmount: number;
  };
}

export interface PaymentsReserveRequest {
  paymentMethod: string;
  productId: string;
  productName: string;
  amount: string;
  currency: string;
}

export interface PaymentsReserveResponse {
  orderId: string;
  sessionId: string; // when adyen
  sessionData: string; // when adyen
}

export interface GemProductsResponse {
  paymentMethod: PGPaymentMethod;
  gemProducts: GemProduct[];
  bonusGemRates?: BonusGemRate[];
}

export interface BonusGemRate {
  type: 'BONUS_COUPON' | 'VIP_BONUS' | 'PG_BONUS';
  rate: number;
  vipRating?: string;
}
