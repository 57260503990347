import { atom } from 'jotai';
import { atomWithReset, loadable, RESET } from 'jotai/utils';

import {
  acceptCmpConsentsAtom,
  getCmpConsentsAtom,
  handleLoginResultAtom,
  socialTokenAtom,
  tryLoginTypeAtom,
  userJoinDateAtom,
} from 'src/stores/auth/atoms';
import { eventMutateAtom } from 'src/stores/event/atoms';
import { statusAtom } from 'src/stores/match/atoms';
import {
  retryWithSmsLoginAtom,
  smsLoginStep3EmailAtom,
  smsLoginStepAtom,
} from 'src/stores/smsLogin/atoms';
import { setFeatureSettingsAtom } from 'src/stores/user/atom';
import {
  SetFeatureSettings,
  SetFeatureSettingsParam,
} from 'src/stores/user/types';
import { EVENT_NAME, EVENT_TYPE } from 'src/types/Event';
import { STATUS } from 'src/types/Match';
import { GENDER, LoginType, SignUpSettingsResponse } from 'src/types/register';

import { getSignUpSettingsAPI, RegisterOAuthAPI, saveEmailAPI } from './apis';
import { ConsentsMapRequest } from 'src/types/consent';

export const defaultBirth = (() => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
})();

export const enum LoginModalType {
  LOGIN = 'login',
  REGISTER = 'register',
  SMS_LOGIN = 'smsLogin',
}
export const loginModalTypeAtom = atom<LoginModalType>(LoginModalType.LOGIN);
export const isRegisterLoadingAtom = atom(false);

export const stepAtom = atom(1);
export const backStepAtom = atom(null, (get, set) => {
  if (get(stepAtom) === 1) {
    set(stepAtom, 1);
    set(loginModalTypeAtom, LoginModalType.LOGIN);
  }
  if (get(stepAtom) > 1) {
    set(stepAtom, get(stepAtom) - 1);
  }
});

export const birthAtom = atom(new Date(defaultBirth));
export const genderAtom = atom<GENDER | undefined>(undefined);

export const nextStepAtom = atom(null, (get, set) => {
  if (get(loginModalTypeAtom) === LoginModalType.SMS_LOGIN) {
    set(smsLoginStepAtom, get(smsLoginStepAtom) + 1);
  } else set(stepAtom, get(stepAtom) + 1);
});

export const featureSettingParamsFromOptionalConsentsAtom = atomWithReset<
  SetFeatureSettings[] | null
>(null);

export const requiredConsentsMapForRegisterAtom = atomWithReset<
  ConsentsMapRequest['consents'] | null
>(null);

export const registerAtom = atom(
  null,
  async (
    get,
    set,
    params: {
      gender?: GENDER;
      childGender?: string;
      phoneVerification?: {
        verifyCode: string;
        formattedPhoneNumber: string;
      };
      featureSettings?: SetFeatureSettingsParam['featureSettings'];
      cmpConsentsAccepted: boolean;
    }
  ) => {
    set(isRegisterLoadingAtom, true);
    const loginType = get(tryLoginTypeAtom);
    const socialToken = get(socialTokenAtom);
    const birth = get(birthAtom);
    const gender = params.gender || get(genderAtom);
    if (params.gender) {
      set(genderAtom, params.gender);
    }
    if (loginType === undefined || !socialToken || !birth || !gender) {
      return;
    }

    const response = await RegisterOAuthAPI(loginType, {
      socialToken,
      birthYear: birth.getFullYear(),
      birthMonth: birth.getMonth() + 1,
      birthDay: birth.getDate(),
      ...params,
      gender,
      childGender: params.childGender,
      consents: get(requiredConsentsMapForRegisterAtom) ?? {},
    });

    const {
      data: { result: userData, error },
    } = response;
    if (userData && !error) {
      await set(handleLoginResultAtom, response);
      if (userData.accessToken) {
        const featureSettings = [
          ...(params.featureSettings ?? []),
          ...(get(featureSettingParamsFromOptionalConsentsAtom) ?? []),
        ];
        await Promise.all([
          params.cmpConsentsAccepted
            ? set(acceptCmpConsentsAtom)
            : set(getCmpConsentsAtom),
          featureSettings.length > 0 &&
            set(setFeatureSettingsAtom, featureSettings),
        ]);
        if (!params.phoneVerification) {
          set(nextStepAtom);
        } else {
          set(smsLoginStepAtom, 7);
        }
      }
      set(eventMutateAtom, {
        eventType: EVENT_TYPE.SIGN_UP,
        eventName: EVENT_NAME.SIGNUP_COMPLETE,
        eventParams: {
          userId: userData.userId,
          loginType: get(tryLoginTypeAtom),
        },
        options: { user_id: userData.userId },
      });
      set(userJoinDateAtom, new Date());
      if (loginType === LoginType.SMS) {
        await saveEmailAPI({ email: get(smsLoginStep3EmailAtom).email });
      }
      set(isRegisterLoadingAtom, false);
    } else if (error) {
      if (error.message === 'NotVerifiedException') {
        set(retryWithSmsLoginAtom, {
          type: LoginModalType.REGISTER,
          config: response.config,
          cmpConsentsAccepted: params.cmpConsentsAccepted,
        });
      }
      set(isRegisterLoadingAtom, false);
    }
  }
);

export const resetRegisterAtom = atom(null, (get, set) => {
  set(featureSettingParamsFromOptionalConsentsAtom, RESET);
  set(requiredConsentsMapForRegisterAtom, RESET);
  set(statusAtom, STATUS.INITIAL);
  set(smsLoginStepAtom, 1);
  set(stepAtom, 1);
  set(loginModalTypeAtom, LoginModalType.LOGIN);
});

export const FALLBACK_SIGNUP_SETTINGS: SignUpSettingsResponse = {
  genderSettings: {
    nonBinaryChildGenders: [],
    nonBinaryEnabled: false,
    hideGenderOptionEnabled: false,
    hideGenderDefaultValue: false,
  },
  birthdaySettings: {
    blockAlertAge: 1,
    uiType: 'BIRTHDAY_WEB_A',
  },
};

export const fetchSignUpSettingsAtom = atom<
  Promise<SignUpSettingsResponse> | SignUpSettingsResponse
>(async () => {
  const { data } = await getSignUpSettingsAPI();
  return data;
});

export const signUpSettingsLoadableAtom = loadable(fetchSignUpSettingsAtom);
