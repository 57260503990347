function findLineInRange(
  sdpLines: string[],
  startLine: number,
  endLine: number,
  prefix: string,
  substr: string
): number | null {
  const realEndLine = endLine !== -1 ? endLine : sdpLines.length;
  for (let i = startLine; i < realEndLine; ++i) {
    if (sdpLines[i].indexOf(prefix) === 0) {
      if (
        !substr ||
        sdpLines[i].toLowerCase().indexOf(substr.toLowerCase()) !== -1
      ) {
        return i;
      }
    }
  }
  return null;
}

function findLine(
  sdpLines: string[],
  prefix: string,
  substr: string
): number | null {
  return findLineInRange(sdpLines, 0, -1, prefix, substr);
}

function getCodecPayloadType(sdpLine: string) {
  const pattern = new RegExp('a=rtpmap:(\\d+) \\w+\\/\\d+');
  const result = sdpLine.match(pattern);
  return result && result.length === 2 ? result[1] : null;
}

/*
// payload에 해당하는 codec 제거
function removeCodec(mLine: string, payload: string) {
  const elements = mLine.split(' ');
  const newLine = [];
  let index = 0;
  for (let i = 0; i < elements.length; i++) {
    if (elements[i] !== payload) {
      newLine[index++] = elements[i];
    }
  }
  return newLine.join(' ');
}
*/

/* mLine: sdp line ex) m=audio 9 RTP/SAVPF 103 111 104 9 0 8 106 105 13 126
 * 코덱을 원하는 payload로 설정한다 */
function setDefaultCodec(mLine: string, payload: string) {
  const elements = mLine.split(' ');
  const newLine = [];
  let index = 0;
  for (let i = 0; i < elements.length; i++) {
    if (index === 3) {
      newLine[index++] = payload;
    }
    if (elements[i] !== payload) {
      newLine[index++] = elements[i];
    }
  }
  return newLine.join(' ');
}

export function applyWorkaroundForCompat(sdp: string) {
  // https://code.google.com/p/webrtc/issues/detail?id=2796
  // 이 이슈에 따라 새 버전에서 'UDP/TLS/RTP/SAVPF' 포맷으로 보내면 기존 WebRTC와 호환이 안되어서 문제가 생긴다.
  // 구 WebRTC와 호환 유지를 위해서 기존대로 'RTP/SAVPF' 로 보내도록 임시로 처리를 해두도록 한다.
  return sdp.replace('UDP/TLS/RTP/SAVPF', 'RTP/SAVPF');
}

export function preferAudioCodec(sdp: string, codec: string) {
  const sdpLines = sdp.split('\r\n');
  const mLineIndex = findLine(sdpLines, 'm=', 'audio');
  if (mLineIndex === null) {
    return sdp;
  }
  const codecIndex = findLine(sdpLines, 'a=rtpmap', codec);
  if (codecIndex) {
    const payload = getCodecPayloadType(sdpLines[codecIndex]);
    if (payload) {
      sdpLines[mLineIndex] = setDefaultCodec(sdpLines[mLineIndex], payload);
    }
  }
  sdp = sdpLines.join('\r\n');
  return sdp;
}
