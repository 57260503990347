import { useMemo } from 'react';

import { useAtomValue } from 'jotai';

import { userDataAtom } from 'src/stores/auth/atoms';

export default function useHelpCenterUrl(ticketFormId = '114093958053') {
  const userData = useAtomValue(userDataAtom);

  const url = useMemo(() => {
    const userName = userData?.userProfile?.username;
    return userName
      ? `https://help.azarlive.com/hc/requests/new?ticket_form_id=${ticketFormId}&entry.38573527=${userName}`
      : 'https://help.azarlive.com/';
  }, [userData, ticketFormId]);

  return url;
}
