import adapter from 'webrtc-adapter';

// adapter가 번들링할 때 import될 수 있도록 추가
adapter.disableLog(true);

if (typeof Promise.allSettled !== 'function') {
  Promise.allSettled = <T>(
    promises: Array<T | Promise<T>>
  ): Promise<PromiseSettledResult<T>[]> => {
    return Promise.all(
      promises.map((p) =>
        Promise.resolve(p)
          .then((value) => ({
            status: 'fulfilled' as const,
            value,
          }))
          .catch((reason) => ({
            status: 'rejected' as const,
            reason,
          }))
      )
    );
  };
}
