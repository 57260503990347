import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useRef } from 'react';
import {
  getInventoryAtom,
  lastInventoryUpdateTimeAtom,
  remoteConfigAtom,
} from 'src/stores/auth/atoms';
import useLogin from 'src/hooks/useLogin';
import { differenceInSeconds } from 'date-fns';

const FALLBACK_INVENTORY_UPDATE_INTERVAL_SECONDS = 60;

const useUpdateInventory = () => {
  const [lastUpdateTime, setLastUpdateTime] = useAtom(
    lastInventoryUpdateTimeAtom
  );
  const remoteConfig = useAtomValue(remoteConfigAtom);
  const getInventory = useSetAtom(getInventoryAtom);
  const { isLogin } = useLogin();
  // 페이지 첫 접속 시점에 로그인 상태였는지 여부
  const wasLoginOnInitRef = useRef(isLogin);

  const shouldUpdateInventory = useCallback(() => {
    // 로그아웃 -> 로그인할 때에는 어차피 로그인 응답에서 인벤토리 내려주므로 해당 로직 방지
    if (!wasLoginOnInitRef.current) return false;
    if (!isLogin) return false;
    if (lastUpdateTime === null) return true;

    const now = new Date();
    const diffInSeconds = differenceInSeconds(now, lastUpdateTime);
    const minIntervalInSeconds =
      remoteConfig?.refreshInventoryMinIntervalInSeconds ??
      FALLBACK_INVENTORY_UPDATE_INTERVAL_SECONDS;

    return diffInSeconds >= minIntervalInSeconds;
  }, [isLogin, lastUpdateTime, remoteConfig]);

  const updateInventory = useCallback(() => {
    setLastUpdateTime(new Date());
    getInventory();
  }, [setLastUpdateTime, getInventory]);

  useEffect(() => {
    if (shouldUpdateInventory()) {
      updateInventory();
    }
  }, [shouldUpdateInventory, updateInventory]);
};

export default useUpdateInventory;
