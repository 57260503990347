import * as Sentry from '@sentry/nextjs';
import { ResponseType } from 'axios';
import { get as idbGet, set as idbSet } from 'idb-keyval';

import { client } from './api';

/**
 * IndexedDB를 통해 캐싱하고 싶은 remote resource를 활용할 때 사용.
 * Cache miss날 경우 주어진 url 통해서 fetch 후 자동 캐시 (fetch 관련 axios error 발생 가능)
 */
export const getResourceWithCache = async (
  resourceUrl: string,
  resourceResponseType?: ResponseType
) => {
  try {
    const cachedData = await idbGet(resourceUrl);
    if (cachedData) {
      return cachedData;
    }
  } catch (error) {
    Sentry.captureMessage(`Failed to read from IndexedDB: ${error}`);
  }

  const fetchedResource = (
    await client.get(resourceUrl, { responseType: resourceResponseType })
  ).data;

  try {
    idbSet(resourceUrl, fetchedResource);
  } catch (error) {
    Sentry.captureMessage(`Failed to write to IndexedDB: ${error}`);
  }
  return fetchedResource;
};
