import {
  getAtomWithStorage,
  reviveDateFromStorage,
} from 'src/utils/localStorageUtils';

export const webToAppLastModalShownAtom = getAtomWithStorage<Date | null>(
  'webToAppLastModalShown',
  null,
  { reviver: reviveDateFromStorage }
);
