import React, { useRef, createContext, useContext } from 'react';

import styled from '@emotion/styled';
import { useAtomValue, useSetAtom } from 'jotai';

import useBodyScrollLock from 'src/hooks/useBodyScrollLock';
import { closeModalAtom, openedModalsAtom } from 'src/stores/modal/atoms';
import { ModalType } from 'src/types/Modal';
import { hexToRgb } from 'src/utils/common';

export const ModalBack = styled.div<{ isDim: boolean; lockScroll: boolean }>`
  z-index: 41;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  width: var(--100vw);
  height: 100%;
  max-height: 100vh;
  display: flex;
  background: ${({ theme, isDim }) =>
    isDim ? `rgba(${hexToRgb(theme.color.black)}, .7)` : 'transparent'};
  ${({ theme }) => theme.screenSize.mobile} {
    z-index: 51;
  }
  ${({ lockScroll }) =>
    lockScroll
      ? `
    position: fixed;
  `
      : `
    position: absolute;
    width: 100%;
  `}
`;

export const ModalBackInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export interface Props
  extends Pick<React.HTMLAttributes<HTMLDivElement>, 'className' | 'onClick'> {
  onClose?: () => void;
  lockScroll?: boolean;
  isDim?: boolean;
  closeWhenClickOutside?: boolean;
}

export const ModalKeyContext = createContext<ModalType | void>(undefined);

export const ModalKeyProvider: React.FC<{ modalType: ModalType }> = ({
  modalType,
  ...props
}) => (
  <ModalKeyContext.Provider value={modalType}>
    {props.children}
  </ModalKeyContext.Provider>
);

export const ModalBackground: React.FC<Props> = ({
  children,
  isDim = false,
  className,
  onClose,
  lockScroll = true,
  closeWhenClickOutside = true,
  ...props
}) => {
  const closeModal = useSetAtom(closeModalAtom);
  const modalType = useContext(ModalKeyContext);
  const onClickOutside: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    if (!closeWhenClickOutside) {
      return;
    }

    if (onClose) {
      onClose();
    }
    if (modalType) {
      closeModal(modalType);
    }
  };

  const rootRef = useRef<HTMLDivElement>(null);
  useBodyScrollLock(rootRef, !lockScroll);
  return (
    <ModalBack
      ref={rootRef}
      lockScroll={lockScroll}
      isDim={isDim}
      className={`ModalBack ${className}`}
      role='button'
      tabIndex={0}
      onClick={onClickOutside}
      {...props}
    >
      <ModalBackInner
        onClick={(e) => e.stopPropagation()}
        role='button'
        tabIndex={0}
      >
        {children}
      </ModalBackInner>
    </ModalBack>
  );
};

type ModalProps = {
  [key in ModalType]?: React.ReactElement;
};

const Modal: React.FC = () => {
  const openedModals = useAtomValue(openedModalsAtom);
  const [renderedModals, setRenderedModals] = React.useState<ModalProps>({});
  React.useEffect(() => {
    setRenderedModals((prevRenderedModals) => {
      const nextRenderedModals: ModalProps = {};
      const preserve = openedModals.map((i) => i.key);
      for (const key in prevRenderedModals) {
        if (!preserve.includes(key as ModalType)) {
          delete prevRenderedModals[key as ModalType];
        }
      }
      openedModals.map(({ component, key }) => {
        const Component = component;
        if (prevRenderedModals[key]) {
          return;
        }
        if (typeof Component === 'boolean') {
          return;
        }
        nextRenderedModals[key] =
          (
            <ModalKeyProvider modalType={key as ModalType} key={key}>
              <Component />
            </ModalKeyProvider>
          ) || undefined;
      });
      return {
        ...prevRenderedModals,
        ...nextRenderedModals,
      };
    });
  }, [openedModals]);

  return <>{Object.values(renderedModals)}</>;
};

export default Modal;
