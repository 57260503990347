import { LoginOauthMap } from 'src/stores/auth/apis';
import { AzarUser } from 'src/types/AzarUser';
import {
  LoginType,
  RegisterParamsBase,
  SignUpSettingsResponse,
} from 'src/types/register';
import { SocialToken } from 'src/types/Token';
import { clientWithoutAuth, createApiCall, rpcClient } from 'src/utils/api';
import { getLoginParam } from 'src/utils/auth';
import getDeviceId from 'src/utils/device/id';

export enum RegisterApiList {
  GetConsents = '/api/v3/consents',
}

export const saveEmailAPI = createApiCall<{ email: string }, void>(
  'POST',
  '/api/v2/user-emails'
);

interface RegisterOAuthParams extends RegisterParamsBase {
  socialToken: SocialToken;
}

export const SignUpOAuthMethodMap: {
  [key in LoginType]: string;
} = {
  [LoginType.FACEBOOK]: 'signUpWithFacebook',
  [LoginType.GOOGLE]: 'signUpWithGoogleV2',
  [LoginType.APPLE]: 'signUpWithApple',
  [LoginType.SMS]: 'signUpWithPhone',
};

export const RegisterOAuthAPI = (
  method: LoginType,
  { socialToken: { id, ...socialToken }, ...params }: RegisterOAuthParams
) => {
  const loginParams = {
    ...getLoginParam(),
    ...socialToken,
    ...params,
    [LoginOauthMap[method]]: id,
  };
  return rpcClient<typeof loginParams, AzarUser>(SignUpOAuthMethodMap[method])(
    loginParams
  );
};

export const getSignUpSettingsAPI = () => {
  const {
    localeInfo: {
      languageCode: localeLanguageCode,
      countryCode: localeCountryCode,
    },
    clientProperties: { flavor },
  } = getLoginParam();

  return clientWithoutAuth.get<SignUpSettingsResponse>(
    '/api/v1/signup-settings',
    {
      params: {
        localeCountryCode,
        localeLanguageCode,
        locationCountryCode: null,
        simCountryCode: null,
        platform: 'WEBCLIENT',
        deviceId: getDeviceId(),
        flavor,
      },
    }
  );
};
