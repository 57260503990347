import { useAtomValue } from 'jotai';

import { userDataAtom } from 'src/stores/auth/atoms';

const GDPR_CODES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
];
const useTermsInfo = () => {
  const { userProfile } = useAtomValue(userDataAtom) || {};
  const isGDPR = GDPR_CODES.includes(
    userProfile?.location?.countryCode || userProfile?.country || ''
  );

  return { isGDPR };
};

export default useTermsInfo;
