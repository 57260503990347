import { client, createApiCall } from 'src/utils/api';
import { getLoginParam } from 'src/utils/auth';

import {
  FeatureSettings,
  MoreSettings,
  SetFeatureSettingsParam,
  SetFeatureSettingsResponse,
  UserSegmentResponse,
} from './types';

export const getMoreSettingsAPI = () =>
  client.get<MoreSettings>('/api/more-settings');

export const getFeatureSettingsAPI = createApiCall<void, FeatureSettings>(
  'get',
  '/api/v3/feature-settings'
);

export const setFeatureSettingsAPI = createApiCall<
  SetFeatureSettingsParam,
  SetFeatureSettingsResponse
>('post', '/api/v3/feature-settings');

export const getUserSegmentAPI = () => {
  // deviceId: _ 는 lint error 방지용
  const { deviceId: _, ...body } = getLoginParam();
  return client.post<UserSegmentResponse>('/api/v1/user-info', body);
};
