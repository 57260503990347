import { useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { remoteConfigAtom, userDataAtom } from 'src/stores/auth/atoms';
import { getUserSegmentAtom } from 'src/stores/user/atom';

const DEFAULT_REFRESH_USER_INFO_INTERVAL_SEC = 14400; // 4시간

export default function useSegment() {
  const remoteConfig = useAtomValue(remoteConfigAtom);
  const userData = useAtomValue(userDataAtom);
  const getUserSegment = useSetAtom(getUserSegmentAtom);

  useEffect(() => {
    if (!userData?.userId) return;
    getUserSegment();
    const timer = setInterval(
      () => {
        getUserSegment();
      },
      (remoteConfig?.refreshUserInfoIntervalInSeconds ??
        DEFAULT_REFRESH_USER_INFO_INTERVAL_SEC) * 1000
    );
    return () => {
      clearInterval(timer);
    };
  }, [
    getUserSegment,
    remoteConfig?.refreshUserInfoIntervalInSeconds,
    userData?.userId,
  ]);
}
