type RoundingType = 'ceil' | 'floor' | 'round';

function roundNumber(
  number: number | string,
  decimalPlaces: number,
  roundingType: RoundingType = 'round'
): number {
  if (typeof number === 'string') {
    number = parseFloat(number);
  }

  const multiplier = Math.pow(10, decimalPlaces);

  if (roundingType === 'ceil') {
    number = Math.ceil(number * multiplier) / multiplier;
  } else if (roundingType === 'floor') {
    number = Math.floor(number * multiplier) / multiplier;
  } else {
    number = Math.round(number * multiplier) / multiplier;
  }

  return Number(number.toFixed(decimalPlaces));
}
export default roundNumber;
