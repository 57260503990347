export function getEmptyAudioTrack() {
  const AudioContext = window.AudioContext || window.webkitAudioContext;
  const context = new AudioContext();
  const destination = context.createMediaStreamDestination();

  const gain = context.createGain();
  gain.gain.value = 0;
  gain.connect(destination);

  const osc = context.createOscillator();
  osc.connect(gain);
  osc.start();

  const [audioTrack] = destination.stream.getAudioTracks();
  return audioTrack;
}

export function getEmptyVideoTrack() {
  const canvas = document.createElement('canvas');
  // getContext()를 호출하지 않으면
  // firefox에서 captureStream() 호출 시, NS_ERROR_NOT_INITIALIZED 에러 발생함
  canvas.getContext('2d');

  const stream = canvas.captureStream();
  const [videoTrack] = stream.getVideoTracks();
  return videoTrack;
}
