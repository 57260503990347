import { v4 as UUID } from 'uuid';

import { client } from 'src/utils/api';

interface GetContentManagerResponse {
  group: string;
  type: string;
  contentManagerApi: {
    url: string;
    certDigest: null | string;
  };
}

export const getContentManagerAPI = () =>
  client.get<GetContentManagerResponse>('/api/contexts/content-manager');

interface PutContentManagerRequest extends GetContentManagerResponse {
  contentId: string;
  body: Blob;
  contentType: string;
  extension: string;
}

export const putContentManagerAPI = ({
  group,
  type,
  contentManagerApi: { url },
  contentId,
  contentType,
  body,
  extension,
}: PutContentManagerRequest) =>
  client.put(
    `${url}/groups/${group}/types/${type}/contents/${contentId}.${extension}`,
    body,
    { headers: { 'Content-Type': contentType } }
  );

export const uploadContentManager = async (
  file: Blob,
  contentType = 'image/png'
) => {
  const contentManager = await getContentManagerAPI().then(({ data }) => data);
  const contentId = UUID();
  const extension = contentType.substring(contentType.indexOf('/') + 1);
  await putContentManagerAPI({
    ...contentManager,
    body: file,
    contentId,
    contentType,
    extension,
  });

  const fileInfo = {
    bucketName: contentManager.group,
    objectName: `${contentManager.type}/${contentId}.${extension}`,
  };

  return fileInfo;
};
