import { FC } from 'react';

import styled from '@emotion/styled';
import { useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';

import Button from 'src/components/Button';
import { ModalFooter } from 'src/components/ModalLayout';
import {
  Description,
  ErrorCode as ErrorCodeBase,
  ImgFailed,
  Wrapper as WrapperBase,
} from 'src/components/Payments/Failure/style';
import { getGemProductsAtom } from 'src/stores/payments/atoms';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled(WrapperBase)`
  padding-top: 40px;
  padding-bottom: 12px;
  flex: 1;
`;

const ErrorCode = styled(ErrorCodeBase)`
  margin-bottom: 12px;
`;

const ProductLoadingFailure: FC = () => {
  const { t } = useTranslation();
  const getGemProducts = useSetAtom(getGemProductsAtom);

  return (
    <Container>
      <Wrapper>
        <ImgFailed src='/images/icons/imgFailed.png' alt='failed' />
        <ErrorCode>{t('common__problem_occurred')}</ErrorCode>
        <Description>{t('common__try_again')}</Description>
      </Wrapper>
      <ModalFooter>
        <Button id='gem-products-retry' onClick={getGemProducts}>
          {t('common__try_again_button')}
        </Button>
      </ModalFooter>
    </Container>
  );
};

export default ProductLoadingFailure;
