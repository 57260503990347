import React, { FC, useContext } from 'react';

import isPropValid from '@emotion/is-prop-valid';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';

import { LanguageContext } from 'src/utils/language';

const TextLogoCss = ({ theme, color }: { theme: Theme; color?: string }) => css`
  fill: ${color || theme.color.white};
`;

const forwardOption: Parameters<typeof styled>[1] = {
  shouldForwardProp: (prop) => typeof prop === 'string' && isPropValid(prop),
};
const TextLogoNormal = styled(
  dynamic(() => import('src/assets/images/textLogo.svg')),
  forwardOption
)<Pick<ITextLogo, 'width' | 'height' | 'color'>>`
  ${TextLogoCss}
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;
const TextLogoAr = styled(
  dynamic(() => import('src/assets/images/textLogoAr.svg')),
  forwardOption
)<Pick<ITextLogo, 'arWidth' | 'arHeight' | 'color'>>`
  ${TextLogoCss}
  width: ${({ arWidth }) => arWidth}px;
  height: ${({ arHeight }) => arHeight}px;
`;
const TextLogoPt = styled(
  dynamic(() => import('src/assets/images/textLogoPt.svg')),
  forwardOption
)<Pick<ITextLogo, 'ptWidth' | 'ptHeight' | 'color'>>`
  ${TextLogoCss}
  width: ${({ ptWidth }) => ptWidth}px;
  height: ${({ ptHeight }) => ptHeight}px;
`;

interface ITextLogo {
  width: number;
  height: number;
  arWidth: number;
  arHeight: number;
  ptWidth: number;
  ptHeight: number;
  color?: string;
  className?: string;
}

const TextLogo: FC<ITextLogo> = ({ width, height, ...props }) => {
  const { language } = useContext(LanguageContext);
  switch (language) {
    case 'ar':
      return <TextLogoAr {...props} />;
    case 'pt':
      return <TextLogoPt {...props} />;
    default:
      return <TextLogoNormal width={width} height={height} {...props} />;
  }
};

export default React.memo(TextLogo);
