export enum WebToAppModalType {
  BASIC = 'home',
  EFFECT = 'effect',
  MESSAGE = 'message',
}

export interface WebToAppModalProps {
  type: WebToAppModalType;
  onClose?: () => void;
}
