import React, { useEffect } from 'react';

import { useAtomValue, useSetAtom } from 'jotai';

import { LoginModalType, loginModalTypeAtom } from 'src/stores/register/atoms';

import Login from './Login';
import Register from './Register';
import SmsLogin from './SmsLogin';
import {
  handleAgeRestrictedLoginAttemptAtom,
  isDeviceAgeRestrictedAtom,
} from 'src/stores/auth/atoms';

interface Props {
  loginSuccessCallback?: () => void;
}

const LoginBox: React.FC<Props> = ({ loginSuccessCallback }) => {
  const loginModalType = useAtomValue(loginModalTypeAtom);
  const isDeviceAgeRestricted = useAtomValue(isDeviceAgeRestrictedAtom);
  const handleAgeRestrictedLoginAttempt = useSetAtom(
    handleAgeRestrictedLoginAttemptAtom
  );

  useEffect(() => {
    if (isDeviceAgeRestricted) {
      handleAgeRestrictedLoginAttempt();
    }
  }, [isDeviceAgeRestricted]);

  if (isDeviceAgeRestricted) return null;

  switch (loginModalType) {
    case LoginModalType.LOGIN:
      return <Login loginSuccessCallback={loginSuccessCallback} />;
    case LoginModalType.REGISTER:
      return <Register />;
    case LoginModalType.SMS_LOGIN:
      return <SmsLogin />;
  }
};

export default LoginBox;
